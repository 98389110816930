<!--<div class="cancelar">
    <div mat-dialog-content>
        <p>{{ data.message }}</p>
    </div>
    <div mat-dialog-actions class="button-container">
        <button mat-button *ngIf="!data.showSuccessMessage" (click)="fechar()">
            Não
        </button>
        <button mat-button *ngIf="data.showSuccessMessage" (click)="close()">
            Fechar
        </button>
        <button
            mat-button
            *ngIf="!data.showSuccessMessage"
            (click)="close()"
            [mat-dialog-close]="true">
            Sim
        </button>
    </div>
</div>-->

<div mat-dialog-content class="flex items-center gap-3">
    <mat-icon class="text-green-700 text-3xl">check_circle</mat-icon>
    <p class="text-green-700 text-lg font-semibold">{{ data.message }}</p>
</div>

<div mat-dialog-actions class="flex justify-end gap-2 mt-4">
    <!-- Botão Não (aparece se não for sucesso) -->
    <button
        mat-button
        *ngIf="!data.showSuccessMessage"
        (click)="fechar()"
        class="bg-red-600 hover:bg-red-700 text-white font-semibold px-4 py-2 rounded-full">
        Não
    </button>

    <!-- Botão Fechar (aparece se for sucesso) -->
    <button
        mat-button
        *ngIf="data.showSuccessMessage"
        (click)="close()"
        class="bg-orange-500 hover:bg-orange-600 text-white font-semibold px-4 py-2 rounded-full">
        Fechar
    </button>

    <!-- Botão Sim (aparece se não for sucesso) -->
    <button
        mat-button
        *ngIf="!data.showSuccessMessage"
        (click)="close()"
        [mat-dialog-close]="true"
        class="bg-green-700 hover:bg-green-800 text-white font-semibold px-4 py-2 rounded-full">
        Sim
    </button>
</div>
