import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface FilterData {
  filterFarm: number;
  filterProduct: string;
  filterBatch: string;
  filterValidity: Date;
  filterMode: string;
  filterCreatedAt: Date;
  filterUpdatedAt: Date;
  filterStockDate: Date;
  farms: any[];
  products: any[];
}

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent {
  filterData: FilterData;

  constructor(
    public dialogRef: MatDialogRef<FilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FilterData
  ) {
    // Clona os dados para não alterar os filtros originais enquanto o usuário edita
    this.filterData = { ...data };
  }

  onApply(): void {
    this.dialogRef.close(this.filterData);
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}