<div class="flex flex-col max-w-500 -m-6">
  <!-- Cabeçalho -->
  <div
    class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary"
  >
    <div class="text-lg font-medium">
      {{ formTitle }}
    </div>
    <button mat-icon-button (click)="cancel()" [tabIndex]="-1">
      <mat-icon class="text-current" matTooltip="Sair" [svgIcon]="'heroicons_outline:x'"></mat-icon>
    </button>
  </div>

  <!-- Formulário -->
  <form
    class="flex flex-col flex-auto p-6 sm:p-8 overflow-y-auto stock-form-container"
    [formGroup]="stockForm"
  >
    <!-- Seleção de Fazenda -->
    <div class="form-row">
      <mat-form-field appearance="fill">
        <mat-label>Fazenda</mat-label>
        <mat-select formControlName="farm_id">
          <mat-option *ngFor="let farm of farms" [value]="farm.id">
            {{ farm.farm_name }} - {{ farm.identity_name || farm.farm_name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- Lista de Produtos (Estoques) -->
    <div formArrayName="inventory" class="inventory-list">
      <ng-container *ngFor="let stock of inventoryFormArray.controls; let i = index">
        <div [formGroupName]="i" class="inventory-item card">
          <!-- Cabeçalho do Produto -->
          <div class="inventory-header">
            <div class="header-left">
              <mat-form-field appearance="fill" class="product-field">
                <mat-label>Produto</mat-label>
                <mat-select formControlName="product">
                  <mat-option *ngFor="let prod of products" [value]="prod.id">
                    {{ prod.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="fill" class="min-level-field">
                <mat-label>Qtd. Mínima</mat-label>
                <input
                  matInput
                  type="number"
                  formControlName="minimun_level"
                  placeholder="Ex: 10"
                />
              </mat-form-field>
            </div>
            <div class="header-actions">
              <button mat-icon-button color="warn" (click)="removeStock(i)" matTooltip="Remover Produto">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
  
          <!-- Lista de Lotes -->
          <div formArrayName="batches" class="batch-list">
            <ng-container *ngFor="let batch of getBatches(stock).controls; let j = index">
              <div [formGroupName]="j" class="batch-item">
                <div class="batch-fields">
                  <mat-form-field appearance="fill" class="batch-code">
                    <mat-label>Código do Lote</mat-label>
                    <input
                      matInput
                      formControlName="batch"
                      placeholder="Código do Lote"
                    />
                  </mat-form-field>
  
                  <mat-form-field appearance="fill" class="batch-validity">
                    <mat-label>Data de Validade</mat-label>
                    <input
                      matInput
                      [matDatepicker]="picker"
                      formControlName="validity"
                      placeholder="Data de Validade"
                    />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
  
                  <mat-form-field appearance="fill" class="batch-quantity">
                    <mat-label>Quantidade</mat-label>
                    <input
                      matInput
                      type="number"
                      formControlName="quantity"
                      placeholder="Quantidade"
                    />
                  </mat-form-field>
  
                  <!-- Select para o campo mode -->
                  <mat-form-field appearance="fill" class="batch-mode">
                    <mat-label>Modo</mat-label>
                    <mat-select formControlName="mode">
                      <mat-option value="OnDemand">OnDemand</mat-option>
                      <mat-option value="OnFarm">OnFarm</mat-option>
                    </mat-select>
                  </mat-form-field>
  
                  <mat-form-field appearance="fill" class="batch-operation">
                    <mat-label>Tipo de Operação</mat-label>
                    <mat-select formControlName="operation_type">
                      <mat-option value="verification">Verificação</mat-option>
                      <mat-option value="movement">Movimentação</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <button mat-icon-button color="warn" (click)="removeBatch(i, j)" matTooltip="Remover Lote">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </ng-container>
  
            <!-- Botão para adicionar um novo lote para o produto -->
            <div class="add-batch">
              <button mat-flat-button color="primary" (click)="addBatch(i)">
                <mat-icon>add</mat-icon>
                <span>Adicionar Lote</span>
              </button>
            </div>
          </div>
        </div>
      </ng-container>
  
      <!-- Botão para adicionar um novo produto -->
      <div class="add-product">
        <button mat-flat-button color="primary" (click)="addStock()">
          <mat-icon>add</mat-icon>
          <span>Adicionar Produto</span>
        </button>
      </div>
    </div>
  
    <!-- Ações do Formulário -->
    <div class="form-actions">
      <button mat-button (click)="cancel()">Cancelar</button>
      <button mat-flat-button color="primary" (click)="save()" [disabled]="stockForm.invalid">
        {{ data && data.stockData ? 'Atualizar' : 'Salvar' }}
      </button>
    </div>
  </form>
</div>