<div class="flex flex-col max-w-240 md:min-w-160 -m-6">
    <!-- Header -->
    <div
        class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">
            Pedido de Vendas ({{ codPedidoControl.value || '---' }})
        </div>
        <button mat-icon-button (click)="cancel()" [tabIndex]="-1">
            <mat-icon
                class="text-current"
                [matTooltip]="'Sair'"
                [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>

    <div class="cadastro h-full overflow-hidden">
        <div
            class="overflow-auto p-4 mt-1"
            [ngClass]="{ 'max-h-[600px]': !isMobile }">
            <div *ngIf="!isMobile" class="cadastro">
                <div
                    class="flex items-center gap-2 text-2xl font-semibold text-gray-800 mb-2">
                    <mat-icon class="text-primary text-3xl"
                        >inventory_2</mat-icon
                    >
                    <span>Cabeçalho</span>
                </div>

                <!-- ID CLIENTE CLIENTE CPF -->
                <div class="grid grid-cols-12 gap-1 pl-3 pr-3 mt-1">
                    <div class="col-span-2">
                        <mat-form-field class="w-full" appearance="outline">
                            <mat-label class="label">Cod. Cliente</mat-label>
                            <input
                                type="text"
                                class="h-full text-sm p-1"
                                matInput
                                [formControl]="codClienteControl"
                                [readonly]="codClienteControl.value !== null" />
                        </mat-form-field>
                    </div>
                    <div class="col-span-7">
                        <mat-form-field class="w-full" appearance="outline">
                            <mat-label class="label">Cliente</mat-label>
                            <input
                                type="text"
                                class="h-full text-sm p-1"
                                matInput
                                [formControl]="clienteControl"
                                [readonly]="clienteControl.value !== null" />
                        </mat-form-field>
                    </div>
                    <div class="col-span-3">
                        <mat-form-field class="w-full" appearance="outline">
                            <mat-label class="label">CPF/CNPJ</mat-label>
                            <input
                                type="text"
                                class="h-full text-sm p-1"
                                matInput
                                [formControl]="cpfControl"
                                [readonly]="cpfControl.value !== null" />
                        </mat-form-field>
                    </div>
                </div>

                <!-- IE FAZ TEL END -->
                <div class="grid grid-cols-12 gap-1 pl-3 pr-3 -mt-5">
                    <div class="col-3">
                        <mat-form-field
                            class="custom-mat-field"
                            appearance="outline">
                            <mat-label class="label"
                                >Inscrição Estadual</mat-label
                            >
                            <input
                                type="text"
                                class="h-full text-sm p-1"
                                matInput
                                [formControl]="inscricaoControl"
                                [readonly]="inscricaoControl.value !== null" />
                        </mat-form-field>
                    </div>
                    <div class="campo-container col-3">
                        <mat-form-field
                            class="custom-mat-field"
                            appearance="outline">
                            <mat-label class="label">Fazenda</mat-label>
                            <input
                                type="text"
                                class="h-full text-sm p-1"
                                matInput
                                [formControl]="fazendaControl"
                                [readonly]="fazendaControl.value !== null" />
                        </mat-form-field>
                    </div>
                    <div class="campo-container col-3">
                        <mat-form-field
                            class="custom-mat-field"
                            appearance="outline">
                            <mat-label class="label">Telefone</mat-label>
                            <input
                                type="text"
                                class="h-full text-sm p-1"
                                matInput
                                [formControl]="telefoneControl"
                                [readonly]="telefoneControl.value !== null" />
                        </mat-form-field>
                    </div>
                    <div class="campo-container col-3">
                        <mat-form-field
                            class="custom-mat-field"
                            appearance="outline">
                            <mat-label class="label">Endereço</mat-label>
                            <input
                                type="text"
                                class="h-full text-sm p-1"
                                matInput
                                [formControl]="enderecoControl"
                                [readonly]="enderecoControl.value !== null" />
                        </mat-form-field>
                    </div>
                </div>

                <!-- DATA ENTREGA, FRETE, CD ENTREGA -->
                <div class="grid grid-cols-12 gap-1 pl-3 pr-3 -mt-5">
                    <!-- Data de Entrega (3 colunas) -->
                    <div class="col-span-3">
                        <mat-form-field
                            class="custom-mat-field w-full"
                            appearance="outline">
                            <mat-label class="label">Data de Entrega</mat-label>
                            <input
                                matInput
                                [formControl]="dataVencimentoControl"
                                [matDatepicker]="pickerScheduledE"
                                [readonly]="dataVencimentoControl.value != null"
                                class="h-full text-sm p-1" />
                            <mat-datepicker-toggle
                                matSuffix
                                [for]="pickerScheduledE"
                                [disabled]="
                                    dataVencimentoControl.value != null
                                "></mat-datepicker-toggle>
                            <mat-datepicker #pickerScheduledE></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <!-- Frete (3 colunas) -->
                    <div class="col-span-3">
                        <mat-form-field
                            class="custom-mat-field w-full"
                            appearance="outline">
                            <mat-label class="label">Frete</mat-label>
                            <input
                                type="text"
                                class="h-full text-sm p-1"
                                matInput
                                [formControl]="freteControl"
                                [readonly]="freteControl.value !== null" />
                        </mat-form-field>
                    </div>

                    <!-- CD Entrega (6 colunas) -->
                    <div class="col-span-6">
                        <mat-form-field
                            class="custom-mat-field w-full"
                            appearance="outline">
                            <mat-label class="label">CD Entrega</mat-label>
                            <input
                                type="text"
                                class="text-sm p-1"
                                matInput
                                [formControl]="CdEntregaControl"
                                [readonly]="CdEntregaControl.value !== null" />
                        </mat-form-field>
                    </div>
                </div>

                <!-- VENDEDOR | COND. PAGAMENTO | MEIO DE PAGAMENTO | OBSERVAÇÕES -->
                <div class="grid grid-cols-12 gap-1 pl-3 pr-3 -mt-5">
                    <!-- Vendedor (3 colunas) -->
                    <div class="col-span-4">
                        <mat-form-field
                            class="custom-mat-field w-full"
                            appearance="outline">
                            <mat-label class="label">Vendedor</mat-label>
                            <input
                                type="text"
                                class="h-full text-sm p-1"
                                matInput
                                [formControl]="vendedorControl"
                                [readonly]="vendedorControl.value !== null" />
                        </mat-form-field>
                    </div>

                    <!-- Condição de Pagamento (2 colunas) -->
                    <div class="col-span-4">
                        <mat-form-field
                            class="custom-mat-field w-full"
                            appearance="outline">
                            <mat-label class="label">Cond. Pagamento</mat-label>
                            <input
                                type="text"
                                class="h-full text-sm p-1"
                                matInput
                                [formControl]="condPagamentoControl"
                                [readonly]="
                                    condPagamentoControl.value !== null
                                " />
                        </mat-form-field>
                    </div>

                    <!-- Meio de Pagamento (2 colunas) -->
                    <div class="col-span-4">
                        <mat-form-field
                            class="custom-mat-field w-full"
                            appearance="outline">
                            <mat-label class="label"
                                >Meio de pagamento</mat-label
                            >
                            <input
                                type="text"
                                class="h-full text-sm p-1"
                                matInput
                                [formControl]="meioPagamentoControl"
                                [readonly]="
                                    meioPagamentoControl.value !== null
                                " />
                        </mat-form-field>
                    </div>
                </div>

                <div class="grid grid-cols-12 gap-1 pl-3 pr-3 -mt-5">
                    <!-- Observações (5 colunas) -->
                    <div class="col-span-12">
                        <mat-form-field
                            class="custom-mat-field w-full"
                            appearance="outline">
                            <mat-label class="label">Observação</mat-label>
                            <input
                                type="text"
                                class="h-full text-sm p-1"
                                matInput
                                [formControl]="observacoescadControl"
                                [readonly]="
                                    observacoescadControl.value !== null
                                " />
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div *ngIf="isMobile" class="cadastro">
                <div
                    class="flex items-center gap-2 text-2xl font-semibold text-gray-800 mb-2">
                    <mat-icon class="text-primary text-3xl"
                        >inventory_2</mat-icon
                    >
                    <span>Cabeçalho</span>
                </div>

                <!-- ID CLIENTE CLIENTE CPF -->
                <div class="grid grid-cols-12 gap-1 pl-3 pr-3 mt-1">
                    <div class="col-span-7">
                        <mat-form-field class="w-full" appearance="outline">
                            <mat-label class="label">Cod. Cliente</mat-label>
                            <input
                                type="text"
                                class="h-full text-sm p-1"
                                matInput
                                [formControl]="codClienteControl"
                                [readonly]="codClienteControl.value !== null" />
                        </mat-form-field>
                    </div>
                    <div class="col-span-12 -mt-6">
                        <mat-form-field class="w-full" appearance="outline">
                            <mat-label class="label">Cliente</mat-label>
                            <input
                                type="text"
                                class="h-full text-sm p-1"
                                matInput
                                [formControl]="clienteControl"
                                [readonly]="clienteControl.value !== null" />
                        </mat-form-field>
                    </div>
                    <div class="col-span-12 -mt-6">
                        <mat-form-field class="w-full" appearance="outline">
                            <mat-label class="label">CPF/CNPJ</mat-label>
                            <input
                                type="text"
                                class="h-full text-sm p-1"
                                matInput
                                [formControl]="cpfControl"
                                [readonly]="cpfControl.value !== null" />
                        </mat-form-field>
                    </div>
                </div>

                <!-- IE FAZ TEL END -->
                <div class="grid grid-cols-12 gap-1 pl-3 pr-3 -mt-3">
                    <div class="col-3">
                        <mat-form-field
                            class="custom-mat-field"
                            appearance="outline">
                            <mat-label class="label"
                                >Inscrição Estadual</mat-label
                            >
                            <input
                                type="text"
                                class="h-full text-sm p-1"
                                matInput
                                [formControl]="inscricaoControl"
                                [readonly]="inscricaoControl.value !== null" />
                        </mat-form-field>
                    </div>
                    <div class="campo-container col-3 -mt-6">
                        <mat-form-field
                            class="custom-mat-field"
                            appearance="outline">
                            <mat-label class="label">Fazenda</mat-label>
                            <input
                                type="text"
                                class="h-full text-sm p-1"
                                matInput
                                [formControl]="fazendaControl"
                                [readonly]="fazendaControl.value !== null" />
                        </mat-form-field>
                    </div>
                    <div class="campo-container col-3 -mt-6">
                        <mat-form-field
                            class="custom-mat-field"
                            appearance="outline">
                            <mat-label class="label">Telefone</mat-label>
                            <input
                                type="text"
                                class="h-full text-sm p-1"
                                matInput
                                [formControl]="telefoneControl"
                                [readonly]="telefoneControl.value !== null" />
                        </mat-form-field>
                    </div>
                    <div class="campo-container col-3 -mt-6">
                        <mat-form-field
                            class="custom-mat-field"
                            appearance="outline">
                            <mat-label class="label">Endereço</mat-label>
                            <input
                                type="text"
                                class="h-full text-sm p-1"
                                matInput
                                [formControl]="enderecoControl"
                                [readonly]="enderecoControl.value !== null" />
                        </mat-form-field>
                    </div>
                </div>

                <!-- DATA ENTREGA, FRETE, CD ENTREGA -->
                <div class="grid grid-cols-12 gap-4 pl-3 pr-3 -mt-3">
                    <!-- Data de Entrega (3 colunas) -->
                    <div class="col-span-12">
                        <mat-form-field
                            class="custom-mat-field w-full"
                            appearance="outline">
                            <mat-label class="label">Data de Entrega</mat-label>
                            <input
                                matInput
                                [formControl]="dataVencimentoControl"
                                [matDatepicker]="pickerScheduledE"
                                [readonly]="dataVencimentoControl.value != null"
                                class="h-full text-sm p-1" />
                            <mat-datepicker-toggle
                                matSuffix
                                [for]="pickerScheduledE"
                                [disabled]="
                                    dataVencimentoControl.value != null
                                "></mat-datepicker-toggle>
                            <mat-datepicker #pickerScheduledE></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <!-- Frete (3 colunas) -->
                    <div class="col-span-12 -mt-6">
                        <mat-form-field
                            class="custom-mat-field w-full"
                            appearance="outline">
                            <mat-label class="label">Frete</mat-label>
                            <input
                                type="text"
                                class="h-full text-sm p-1"
                                matInput
                                [formControl]="freteControl"
                                [readonly]="freteControl.value !== null" />
                        </mat-form-field>
                    </div>

                    <!-- CD Entrega (6 colunas) -->
                    <div class="col-span-12 -mt-6">
                        <mat-form-field
                            class="custom-mat-field w-full"
                            appearance="outline">
                            <mat-label class="label">CD Entrega</mat-label>
                            <input
                                type="text"
                                class="text-sm p-1"
                                matInput
                                [formControl]="CdEntregaControl"
                                [readonly]="CdEntregaControl.value !== null" />
                        </mat-form-field>
                    </div>
                </div>

                <!-- VENDEDOR | COND. PAGAMENTO | MEIO DE PAGAMENTO | OBSERVAÇÕES -->
                <div class="grid grid-cols-12 gap-1 pl-3 pr-3 -mt-3">
                    <!-- Vendedor (3 colunas) -->
                    <div class="col-span-12">
                        <mat-form-field
                            class="custom-mat-field w-full"
                            appearance="outline">
                            <mat-label class="label">Vendedor</mat-label>
                            <input
                                type="text"
                                class="h-full text-sm p-1"
                                matInput
                                [formControl]="vendedorControl"
                                [readonly]="vendedorControl.value !== null" />
                        </mat-form-field>
                    </div>

                    <!-- Condição de Pagamento (2 colunas) -->
                    <div class="col-span-12 -mt-6">
                        <mat-form-field
                            class="custom-mat-field w-full"
                            appearance="outline">
                            <mat-label class="label">Cond. Pagamento</mat-label>
                            <input
                                type="text"
                                class="h-full text-sm p-1"
                                matInput
                                [formControl]="condPagamentoControl"
                                [readonly]="
                                    condPagamentoControl.value !== null
                                " />
                        </mat-form-field>
                    </div>

                    <!-- Meio de Pagamento (2 colunas) -->
                    <div class="col-span-12 -mt-6">
                        <mat-form-field
                            class="custom-mat-field w-full"
                            appearance="outline">
                            <mat-label class="label"
                                >Meio de pagamento</mat-label
                            >
                            <input
                                type="text"
                                class="h-full text-sm p-1"
                                matInput
                                [formControl]="meioPagamentoControl"
                                [readonly]="
                                    meioPagamentoControl.value !== null
                                " />
                        </mat-form-field>
                    </div>

                    <!-- Observações (5 colunas) -->
                    <div class="col-span-12 -mt-6">
                        <mat-form-field
                            class="custom-mat-field w-full"
                            appearance="outline">
                            <mat-label class="label">Observação</mat-label>
                            <input
                                type="text"
                                class="h-full text-sm p-1"
                                matInput
                                [formControl]="observacoescadControl"
                                [readonly]="
                                    observacoescadControl.value !== null
                                " />
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div
                class="flex items-center gap-2 text-2xl font-semibold text-gray-800 mb-2">
                <mat-icon class="text-primary text-3xl">inventory_2</mat-icon>
                <span>Itens do Pedido</span>
            </div>

            <div class="itens-container mt-2 mb-4">
                <div
                    class="item-card w-full border-l-8 border-green-700"
                    *ngFor="let item of dadosTabela"
                    (click)="selecionarLinha(item)"
                    [ngClass]="{ selected: linhasSelecionadas === item }">
                    <!-- Ícone e Código do Item -->
                    <div class="item-header">
                        <mat-icon class="item-icon">inventory_2</mat-icon>
                        <span class="item-code"># {{ item.nItem }}</span>
                    </div>

                    <!-- Descrição do Produto e Aplicação -->
                    <div class="item-info">
                        <strong class="item-title">{{
                            item.descricaoProduto
                        }}</strong
                        ><br />
                        <span class="item-subtitle"
                            ><strong>Aplicação:</strong>
                            {{ item.aplicacao }}</span
                        >
                    </div>

                    <!-- Cultura e Data de Entrega -->
                    <div class="item-detalhes">
                        <span class="cultura"
                            ><strong>Cultura:</strong> {{ item.cultura }}</span
                        >
                        <span class="data-entrega"
                            ><strong>Dt. Entrega:</strong>
                            {{ item.data | date : 'dd/MM/yyyy' }}</span
                        >
                    </div>

                    <!-- Preço e Total -->
                    <div class="item-preco">
                        <span class="preco-unit"
                            >Preço Unit: {{ item.precoUnitario }}</span
                        >
                        <span class="preco-total">Total: {{ item.total }}</span>
                    </div>

                    <!-- Status -->
                    <div class="item-status">
                        <span class="desconto"
                            ><strong>Desconto:</strong>
                            {{ item.desconto | number : '1.0-0' }}%</span
                        >
                        <span class="status"
                            ><strong>Venda: </strong> #{{
                                item.cod_venda
                            }}</span
                        >
                    </div>

                    <!-- Quantidade e Status de Entrega -->
                    <div class="item-quantidade">
                        <span><strong>Qtd:</strong> {{ item.quantidade }}</span>
                        <span class="entrega-status"
                            ><strong>Qtd. Entregue:</strong>
                            {{ item.quantidade - item.quantidadeemaberto }} de
                            {{ item.quantidade }} entregues
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
