import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StockFormComponent } from '../stock-form/stock-form.component';
import { ProductStockService } from 'app/core/services/admin/product-stock.service';
import { FarmsService } from 'app/core/services/admin/farms.service';
import { ProductsService } from 'app/core/services/admin/products.service';
import { Farm } from 'app/core/models/admin/farms.model';
import { Product } from 'app/core/models/admin/products.model';
import { Subscription } from 'rxjs';
import { FilterDialogComponent, FilterData } from '../filter-dialog/filter-dialog.component';

@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.scss'],
})
export class StockComponent implements OnInit, OnDestroy {
  stock: any[] = [];
  isLoading: boolean = false;
  error: string = '';
  pagination = {
    length: 0,
    page: 0,
    size: 10,
  };

  // Filtros
  filterFarm: number = null;
  filterProduct: string = '';
  filterBatch: string = '';
  filterValidity: Date = null;
  filterMode: string = '';
  filterCreatedAt: Date = null;
  filterUpdatedAt: Date = null;
  filterStockDate: Date = null;
  farms: Farm[] = [];
  products: Product[] = [];

  private subscription: Subscription;

  constructor(
    private productStockService: ProductStockService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private farmsService: FarmsService,
    private productsService: ProductsService
  ) {}

  ngOnInit(): void {
    this.loadStock();
    this.loadFarms();
    this.loadProducts();
  }

  loadFarms(): void {
    this.farmsService.getFarms().subscribe({
      next: (data) => {
        this.farms = data;
        this.cdr.markForCheck();
      },
      error: (err) => {
        console.error('Erro ao carregar fazendas:', err);
      },
    });
  }

  loadProducts(): void {
    this.productsService.getProducts().subscribe({
      next: (data) => {
        this.products = data;
        this.cdr.markForCheck();
      },
      error: (err) => {
        console.error('Erro ao carregar produtos:', err);
      },
    });
  }

  loadStock(): void {
    this.isLoading = true;
    this.subscription = this.productStockService.getStock().subscribe({
      next: (data) => {
        // Ordena decrescentemente por id
        this.stock = data.stock.sort((a, b) => b.id - a.id);
        this.pagination.length = data.totalItems || this.stock.length;
        this.isLoading = false;
        this.cdr.markForCheck();
      },
      error: (err) => {
        console.error('Erro ao carregar o estoque:', err);
        this.error = 'Erro ao carregar o estoque!';
        this.isLoading = false;
        this.cdr.markForCheck();
      },
    });
  }

  /**
   * Converte uma string de data para um objeto Date.
   * Se a string contiver '/' assume o formato "dd/mm/yyyy hh:mm:ss".
   * Se estiver no formato "YYYY-MM-DD", cria uma data em horário local.
   */
  private parseDate(dateStr: string): Date {
    if (!dateStr) {
      return null;
    }
    if (dateStr.includes('/')) {
      const [datePart, timePart] = dateStr.split(' ');
      const [day, month, year] = datePart.split('/').map(Number);
      const [hours = 0, minutes = 0, seconds = 0] = timePart ? timePart.split(':').map(Number) : [];
      return new Date(year, month - 1, day, hours, minutes, seconds);
    }
    const isoMatch = dateStr.match(/^(\d{4})-(\d{2})-(\d{2})$/);
    if (isoMatch) {
      const [, year, month, day] = isoMatch;
      return new Date(Number(year), Number(month) - 1, Number(day));
    }
    return new Date(dateStr);
  }

  get filteredProducts(): Product[] {
    return this.products;
  }

  // Função auxiliar robusta para comparar duas datas ignorando as horas
  private isSameDate(date1: any, date2: any): boolean {
    let d1 = date1;
    let d2 = date2;
    if (d1 && typeof d1.toDate === 'function') {
      d1 = d1.toDate();
    } else if (!(d1 instanceof Date)) {
      d1 = new Date(d1);
    }
    if (d2 && typeof d2.toDate === 'function') {
      d2 = d2.toDate();
    } else if (!(d2 instanceof Date)) {
      d2 = new Date(d2);
    }
    if (isNaN(d1.getTime()) || isNaN(d2.getTime())) {
      return false;
    }
    return d1.getFullYear() === d2.getFullYear() &&
           d1.getMonth() === d2.getMonth() &&
           d1.getDate() === d2.getDate();
  }

  get filteredStock(): any[] {
    return this.stock.filter(item => {
      const matchFarm = !this.filterFarm || item.farm_id === this.filterFarm;
      const matchProduct = !this.filterProduct || (item.product && item.product.description.toLowerCase().includes(this.filterProduct.toLowerCase()));
      const batchMatches = item.batch && item.batch.some(batch => {
        const matchBatch = !this.filterBatch || (batch.batch && batch.batch.toLowerCase().includes(this.filterBatch.toLowerCase()));
        const matchValidity = !this.filterValidity || (batch.validity && this.isSameDate(this.parseDate(batch.validity), this.filterValidity));
        const matchMode = !this.filterMode || (batch.mode && batch.mode.toLowerCase() === this.filterMode.toLowerCase());
        const matchCreatedAt = !this.filterCreatedAt || (batch.created_at && this.isSameDate(new Date(batch.created_at), this.filterCreatedAt));
        const matchUpdatedAt = !this.filterUpdatedAt || (batch.updated_at && this.isSameDate(new Date(batch.updated_at), this.filterUpdatedAt));
        const matchStockDate = !this.filterStockDate || (batch.stock_date && this.isSameDate(this.parseDate(batch.stock_date), this.filterStockDate));
        return matchBatch && matchValidity && matchMode && matchCreatedAt && matchUpdatedAt && matchStockDate;
      });
      return matchFarm && matchProduct && batchMatches;
    });
  }

  openStockForm(item?: any, batchIndex?: number): void {
    const dialogRef = this.dialog.open(StockFormComponent, {
      width: '600px',
      data: { stockData: item, batchIndex: batchIndex || null },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadStock();
      }
    });
  }

  createNewStock(): void {
    this.openStockForm();
  }

  // Abre o diálogo de filtros
  openFilterDialog(): void {
    const dialogRef = this.dialog.open(FilterDialogComponent, {
      width: '600px',
      data: <FilterData>{
        filterFarm: this.filterFarm,
        filterProduct: this.filterProduct,
        filterBatch: this.filterBatch,
        filterValidity: this.filterValidity,
        filterMode: this.filterMode,
        filterCreatedAt: this.filterCreatedAt,
        filterUpdatedAt: this.filterUpdatedAt,
        filterStockDate: this.filterStockDate,
        farms: this.farms,
        products: this.products,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.filterFarm = result.filterFarm;
        this.filterProduct = result.filterProduct;
        this.filterBatch = result.filterBatch;
        this.filterValidity = result.filterValidity;
        this.filterMode = result.filterMode;
        this.filterCreatedAt = result.filterCreatedAt;
        this.filterUpdatedAt = result.filterUpdatedAt;
        this.filterStockDate = result.filterStockDate;
        this.cdr.markForCheck();
      }
    });
  }

  searchStock(): void {
    console.log('Pesquisar estoque');
  }

  downloadStock(): void {
    console.log('Download do estoque');
  }

  trackByFn(index: number, item: any): any {
    return item.id;
  }

  getBatchDate(batch: any): string {
    const created = new Date(batch.created_at);
    const updated = new Date(batch.updated_at);
    if (created.getTime() === updated.getTime()) {
      return `Criado: ${updated.toLocaleString()}`;
    } else if (updated.getTime() > created.getTime()) {
      return `Atualizado: ${updated.toLocaleString()}`;
    }
    return updated.toLocaleString();
  }

  // Exibe alerta para o nível mínimo se o total estiver a até 5 unidades do mínimo
  shouldShowMinAlert(item: any): boolean {
    if (item.total_stock == null || item.minimun_level == null) return false;
    return item.total_stock <= (item.minimun_level + 5);
  }

  // Exibe alerta para validade se a data estiver a 15 dias ou menos da data atual
  shouldShowValidityAlert(validity: string): boolean {
    if (!validity) return false;
    const validityDate = this.parseDate(validity);
    const today = new Date();
    const diffDays = (validityDate.getTime() - today.getTime()) / (1000 * 3600 * 24);
    return diffDays <= 15;
  }

  // Retorna o nível de alerta para o lote: 'none', 'medium' ou 'high'
  getBatchAlertLevel(batch: any): string {
    if (!batch || (!batch.created_at && !batch.updated_at)) return 'none';
    const lastChange = batch.updated_at ? new Date(batch.updated_at) : new Date(batch.created_at);
    const today = new Date();
    const diffDays = (today.getTime() - lastChange.getTime()) / (1000 * 3600 * 24);
    console.log('diffDays:', diffDays);
    if (diffDays >= 30) return 'high';
    if (diffDays >= 15) return 'medium';
    return 'none';
  }

  getProductColor(productId: number): string {
    const product = this.products.find(prod => prod.id === productId);
    return product ? product.color : '#fff';
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}