<h2 mat-dialog-title>Filtrar Estoque</h2>
<mat-dialog-content class="filters-dialog-content">
  <!-- Filtro de Fazenda -->
  <mat-form-field appearance="outline" class="filter-field">
    <mat-label>Fazenda</mat-label>
    <mat-select [(ngModel)]="filterData.filterFarm">
      <mat-option [value]="''">Todas</mat-option>
      <mat-option *ngFor="let farm of filterData.farms" [value]="farm.id">
        {{ farm.identity_name }} - {{ farm.farm_name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <!-- Filtro de Produto -->
  <mat-form-field appearance="outline" class="filter-field">
    <mat-label>Produto</mat-label>
    <mat-select [(ngModel)]="filterData.filterProduct">
      <mat-option [value]="''">Todos</mat-option>
      <mat-option *ngFor="let product of filterData.products" [value]="product.description">
        {{ product.description }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <!-- Filtro de Lote -->
  <mat-form-field appearance="outline" class="filter-field">
    <mat-label>Lote</mat-label>
    <input matInput [(ngModel)]="filterData.filterBatch" placeholder="Filtrar por lote">
  </mat-form-field>
  <!-- Filtro de Validade -->
  <mat-form-field appearance="outline" class="filter-field">
    <mat-label>Validade</mat-label>
    <input matInput [matDatepicker]="validityPicker" [(ngModel)]="filterData.filterValidity" placeholder="Selecione a data">
    <mat-datepicker-toggle matSuffix [for]="validityPicker"></mat-datepicker-toggle>
    <mat-datepicker #validityPicker></mat-datepicker>
  </mat-form-field>
  <!-- Filtro de Tipo -->
  <mat-form-field appearance="outline" class="filter-field">
    <mat-label>Tipo</mat-label>
    <mat-select [(ngModel)]="filterData.filterMode">
      <mat-option [value]="''">Todos</mat-option>
      <mat-option value="ondemand">OnDemand</mat-option>
      <mat-option value="onfarm">OnFarm</mat-option>
    </mat-select>
  </mat-form-field>
  <!-- Filtro de Criado -->
  <mat-form-field appearance="outline" class="filter-field">
    <mat-label>Criado</mat-label>
    <input matInput [matDatepicker]="createdPicker" [(ngModel)]="filterData.filterCreatedAt" placeholder="Selecione a data">
    <mat-datepicker-toggle matSuffix [for]="createdPicker"></mat-datepicker-toggle>
    <mat-datepicker #createdPicker></mat-datepicker>
  </mat-form-field>
  <!-- Filtro de Atualizado -->
  <mat-form-field appearance="outline" class="filter-field">
    <mat-label>Atualizado</mat-label>
    <input matInput [matDatepicker]="updatedPicker" [(ngModel)]="filterData.filterUpdatedAt" placeholder="Selecione a data">
    <mat-datepicker-toggle matSuffix [for]="updatedPicker"></mat-datepicker-toggle>
    <mat-datepicker #updatedPicker></mat-datepicker>
  </mat-form-field>
  <!-- Filtro de Data de Estoque -->
  <mat-form-field appearance="outline" class="filter-field">
    <mat-label>Data de Estoque</mat-label>
    <input matInput [matDatepicker]="stockDatePicker" [(ngModel)]="filterData.filterStockDate" placeholder="Selecione a data">
    <mat-datepicker-toggle matSuffix [for]="stockDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #stockDatePicker></mat-datepicker>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="onCancel()">Cancelar</button>
  <button mat-button color="primary" (click)="onApply()">Aplicar</button>
</mat-dialog-actions>