//src/app/core/navigation/navigation.service.ts
import { FuseNavigationItem } from './../../../@fuse/components/navigation/navigation.types';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, of } from 'rxjs';
import { Navigation } from 'app/core/navigation/navigation.types';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  compactNavigation: FuseNavigationItem[] = [];

  menu: Navigation = {
    compact: this.compactNavigation,
    default: this.compactNavigation,
    futuristic: this.compactNavigation,
    horizontal: this.compactNavigation,
  };

  public role: string = '';

  private _navigation: ReplaySubject<Navigation> =
    new ReplaySubject<Navigation>(1);

  constructor(private _httpClient: HttpClient) {}

  get navigation$(): Observable<Navigation> {
    return this._navigation.asObservable();
  }

  get(roles): Observable<Navigation> {
    this.compactNavigation = [
      {
        id: 'farms',
        title: 'Fazendas',
        type: 'basic',
        icon: 'mat_solid:corporate_fare',
        link: '/farms',
      },
      {
        id: 'sales',
        title: 'Comercial',
        type: 'basic',
        icon: 'feather:shopping-cart',
        link: '/sales',
      },

      // {
      //   id: 'timeline',
      //   title: 'Relatório Onfarm',
      //   type: 'basic',
      //   icon: 'heroicons_outline:clipboard-check',
      //   link: '/time-line',
      // },
      {
        id: 'stock',
        title: 'Estoque',
        type: 'basic',
        icon: 'heroicons_outline:archive',
        link: '/stock',
      },
      {
        id: 'bio-factories',
        title: 'BioFábricas',
        type: 'basic',
        icon: 'heroicons_outline:beaker',
        link: '/biofactories',
      },

      {
        id: 'service-orders',
        title: 'Ordens de Serviço',
        type: 'basic',
        icon: 'heroicons_outline:document-text',
        link: '/service-orders',
      },
      {
        id: 'checklists',
        title: 'Checklists',
        type: 'basic',
        icon: 'checklist',
        link: '/checklists',
      },
      {
        id: 'reports',
        title: 'Laudos',
        type: 'basic',
        icon: 'heroicons_outline:document-report',
        link: '/reports',
      },
      // Oculto por ainda não estar disponível para uso
      // {
      //   id: 'ea-protocol',
      //   title: 'Protocolo de Campo',
      //   type: 'basic',
      //   icon: 'heroicons_outline:flag',
      //   link: '/ea-protocol',
      // },
      {
        id: 'diaries',
        title: 'Diário de Visitas',
        type: 'basic',
        icon: 'event_note',
        link: '/diaries',
      },
      {
        id: 'compatibilidade',
        title: 'Compatibilidade',
        type: 'basic',
        icon: 'heroicons_outline:tag',
        link: '/compatibilidade',
      },
      {
        id: 'simulator',
        title: 'Simulador de Compatabilidade',
        type: 'basic',
        icon: 'heroicons_outline:calculator',
        link: '/type-simulator',
      },
      {
        id: 'calendar',
        title: 'Calendário',
        type: 'basic',
        icon: 'mat_solid:calendar_today',
        link: '/calendario',
      },
      // Oculto por ainda não estar disponível para uso
      //  {
      //    id: 'plan',
      //    title: 'Plano de manejo',
      //    type: 'basic',
      //    icon: 'mat_outline:grass',
      //    link: '/plan',
      //  },
      {
        id: 'settings',
        title: 'Configurações',
        type: 'basic',
        icon: 'mat_outline:settings',
        link: '/settings',
      },
    ];
    // Remove settings and squad menu if user is not admin
    if (
      !roles.map(r => r.scope).includes('admin') &&
      !roles.map(r => r.scope).includes('coordenador')
    ) {
      this.compactNavigation = this.compactNavigation.filter(
        f => f.id != 'settings'
      );
    }

    // Remove farm menu if user hasn´t admin, solubio or franchise role
    if (
      !roles.map(r => r.scope).includes('admin') &&
      !roles.map(r => r.scope).includes('coordenador') &&
      !roles.map(r => r.scope).includes('solubio') &&
      !roles.map(r => r.scope).includes('franchise') &&
      !roles.map(r => r.scope).includes('comercial') &&
      !roles.map(r => r.scope).includes('laboratorio')
    ) {
      this.compactNavigation = this.compactNavigation.filter(
        f => f.id != 'farms'
      );
    }

    // Show only report menu if user role is laboratorio
    if (roles.map(r => r.scope).includes('laboratorio')) {
      this.compactNavigation = this.compactNavigation.filter(
        f => f.id != 'service-orders'
      );
      this.compactNavigation = this.compactNavigation.filter(
        f => f.id != 'checklists'
      );
      this.compactNavigation = this.compactNavigation.filter(
        f => f.id != 'dailies'
      );
    }

    if (roles.map(r => r.scope).includes('cliente')) {
      this.compactNavigation = this.compactNavigation.filter(
        f =>
          f.id === 'service-orders' ||
          f.id === 'bio-factories' ||
          f.id === 'timeline' ||
          f.id === 'sales' ||
          f.id === 'simulator'
      );
    }
    if (roles.map(r => r.scope).includes('comercial')) {
      this.compactNavigation = this.compactNavigation.filter(
        f => f.id === 'sales'
      );
    }
    // Remove simulator menu if user hasn't cliente role
    if (!roles.map(r => r.scope).includes('cliente')) {
      this.compactNavigation = this.compactNavigation.filter(
        f => f.id != 'simulator'
      );
    }

    this.menu.compact = this.compactNavigation;

    this.role = roles.map(r => r.scope);
    localStorage.setItem('role', this.role);
    const regra = roles.map(r => r.scope);

    this._navigation.next(this.menu);
    return of(this.menu);
  }

  public async isAuthorized(): Promise<boolean> {
    if (
      this.role.includes('admin') ||
      this.role.includes('coordenador') ||
      this.role.includes('solubio') ||
      this.role.includes('comercial') ||
      this.role.includes('franchise') ||
      this.role.includes('laboratorio')
    ) {
      return true;
    } else {
      return false;
    }
  }
}

