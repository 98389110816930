<div class="stock-list-container">
    <!-- Cabeçalho com ícone de filtro e botão de novo estoque -->
    <div class="stock-header">
        <button mat-flat-button color="primary" (click)="openFilterDialog()">
            <mat-icon>search</mat-icon>
            <span>Filtrar</span>
        </button>
        <button mat-flat-button color="primary" (click)="createNewStock()">
            <mat-icon>add</mat-icon>
            <span>Criar Novo Estoque</span>
        </button>
    </div>
    <!-- Wrapper para tabela com scroll horizontal -->
    <div class="table-wrapper">
        <!-- Tabela de Estoques -->
        <table class="stock-table">
            <thead>
                <tr>
                    <th class="col-id">#ID</th>
                    <th class="col-farm">Fazenda</th>
                    <th class="col-product">Produto</th>
                    <th class="col-min">Nível Mín.</th>
                    <th class="col-total">Total</th>
                    <th class="col-batch">Lote</th>
                    <th class="col-validity">Validade</th>
                    <th class="col-mode">Tipo</th>
                    <th class="col-qty">Qtd</th>
                    <th class="col-date">Data</th>
                    <th class="col-action">Ação</th>
                </tr>
            </thead>
            <tbody>
                <ng-container
                    *ngFor="let item of filteredStock; trackBy: trackByFn"
                >
                    <!-- Linha principal exibe batch[0] -->
                    <tr
                        class="table-row main-row"
                        (click)="openStockForm(item, 0)"
                    >
                        <!-- Colunas que possuem o mesmo valor para todos os batches -->
                        <td class="col-id" [attr.rowspan]="item.batch.length">
                            <strong>#{{ item.id }}</strong>
                        </td>
                        <td class="col-farm" [attr.rowspan]="item.batch.length">
                            {{ item.farm }} <br />
                            <small class="stock-date">
                                {{ getBatchDate(item.batch[0]) }}
                            </small>
                        </td>
                        <td
                            class="col-product"
                            [attr.rowspan]="item.batch.length"
                        >
                            <span
                                class="product-badge"
                                [ngStyle]="{
                                    'background-color': getProductColor(
                                        item.product?.id
                                    )
                                }"
                            >
                                <strong>{{ item.product?.description }}</strong>
                            </span>
                        </td>
                        <td class="col-min" [attr.rowspan]="item.batch.length">
                            <span>
                                {{ item.minimun_level || '-' }}
                                <mat-icon
                                    *ngIf="shouldShowMinAlert(item)"
                                    class="alert-icon"
                                    >warning</mat-icon
                                >
                            </span>
                        </td>
                        <td
                            class="col-total"
                            [attr.rowspan]="item.batch.length"
                        >
                            <strong>{{ item.total_stock || '-' }}</strong>
                        </td>

                        <!-- Colunas que variam conforme cada batch -->
                        <td class="col-batch">
                            {{ item.batch[0]?.batch || '-' }}
                        </td>
                        <td class="col-validity">
                            <span>
                                {{ item.batch[0]?.validity || '-' }}
                                <mat-icon
                                    *ngIf="
                                        shouldShowValidityAlert(
                                            item.batch[0]?.validity
                                        )
                                    "
                                    class="alert-icon"
                                    >warning</mat-icon
                                >
                            </span>
                        </td>
                        <td class="col-mode">
                            {{ item.batch[0]?.mode || '-' }}
                        </td>
                        <td class="col-qty">
                            {{ item.batch[0]?.quantity || '-' }}
                        </td>
                        <td class="col-date">
                            {{ getBatchDate(item.batch[0]) }}
                            <ng-container
                                *ngIf="
                                    getBatchAlertLevel(item.batch[0]) ===
                                    'medium'
                                "
                            >
                                <mat-icon class="alert-icon"
                                    >report_problem</mat-icon
                                >
                            </ng-container>
                            <ng-container
                                *ngIf="
                                    getBatchAlertLevel(item.batch[0]) === 'high'
                                "
                            >
                                <mat-icon class="alert-icon">error</mat-icon>
                            </ng-container>
                        </td>
                        <td class="col-action">
                            <button
                                mat-button
                                (click)="
                                    openStockForm(item, 0);
                                    $event.stopPropagation()
                                "
                            >
                                Editar
                            </button>
                        </td>
                    </tr>

                    <!-- Demais linhas (sub-row) para batch[1..n] -->
                    <ng-container
                        *ngFor="let batch of item.batch; let j = index"
                    >
                        <tr
                            *ngIf="j > 0"
                            class="table-row sub-row"
                            (click)="openStockForm(item, j)"
                        >
                            <td class="col-batch">
                                {{ batch.batch || '-' }}
                                <mat-icon
                                    *ngIf="
                                        getBatchAlertLevel(batch) === 'medium'
                                    "
                                    class="alert-icon"
                                    >report_problem</mat-icon
                                >
                                <mat-icon
                                    *ngIf="
                                        getBatchAlertLevel(batch) === 'high'
                                    "
                                    class="alert-icon"
                                    >error</mat-icon
                                >
                            </td>
                            <td class="col-validity">
                                <span>
                                    {{ batch.validity || '-' }}
                                    <mat-icon
                                        *ngIf="
                                            shouldShowValidityAlert(
                                                batch.validity
                                            )
                                        "
                                        class="alert-icon"
                                        >warning</mat-icon
                                    >
                                </span>
                            </td>
                            <td class="col-mode">
                                {{ batch.mode || '-' }}
                            </td>
                            <td class="col-qty">
                                {{ batch.quantity || '-' }}
                            </td>
                            <td class="col-date">
                                {{ getBatchDate(batch) }}
                                <ng-container
                                    *ngIf="
                                        getBatchAlertLevel(batch) === 'medium'
                                    "
                                >
                                    <mat-icon class="alert-icon"
                                        >report_problem</mat-icon
                                    >
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        getBatchAlertLevel(batch) === 'high'
                                    "
                                >
                                    <mat-icon class="alert-icon"
                                        >error</mat-icon
                                    >
                                </ng-container>
                            </td>
                            <td class="col-action">
                                <button
                                    mat-button
                                    (click)="
                                        openStockForm(item, j);
                                        $event.stopPropagation()
                                    "
                                >
                                    Editar
                                </button>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>