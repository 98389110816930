import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Type } from '@angular/core';
import { Item } from '../../Types';
import {
  BehaviorSubject,
  Observable,
  catchError,
  finalize,
  map,
  startWith,
} from 'rxjs';
import { CurrencyPipe } from '@angular/common';
import { OpenSalesService } from '../open-sales.service';
import { UserService, User } from 'app/core/services/auth/user.service';
import { Console } from 'console';
import { ClientRequest } from 'http';
import { ModificarService } from 'app/modules/admin/dashboard/orders-open/modificar/modificar.service';
import { DialogPedidoComponent } from 'app/modules/admin/dashboard/orders-open/dialog-pedido/dialog-pedido.component';
import { ModificarComponent } from 'app/modules/admin/dashboard/orders-open/modificar/modificar.component';
import { DialogCancelarComponent } from './dialog-cancelar/dialog-cancelar.component';
import { DialogEditarComponent } from './dialog-editar/dialog-editar.component';
import { DialogErrorCancelarComponent } from 'app/modules/admin/dashboard/orders-open/dialog-error-cancelar/dialog-error-cancelar.component';
import { DialogErrorEditarComponent } from 'app/modules/admin/dashboard/orders-open/dialog-error-editar/dialog-error-editar.component';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { throwError } from 'rxjs';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatSidenavContainer } from '@angular/material/sidenav';

@Component({
  selector: 'app-open-sales-dialog',
  templateUrl: './open-sales-dialog.component.html',
  styleUrls: ['./open-sales-dialog.component.scss'],
})
export class OpenSalesDialogComponent implements OnInit, OnChanges {
  @ViewChild(MatSidenavContainer) sidenavContainer: MatSidenavContainer;
  @ViewChild('itensScroll') itensScroll: ElementRef;

  CodVenda = [
    { id: 212, Tipo: 'VFU' },
    { id: 222, Tipo: 'VDI' },
    { id: 210, Tipo: 'BNF' },
  ];

  codOrcamento: number = 0;

  itensEditados: string[] = [];

  tiposFiltrados: Observable<{ id: number; Tipo: string }[]>;
  apiUrl: string = '';

  codClienteControl = new FormControl();
  cpfControl = new FormControl();
  telefoneControl = new FormControl();
  fazendaControl = new FormControl();
  enderecoControl = new FormControl();
  BPLIdSelecionado: string;
  codPedidoControl = new FormControl();
  codControl = new FormControl();
  clienteControl = new FormControl();
  localEntregaControl = new FormControl();
  freteControl = new FormControl();
  observacoescadControl = new FormControl();
  textoControl = new FormControl();
  pagamentoControl = new FormControl();
  inscricaoControl = new FormControl();
  fretes: any[] = [];
  fretesFiltrados: any[] = [];
  itemControl = new FormControl();

  produtoControl = new FormControl();

  vendedorControl = new FormControl();

  vendedorFiltroControl = new FormControl(''); // Para buscar na lista
  _vendedoresFiltrados!: Observable<any[]>; // Lista filtrada como Observable

  freteCodigo: any = null;
  CdEntregaControl = new FormControl();
  cliente: any = null;
  items: any[] = [];
  clientes: any[] = [];
  clientesFiltrados: any[] = [];
  observacoesControl = new FormControl();
  numeroOCControl = new FormControl();
  quantidadeControl = new FormControl();
  descontoControl = new FormControl();
  aplicacaoControl = new FormControl();
  nItemControl = new FormControl();
  descricaoProdutoControl = new FormControl();
  precoUnitarioControl = new FormControl();
  dataEntregaControl = new FormControl();
  dataVencimentoControl = new FormControl();
  culturaControl = new FormControl();
  inscricaoMunicipalControl = new FormControl();
  condPagamentoControl = new FormControl();

  numeroHectaresControl = new FormControl('', [Validators.required]);
  doseBiologicaControl = new FormControl();
  quantidadeAplicacoesControl = new FormControl();
  sanatizacaoProdutoControl = new FormControl('');
  faixaCompraControl = new FormControl();
  formaPagamentoControl = new FormControl();
  condicaoPagamentoControl = new FormControl();
  buscaClienteControl = new FormControl();
  rKitControl = new FormControl({ value: '', disabled: true });
  nrKitControl = new FormControl({ value: '', disabled: true });
  regrasDeCalculo: any[] = [];

  ultimoNumAtCard: string;

  custoKgLControl = new FormControl('');
  custoTotalHaBiologicoControl = new FormControl('');
  newValorTotalControl = new FormControl('');

  loading: boolean = false;
  filtroForm: FormGroup;
  precoForm: FormGroup;
  vendedoresFiltrados: any[] = [];
  vendedores: any[] = [];
  linhasSelecionadas: any;
  dadosTabela: any[] = [];
  cardcodeSelecionado: number | null = null;
  SlpCodeSelecionado: number | null = null;
  vendedor: any = null;
  filteredVendedores: any;
  filteredCulturas: any;
  filteredItemDescriptions: any;
  filteredClientes: any;
  filteredCdEntrega: any;
  private indiceSequencial: number = 0;
  formapagamento: any = null;
  AtCard = new FormControl();
  PayMethCodSelecionado: number | null = null;
  filteredformaspagamento: any;
  formaspagamentoFiltradas: any[] = [];
  formaspagamento: any[] = [];
  editarAtivo: boolean = false;
  orcamentoSelecionado: any;
  filteredItems: any;
  itemsFiltrados: any[] = [];
  orcamentosFiltrados: any[] = [];
  orcamentos: any[] = [];
  cdsEntrega: any[] = [];
  cdsEntregaFiltrados: any[] = [];
  cdEntrega: any = null;
  GroupNumSelecionado: string;
  condicoesPagamento: any[] = [];
  condicoesPagamentoFiltradas: any[] = [];
  filteredCondicaoPagamento: any;
  condicao: any = null;
  culturasFiltradas: any[] = [];
  culturas: any[] = [];
  currentUser: User;
  isClient = false;
  isComercial = false;
  isAdmin = false;
  private ultimoAtCard: string = '';

  condicaoPagamentoFiltroControl = new FormControl('');
  _condicoesPagamentoFiltradas!: Observable<any[]>;

  meioPagamentoFiltroControl = new FormControl('');
  _formaspagamentoFiltradas!: Observable<any[]>;

  dadosCabecalhoValidos: boolean = false;
  camposInvalidosCabecalho: string[] = [];
  dadosPriceValidos: boolean = false;
  camposInvalidosPrice: string[] = [];
  validDataProductItem: boolean = false;
  camposInvalidosProduto: string[] = [];
  formProdutoIniciado: boolean = false;
  formPriceIniciado: boolean = false;

  somaValorTotalItens: number = 0;

  totalInvestimentoPorHa: number = 0;
  investimentoTotalNoManejo: number = 0;
  numberCard: any[] = [];
  numberCardFiltrado: any[] = [];
  contadorPedido: number = 0;

  faixasDeCompra: any[] = [];
  tiposSanitizacao: string[] = [];

  indiceItemEditando: number | null = null;

  constructor(
    private currencyPipe: CurrencyPipe,
    public dialogRef: MatDialogRef<OpenSalesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private openService: OpenSalesService,
    private userAuth: UserService,
    private modificarService: ModificarService,
    private changeDetectorRef: ChangeDetectorRef,
    private http: HttpClient,
    private fb: FormBuilder,
    public dialog: MatDialog
  ) {
    let items: Item[] = [];
    this.apiUrl = environment.apiSap;
  }

  ngOnChanges() {
    this.toggleFormControls();
  }

  onChanges(): void {
    this.filtroForm.valueChanges.subscribe(val => {
      this.filtrarClientes();
    });

    this.filtroForm.valueChanges.subscribe(() => {});
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    this.carregarMeioCultura();
    this.carregarFaixasDeCompra();
    this.carregarTiposSanitizacao();
    this.carregarCondicaoPagamento();

    // 🔥 Garante que o campo não inicie como null ou undefined
    this.condicaoPagamentoFiltroControl.setValue(
      this.condicaoPagamentoFiltroControl.value || ''
    );

    this.carregarVendedores();
    this.carregarClientes();
    this.carregarMetodoPagamento();

    // 🔥 Garante que o campo não inicie como null ou undefined
    this.meioPagamentoFiltroControl.setValue(
      this.meioPagamentoFiltroControl.value || ''
    );

    this.carregarCdsEntrega();
    this.carregarOrcamentos();
    this.carregarItems();
    this.carregarUser();

    this.carregarFretes();

    this.clienteControl = new FormControl();
    this.fazendaControl = new FormControl();
    this.telefoneControl = new FormControl();
    this.localEntregaControl = new FormControl();

    this.precoForm = this.fb.group({
      precoUnitario: [''],
    });

    this.filtroForm = new FormGroup({
      CardCode: new FormControl(''),
      Nome_do_PN: new FormControl(''),
      CNPJ_CPF: new FormControl(''),
      Telefone: new FormControl(''),
      U_CADF_Fazenda: new FormControl(''),
    });
    this.tiposFiltrados = this.codControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterTipos(value))
    );

    this.filteredClientes = this.clienteControl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.Nome_do_PN)),
      map(nome => {
        const cardCode = this.data.orcamento.CardCode || '';
        return this._filterClientes(nome, cardCode);
      })
    );
    /*this.precoUnitarioControl.valueChanges.subscribe(value => {
      if (value !== null && value !== undefined) {
        const formattedValue = this.currencyPipe.transform(
          value,
          'BRL',
          'symbol',
          '1.0-2'
        );
        this.precoUnitarioControl.setValue(formattedValue, {
          emitEvent: false,
        });
      }
    });*/
    this.precoUnitarioControl.valueChanges.subscribe(value => {
      if (value !== null && value !== undefined) {
        const numericValue = this.converterValorMonetarioParaNumero(value);
        const formattedValue = this.currencyPipe.transform(
          numericValue,
          'BRL',
          'symbol',
          '1.0-2'
        );
        this.precoUnitarioControl.setValue(formattedValue, {
          emitEvent: false,
        });
      }
    });

    this.clienteControl.valueChanges
      .pipe(
        startWith(''),
        map(value => (typeof value === 'string' ? value : value.Nome_do_PN)),
        map(nome => {
          const cardCode = this.data.orcamento.CardCode || '';
          return this._filterClientes(nome, cardCode);
        })
      )
      .subscribe((clientesFiltrados: any[]) => {
        this.clientesFiltrados = clientesFiltrados;
      });

    this.filteredformaspagamento = this.pagamentoControl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.Descript)),
      map(nome => {
        const PayMethodCode = this.data.orcamento.PaymentMethod || '';
        return this._filterPagamentos(nome, PayMethodCode);
      })
    );

    this.filteredCulturas = this.culturaControl.valueChanges.pipe(
      startWith(''),
      map(value =>
        typeof value === 'string' ? value : value ? value.Name : ''
      ),
      map(name => (name ? this._filterCulturas(name) : this.culturas.slice()))
    );

    this.filteredVendedores = this.vendedorControl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.SlpName)),
      map(nome => {
        const salesPersonCode = this.data.orcamento.SalesPersonCode || '';
        return this._filterVendedores(nome, salesPersonCode);
      })
    );
    this.filteredItems = this.nItemControl.valueChanges.pipe(
      startWith(''),
      map(value =>
        typeof value === 'string' ? value : value ? value.ItemCode : ''
      ),
      map(name => (name ? this._filterItems(name) : this.items.slice()))
    );

    this.filteredItemDescriptions =
      this.descricaoProdutoControl.valueChanges.pipe(
        startWith(''),
        map(value =>
          typeof value === 'string' ? value : value ? value.ItemName : ''
        ),
        map(description =>
          description
            ? this._filterItemDescriptions(description)
            : this.items.slice()
        )
      );

    /*this.vendedorControl.valueChanges
      .pipe(
        startWith(''),
        map(value => (typeof value === 'string' ? value : value.SlpName)),
        map(nome => {
          const salesPersonCode = this.data.orcamento.SalesPersonCode || '';
          return this._filterVendedores(nome, salesPersonCode);
        })
      )
      .subscribe((vendedoresFiltrados: any[]) => {
        this.filteredVendedores = vendedoresFiltrados;
      });*/

    this.filteredVendedores = this.vendedorControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        if (!value) return ''; // valor vazio, não filtra
        return typeof value === 'string' ? value : value?.SlpName ?? '';
      }),
      map(nome => {
        const salesPersonCode = this.data.orcamento?.SalesPersonCode || '';
        return this._filterVendedores(nome, salesPersonCode);
      })
    );

    this.culturaControl.valueChanges
      .pipe(
        startWith(''),
        map(value =>
          typeof value === 'string' ? value : value ? value.Name : ''
        ),
        map(name => (name ? this._filterCulturas(name) : this.culturas.slice()))
      )
      .subscribe((culturasFiltradas: any[]) => {
        this.filteredCulturas = culturasFiltradas;
      });

    if (this.data) {
      this.codClienteControl.setValue(this.data.orcamento.CardCode);
      this.clienteControl.setValue(this.data.orcamento.CardName);
      this.cpfControl.setValue(this.data.orcamento.TaxExtension.CNPJ);
      this.observacoescadControl.setValue(this.data.orcamento.Comments);
      this.dataVencimentoControl.setValue(this.data.orcamento.DocDueDate);
      this.condPagamentoControl.setValue(this.data.orcamento.PaymentGroupCode);
      this.pagamentoControl.setValue(this.data.orcamento.U_SOLU_MEIO_PAG);
      this.AtCard.setValue(this.data.orcamento.NumAtCard);
      this.codPedidoControl.setValue(this.data.orcamento.DocNum);
      this.codControl.setValue(this.data.orcamento.DocumentLines.Usage);
      this.CdEntregaControl.setValue(this.data.orcamento.BPLName);
      this.enderecoControl.setValue(this.data.orcamento.Address);
      this.inscricaoControl.setValue(
        this.data.orcamento.TaxExtension.inscricao_estadual
      );
      this.freteControl.setValue(this.data.orcamento.TaxExtension.Incoterms);

      if (
        this.data.orcamento.DocumentLines &&
        Array.isArray(this.data.orcamento.DocumentLines)
      ) {
        this.data.orcamento.DocumentLines.forEach((line: any) => {
          this.adicionarItemFromAPI(line);
        });
      } else {
      }
      const salesPersonCode = this.data.orcamento.SalesPersonCode;
      const vendedor = this.vendedores.find(v => v.SlpCode === salesPersonCode);
      if (vendedor) {
        this.vendedorControl.setValue(vendedor ? vendedor.SlpName : '');
      }
      const cardCode = this.data.orcamento.CardCode;
      const cliente = this.clientes.find(c => c.CardCode === cardCode);
      if (cliente) {
        this.clienteControl.setValue(cliente ? cliente.Nome_do_PN : '');
        this.telefoneControl.setValue(cliente ? cliente.Telefone : '');
        this.fazendaControl.setValue(cliente ? cliente.U_CADF_Fazenda : '');
        this.localEntregaControl.setValue(
          cliente ? cliente.U_CADF_Fazenda : ''
        );
      }

      const cdsEntregaName = this.data.orcamento.BPLName;
      const cdEntrega = this.cdsEntrega.find(c => c.BPLName === cdsEntregaName);
      if (cdEntrega) {
        this.BPLIdSelecionado = cdEntrega.BPLId;
      }

      const codigoVenda = this.data.orcamento.DocumentLines.Usage;
      const tipoCodVenda = this.getTipoByCodVenda(codigoVenda);
      if (tipoCodVenda) {
        this.codControl.setValue(tipoCodVenda);
      }

      const codigoFrete = this.data.orcamento.TaxExtension.Incoterms;
      const freteSelecionado = this.fretes.find(
        frete => frete.CODIGO === codigoFrete
      );

      if (freteSelecionado) {
        this.freteControl.setValue(
          freteSelecionado ? freteSelecionado.DESCRICAO : ''
        );
      }
    }

    console.log('Orçamento', this.data.orcamento);

    this.filteredCulturas = this.culturaControl.valueChanges.pipe(
      startWith(''),
      map(value =>
        typeof value === 'string' ? value : value ? value.Name : ''
      ),
      map(name => (name ? this._filterCulturas(name) : this.culturas.slice()))
    );

    this.culturaControl.valueChanges
      .pipe(
        startWith(''),
        map(value =>
          typeof value === 'string' ? value : value ? value.Name : ''
        ),
        map(name => (name ? this._filterCulturas(name) : this.culturas.slice()))
      )
      .subscribe((culturasFiltradas: any[]) => {
        this.filteredCulturas = culturasFiltradas;
      });

    this.CdEntregaControl.valueChanges
      .pipe(
        startWith(''),
        map(value => (typeof value === 'string' ? value : value.BPLName)),
        map(nome =>
          nome ? this._filterCdsEntrega(nome) : this.cdsEntrega.slice()
        )
      )
      .subscribe((cdsEntregaFiltrados: any[]) => {
        this.filteredCdEntrega = cdsEntregaFiltrados;
      });

    this.onChanges();
    this.changeDetectorRef.detectChanges();
    this.codControl = new FormControl(
      { value: '', disabled: this.selectUtilizado !== false },
      Validators.required
    );
    this.quantidadeAplicacoesControl.valueChanges.subscribe(() =>
      this.calcularCustoTotalHaBiologico()
    );
    this.doseBiologicaControl.valueChanges.subscribe(() =>
      this.calcularCustoTotalHaBiologico()
    );
    this.custoKgLControl.valueChanges.subscribe(() =>
      this.calcularCustoTotalHaBiologico()
    );
    this.quantidadeControl.valueChanges.subscribe(() =>
      this.validarERecalcularValorTotal()
    );
    this.precoUnitarioControl.valueChanges.subscribe(() =>
      this.validarERecalcularValorTotal()
    );

    this.quantidadeAplicacoesControl.valueChanges.subscribe(() =>
      this.atualizarNrKit()
    );
    this.doseBiologicaControl.valueChanges.subscribe(() =>
      this.atualizarNrKit()
    );
    this.numeroHectaresControl.valueChanges.subscribe(() =>
      this.atualizarNrKit()
    );

    const campos = [
      this.dataEntregaControl,
      this.freteControl,
      this.CdEntregaControl,
      this.vendedorControl,
      this.condicaoPagamentoFiltroControl,
      this.formaPagamentoControl,
    ];

    campos.forEach(control => {
      control.valueChanges.subscribe(() => {
        this.validarCabecalho();
      });
    });

    const camposPrice = [
      this.faixaCompraControl,
      this.sanatizacaoProdutoControl,
    ];

    camposPrice.forEach(control => {
      control.valueChanges.subscribe(() => {
        this.validarPriceValid();
      });
    });

    const camposProductItem = [
      this.nItemControl,
      this.descricaoProdutoControl,
      this.quantidadeAplicacoesControl,
      this.doseBiologicaControl,
      this.numeroHectaresControl,
      this.precoUnitarioControl,
      this.quantidadeControl,
      this.descontoControl,
      this.codControl,
      this.aplicacaoControl,
      this.culturaControl,
      this.dataEntregaControl,
    ];

    camposProductItem.forEach(control => {
      control.valueChanges.subscribe(() => {
        this.validarProductItem();
      });
    });

    this.toggleFormControls();

    this.fieldsDisabledEditActive();
    this.fieldsItemDisabledEditActive();
    this.fieldsPricemDisabledEditActive();

    this.valueChangesFieldsHeaderValidarCabeçalho();

    this.atualizarCalculos();

    this.codOrcamento = this.codPedidoControl.value;

    this.changeDetectorRef.detectChanges();
  }

  private _filterCdsEntrega(nome: string): any[] {
    const filterValue = nome.toLowerCase();
    return this.cdsEntrega.filter(cdEntrega =>
      cdEntrega.BPLName.toLowerCase().includes(filterValue)
    );
  }

  formatarPreco(): void {
    let valor = this.precoUnitarioControl.value;
    if (valor) {
      const valorNumerico = parseFloat(
        valor.replace(/[^\d,.-]/g, '').replace(',', '.')
      );
      const precoUnitarioFormatado = this.currencyPipe.transform(
        valorNumerico,
        'BRL',
        'symbol',
        '1.2-2'
      );
      this.precoUnitarioControl.setValue(precoUnitarioFormatado, {
        emitEvent: false,
      });
    }
  }

  private _filterTipos(value: string): { id: number; Tipo: string }[] {
    const filterValue = value.toLowerCase();
    return this.CodVenda.filter(option =>
      option.Tipo.toLowerCase().includes(filterValue)
    );
  }

  private _filter(value: string): any[] {
    const filterValue = this._formatPreco(value);
    return this.items.filter(option =>
      this.currencyPipe
        .transform(option.Price, 'BRL', 'symbol', '1.2-2')
        .includes(filterValue)
    );
  }

  private _formatPreco(valor: string): string {
    const valorNumerico = parseFloat(
      valor.replace(/[^\d,.-]/g, '').replace(',', '.')
    );
    return (
      this.currencyPipe.transform(valorNumerico, 'BRL', 'symbol', '1.2-2') ?? ''
    );
  }

  async carregarUser(): Promise<void> {
    const user = await this.userAuth.get().toPromise();
    this.currentUser = user;
    this.isClient = user.roles.some(r => r.scope === 'cliente');
    this.isComercial = user.roles.some(r => r.scope === 'comercial');
    this.isAdmin = user.roles.some(r => r.scope === 'admin');
    if (this.isClient) {
      this.isClient = true;
    }
  }

  private _filterClientes(nome: string, cardCode: string): any[] {
    const filterValue = nome.toLowerCase();
    return this.clientes.filter(
      cliente =>
        (cardCode
          ? cliente.CardCode.toLowerCase().includes(cardCode.toLowerCase())
          : true) && cliente.Nome_do_PN.toLowerCase().includes(filterValue)
    );
  }

  private _filterVendedores(nome: string, salesPersonCode: string): any[] {
    const filterValue = nome.toLowerCase();
    return this.vendedores.filter(
      vendedor =>
        (salesPersonCode
          ? vendedor.SlpCode.toString()
              .toLowerCase()
              .includes(salesPersonCode.toString().toLowerCase())
          : true) && vendedor.SlpName.toLowerCase().includes(filterValue)
    );
  }

  carregarVendedores(): void {
    this.loading = true;
    this.openService.obterVendedores().subscribe(
      vendedores => {
        if (!vendedores || vendedores.length === 0) {
          console.warn('Nenhum vendedor carregado!');
          this.loading = false;
          return;
        }

        this.vendedores = vendedores;

        this.configurarFiltragemVendedores();

        // Se houver um vendedor já selecionado, carregá-lo corretamente
        const salesPersonCode = this.data.orcamento?.SalesPersonCode;
        if (salesPersonCode) {
          this.selecionarVendedorPorCodigo(salesPersonCode);
        }

        this.loading = false;
      },
      error => {
        this.loading = false;
        console.error('Erro ao carregar vendedores:', error);
      }
    );
  }

  configurarFiltragemVendedores(): void {
    this._vendedoresFiltrados = this.vendedorFiltroControl.valueChanges.pipe(
      startWith(''),
      map(valor => this._filtrarVendedores(valor || ''))
    );
  }

  private _filtrarVendedores(valor: string): any[] {
    if (!this.vendedores || this.vendedores.length === 0) {
      return [];
    }

    const filtro = valor.toLowerCase();
    return this.vendedores.filter(vendedor =>
      vendedor.SlpName.toLowerCase().includes(filtro)
    );
  }

  filtrarVendedores(): void {
    const { SlpCode, SlpName } = this.filtroForm.value;
    this.vendedoresFiltrados = this.vendedores.filter(vendedor => {
      return (
        (SlpCode
          ? vendedor.SlpCode.toString()
              .toLowerCase()
              .includes(SlpCode.toLowerCase())
          : true) &&
        (SlpName
          ? (vendedor.SlpName || '')
              .toLowerCase()
              .includes(SlpName.toLowerCase())
          : true)
      );
    });
  }

  selecionarVendedor(event: any): void {
    const selectedSlpCode = event.option.value;
    const vendedor = this.vendedores.find(v => v.SlpCode === selectedSlpCode);

    if (vendedor) {
      this.vendedorControl.setValue(vendedor.SlpName);
      this.SlpCodeSelecionado = vendedor.SlpCode;
      this.vendedorFiltroControl.setValue(vendedor.SlpName, {
        emitEvent: false,
      });
    }
  }

  selecionarVendedorPorCodigo(salesPersonCode: number): void {
    const vendedor = this.vendedores.find(v => v.SlpCode === salesPersonCode);
    if (vendedor) {
      this.vendedorControl.setValue(vendedor.SlpName); // Exibe o nome na inicialização
      this.vendedorFiltroControl.setValue(vendedor.SlpName, {
        emitEvent: false,
      }); // Mantém o nome visível
    }
  }

  abrirDropdown(): void {
    if (!this.vendedorFiltroControl.value) {
      this.vendedorFiltroControl.setValue(' '); // Garante que o dropdown abre
    }
  }

  private _filterItemDescriptions(name: string): any[] {
    const filterValue = name.toLowerCase();
    return this.items.filter(
      item =>
        (item.ItemCode && item.ItemCode.toLowerCase().includes(filterValue)) ||
        (item.ItemName || '').toLowerCase().includes(filterValue)
    );
  }

  async carregarItems(): Promise<void> {
    try {
      this.loading = true;
      const response = await this.http
        .get<any>(this.apiUrl + '/api/consulta/listaItems')
        .toPromise();
      this.items = response.listaItems;
      this.itemsFiltrados = [...this.items];
      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  }

  private _filterItems(name: string): any[] {
    const filterValue = name.toLowerCase();
    return this.items.filter(
      item =>
        (item.ItemCode && item.ItemCode.toLowerCase().includes(filterValue)) ||
        (item.ItemName || '').toLowerCase().includes(filterValue)
    );
  }

  filtrarItens(): void {
    const { ItemCode, ItemName } = this.filtroForm.value;
    this.itemsFiltrados = this.items.filter(item => {
      return (
        (ItemCode
          ? item.ItemCode.toLowerCase().includes(ItemCode.toLowerCase())
          : true) &&
        (ItemName
          ? (item.ItemName || '').toLowerCase().includes(ItemName.toLowerCase())
          : true)
      );
    });
  }

  displayItem(item: any): string {
    return item
      ? item.ItemCode && item.ItemName
        ? `${item.ItemCode} - ${item.ItemName}`
        : item.ItemCode || item.ItemName || ''
      : '';
  }

  carregarFretes(): void {
    this.loading = true;
    this.openService.carregarFrete().subscribe(
      fretes => {
        this.fretes = fretes;
        this.fretesFiltrados = [...this.fretes];
        this.loading = false;
        const freteCod = this.data.orcamento.TaxExtension.Incoterms;
        this.selecionarFrete(freteCod);
      },
      error => {
        console.error('Erro ao carregar lista de fretes: ', error);
        this.loading = false;
      }
    );
  }

  filtrarFretes(): void {
    const { CODIGO, DESCRICAO } = this.filtroForm.value;

    this.fretesFiltrados = this.fretes.filter(frete => {
      return (
        (CODIGO
          ? frete.CODIGO.toString().toLowerCase().includes(CODIGO.toLowerCase())
          : true) &&
        (DESCRICAO
          ? (frete.DESCRICAO || '')
              .toLowerCase()
              .includes(DESCRICAO.toLowerCase())
          : true)
      );
    });
  }

  selecionarFrete(frete: any): void {
    let codigoFreteSelecionado: string;

    if (typeof frete === 'string') {
      codigoFreteSelecionado = frete; // Chamado com código ao carregar fretes
    } else if (frete?.option?.value) {
      codigoFreteSelecionado = this.fretes.find(
        f => f.DESCRICAO === frete.option.value
      )?.CODIGO;
    }

    if (codigoFreteSelecionado) {
      const freteSelecionado = this.fretes.find(
        f => f.CODIGO === codigoFreteSelecionado
      );
      if (freteSelecionado) {
        this.freteControl.setValue(freteSelecionado.DESCRICAO);
        this.freteCodigo = freteSelecionado.CODIGO;
      }
    }
  }

  selecionarProduto(event: any) {
    const itemSelecionado = this.itemsFiltrados.find(
      item =>
        item.ItemCode === event.option.value ||
        item.ItemName === event.option.value
    );
    if (itemSelecionado) {
      this.nItemControl.setValue(itemSelecionado.ItemCode, {
        emitEvent: false,
      });
      this.descricaoProdutoControl.setValue(itemSelecionado.ItemName, {
        emitEvent: false,
      });
      this.precoUnitarioControl.setValue(
        this.currencyPipe.transform(
          itemSelecionado.Price,
          'BRL',
          'symbol',
          '1.2-2'
        ),
        { emitEvent: false }
      );
    }
  }

  async carregarClientes(): Promise<void> {
    this.loading = true;
    try {
      const clientes = await this.openService.obterClientes().toPromise();
      this.clientes = clientes;
      this.clientesFiltrados = [...this.clientes];
      this.loading = false;

      const cardCode = this.data.orcamento.CardCode;

      if (cardCode) {
        this.selecionarClientePorCardCode(cardCode);
      }
    } catch (error) {
      this.loading = false;
    }
  }

  filtrarClientes(): void {
    const { U_CADF_Fazenda, Nome_do_PN, CNPJ_CPF, Telefone, CardCode } =
      this.filtroForm.value;
    this.clientesFiltrados = this.clientes.filter(cliente => {
      return (
        (U_CADF_Fazenda
          ? cliente.U_CADF_Fazenda.toLowerCase().includes(
              U_CADF_Fazenda.toLowerCase()
            )
          : true) &&
        (Nome_do_PN
          ? (cliente.Nome_do_PN || '')
              .toLowerCase()
              .includes(Nome_do_PN.toLowerCase())
          : true) &&
        (CardCode
          ? (cliente.CardCode || '')
              .toLowerCase()
              .includes(CardCode.toLowerCase())
          : true) &&
        (CNPJ_CPF
          ? (cliente.CNPJ_CPF || '')
              .toLowerCase()
              .includes(CNPJ_CPF.toLowerCase())
          : true) &&
        (Telefone
          ? (cliente.Telefone || '')
              .toLowerCase()
              .includes(Telefone.toLowerCase())
          : true)
      );
    });
  }

  selecionarCliente(event: any) {
    const cardcodeSelecionado = event.option.value;
    this.data.orcamento.CardCode = cardcodeSelecionado;

    const cliente = this.clientes.find(c => c.CardCode === cardcodeSelecionado);

    if (cliente) {
      this.clienteControl.setValue(cliente.Nome_do_PN);
      this.fazendaControl.setValue(cliente.U_CADF_Fazenda);
      this.telefoneControl.setValue(cliente.Telefone);

      this.cardcodeSelecionado = cliente.CardCode;
    }
  }

  selecionarClientePorCardCode(cardCode: string) {
    const cleanedCardCode = cardCode.trim().toLowerCase();

    const cliente = this.clientes.find(c => {
      const clienteCardCode = c.CardCode.trim().toLowerCase();

      return clienteCardCode === cleanedCardCode;
    });

    if (cliente) {
      this.filtroForm.patchValue({
        CardCode: cliente.CardCode,
        Nome_do_PN: cliente.Nome_do_PN,
        CNPJ_CPF: cliente.CNPJ_CPF,
        Telefone: cliente.Telefone,
        U_CADF_Fazenda: cliente.U_CADF_Fazenda,
      });
      this.clienteControl.setValue(cliente.Nome_do_PN);
      this.fazendaControl.setValue(cliente.U_CADF_Fazenda);
      this.localEntregaControl.setValue(cliente.U_CADF_Fazenda);
      this.telefoneControl.setValue(cliente.Telefone);
      this.cpfControl.setValue(cliente.CNPJ_CPF);
      this.cardcodeSelecionado = cliente.CardCode;
    }
  }

  carregarCondicaoPagamento(): void {
    this.loading = true;
    this.openService.condicaoPagamento().subscribe(
      condicoespagamento => {
        if (!condicoespagamento || condicoespagamento.length === 0) {
          console.warn('Nenhuma condição de pagamento carregada!');
          this.loading = false;
          return;
        }

        this.condicoesPagamento = condicoespagamento;
        this.configurarFiltragemCondicoesPagamento();

        // Se houver uma condição já selecionada, carregá-la corretamente
        const PayCondCode = this.data.orcamento?.PaymentGroupCode;
        if (PayCondCode) {
          this.selecionarCondicaoPagamentoPorCodigo(PayCondCode);
        }

        this.loading = false;
      },
      error => {
        this.loading = false;
        console.error('Erro ao carregar condições de pagamento:', error);
      }
    );
  }

  configurarFiltragemCondicoesPagamento(): void {
    this._condicoesPagamentoFiltradas =
      this.condicaoPagamentoFiltroControl.valueChanges.pipe(
        startWith(''),
        map(valor => this._filterCondicoesPagamento(valor || '')) // ✅ Evita valores inválidos
      );
  }

  private _filterCondicoesPagamento(valor: any): any[] {
    if (!this.condicoesPagamento || this.condicoesPagamento.length === 0) {
      return [];
    }

    const filtro = typeof valor === 'string' ? valor.toLowerCase() : ''; // ✅ Garante que sempre será string

    return this.condicoesPagamento.filter(condicaopagamento =>
      condicaopagamento.PymntGroup.toLowerCase().includes(filtro)
    );
  }

  selecionarCondicaoPagamento(event: any) {
    const PayMethodCodeSelecionado = event.option.value;

    const condicaopagamento = this.condicoesPagamento.find(
      c => c.GroupNum === PayMethodCodeSelecionado
    );

    if (condicaopagamento) {
      this.condPagamentoControl.setValue(condicaopagamento.PymntGroup); // Exibe o nome no campo
      this.PayMethCodSelecionado = condicaopagamento.GroupNum; // Salva o código da condição
      this.condicaoPagamentoFiltroControl.setValue(
        condicaopagamento.PymntGroup,
        { emitEvent: false }
      );
    }
  }

  selecionarCondicaoPagamentoPorCodigo(PayMethCod: any) {
    const condicaopagamento = this.condicoesPagamento.find(
      c => c.GroupNum === PayMethCod
    );

    if (condicaopagamento) {
      this.condPagamentoControl.setValue(condicaopagamento.PymntGroup); // Exibe o nome na inicialização
      this.condicaoPagamentoFiltroControl.setValue(
        condicaopagamento.PymntGroup || '',
        { emitEvent: false }
      ); // 🔥 Evita valores inválidos
      this.PayMethCodSelecionado = condicaopagamento.GroupNum;
    }
  }

  abrirDropdownCondicao(): void {
    this.condicaoPagamentoFiltroControl.setValue(
      this.condicaoPagamentoFiltroControl.value || ' '
    ); // 🔥 Isso força a abrir o dropdown
  }

  filtrarCondicoesPagamento(): void {
    const { GroupNum, PymntGroup } = this.filtroForm.value;
    this.condicoesPagamentoFiltradas = this.condicoesPagamento.filter(
      condicaopagamento => {
        return (
          (GroupNum
            ? condicaopagamento.PayMethCod.toString()
                .toLowerCase()
                .includes(GroupNum.toLowerCase())
            : true) &&
          (PymntGroup
            ? (condicaopagamento.PymntGroup || '')
                .toLowerCase()
                .includes(PymntGroup.toLowerCase())
            : true)
        );
      }
    );
  }

  carregarMeioCultura(): void {
    this.loading = true;
    this.openService.carregarCultura().subscribe({
      next: (culturas: any[]) => {
        this.culturas = culturas;
        this.culturasFiltradas = [...this.culturas];
        this.loading = false;
      },
      error: error => {
        this.loading = false;
      },
    });
  }

  filtrarCultura(): void {
    const { Name, Code } = this.filtroForm.value;
    this.culturasFiltradas = this.culturas.filter(cultura => {
      return (
        (Code
          ? cultura.Code.toLowerCase().includes(Code.toLowerCase())
          : true) &&
        (Name
          ? (cultura.Name || '').toLowerCase().includes(Name.toLowerCase())
          : true)
      );
    });
  }

  private _filterCulturas(name: string): any[] {
    const filterValue = name ? name.toLowerCase() : '';
    return this.culturas.filter(
      cultura =>
        cultura.Name && cultura.Name.toLowerCase().includes(filterValue)
    );
  }

  removerItem(index: number): void {
    if (index >= 0 && index < this.dadosTabela.length) {
      this.dadosTabela.splice(index, 1);
      this.atualizarCalculos();
      this.limparCamposNovoItem();
      this.changeDetectorRef.detectChanges();
    }
  }

  adicionarItemFromAPI(data: any): void {
    const nindice = this.indiceSequencial++;
    const nItem = data.ItemCode;
    const descricaoProduto = data.ItemDescription;
    const quantidade = data.Quantity;
    const desconto = data.DiscountPercent;
    const aplicacao = data.U_SOLU_TP_Aplicacao;
    const cod_venda = data.Usage;
    const cultura = data.U_SOLU_Cultura;
    const precoUnitario = `${data.Currency} ${data.UnitPrice}`;
    const precoDesconto = `${data.Currency} ${data.Price}`;
    const total = `${data.Currency} ${data.LineTotal}`;
    const dataEntrega = data.ShipDate;
    const observacoes = data.observacoes;
    const totalvalue = parseFloat(total.replace(/[^\d.-]/g, ''));
    const totalformat = this.currencyPipe.transform(
      totalvalue,
      'BRL',
      'symbol',
      '1.2-2'
    );
    const valorNumerico = parseFloat(precoUnitario.replace(/[^\d.-]/g, ''));
    const value = parseFloat(precoDesconto.replace(/[^\d.-]/g, ''));
    const valueFormat = this.currencyPipe.transform(
      value,
      'BRL',
      'symbol',
      '1.2-2'
    );
    const precoUnitarioFormatado = this.currencyPipe.transform(
      valorNumerico,
      'BRL',
      'symbol',
      '1.2-2'
    );

    const tipoCodVenda = this.getTipoByCodVenda(cod_venda);

    this.codControl.setValue(tipoCodVenda);
    this.codControl.disable();

    const item: Item = {
      nindice: nindice,
      nItem: nItem,
      descricaoProduto: descricaoProduto,
      quantidade: quantidade,
      desconto: desconto,
      aplicacao: aplicacao,
      cultura: cultura,
      precoUnitario: precoUnitarioFormatado,
      precoDesconto: valueFormat,
      total: totalformat,
      data: dataEntrega,
      observacoes: observacoes,
      cod_venda: cod_venda,
      numeroOC: 0,
    };
    this.dadosTabela.push(item);

    console.log('Items data', this.data.orcamento.documentLines);
    console.log('Items tabela', this.dadosTabela);
  }

  getTipoByCodVenda(cod_venda: number): string | undefined {
    const result = this.CodVenda.find(item => item.id === cod_venda);
    return result ? result.Tipo : undefined;
  }

  private _filterPagamentos(nome: string, PayMethodCode: string): any[] {
    const filterValue = nome.toLowerCase();
    return this.formaspagamento.filter(
      option =>
        option.Descript.toLowerCase().includes(filterValue) ||
        option.PayMethCod.toString().includes(filterValue) ||
        option.PayMethCod.toString() === PayMethodCode
    );
  }

  filtrarMetodoPagamento(): void {
    const { FPAGAMENTO } = this.filtroForm.value;
    this.formaspagamentoFiltradas = this.formaspagamento.filter(
      formapagamento => {
        return FPAGAMENTO
          ? formapagamento.FPAGAMENTO.toString()
              .toLowerCase()
              .includes(FPAGAMENTO.toLowerCase())
          : true;
      }
    );
  }
  carregarMetodoPagamento(): void {
    this.loading = true;
    this.openService.obterMetodoPagamento().subscribe(
      formaspagamento => {
        if (!formaspagamento || formaspagamento.length === 0) {
          console.warn('⚠ Nenhum meio de pagamento carregado!');
          this.loading = false;
          return;
        }

        this.formaspagamento = formaspagamento;
        //console.log('✅ Meios de pagamento carregados:', this.formaspagamento);

        this.configurarFiltragemMeioPagamento();

        const PayMethodCode = this.data.orcamento?.U_SOLU_MEIO_PAG;
        if (PayMethodCode) {
          this.selecionarMeioPagamentoPorCodigo(PayMethodCode);
        }

        this.loading = false;
      },
      error => {
        this.loading = false;
        console.error('❌ Erro ao carregar meios de pagamento:', error);
      }
    );
  }

  configurarFiltragemMeioPagamento(): void {
    this._formaspagamentoFiltradas =
      this.meioPagamentoFiltroControl.valueChanges.pipe(
        startWith(''),
        map(valor => this._filterMeioPagamento(valor || ''))
      );

    this._formaspagamentoFiltradas.subscribe(dados => {
      //console.log('📌 Lista filtrada de meios de pagamento:', dados);
    });
  }

  private _filterMeioPagamento(valor: any): any[] {
    if (!this.formaspagamento || this.formaspagamento.length === 0) {
      console.warn('⚠ Nenhum meio de pagamento disponível para filtrar.');
      return [];
    }

    if (typeof valor !== 'string') {
      console.warn('⚠ O valor passado para filtro não é string:', valor);
      return this.formaspagamento; // Se não for string, retorna todos os dados
    }

    const filtro = valor.toLowerCase();
    const filtrados = this.formaspagamento.filter(
      forma =>
        forma.FPAGAMENTO && forma.FPAGAMENTO.toLowerCase().includes(filtro)
    );

    return filtrados;
  }

  selecionarMeioPagamento(event: any) {
    //console.log('✅ Antes da seleção:', this.meioPagamentoFiltroControl.value);

    const meioSelecionado = event.option.value;
    const forma = this.formaspagamento.find(
      f => f.FPAGAMENTO === meioSelecionado
    );

    if (forma) {
      this.pagamentoControl.setValue(forma.FPAGAMENTO); // Exibe o nome no campo
      this.meioPagamentoFiltroControl.setValue(forma.FPAGAMENTO, {
        emitEvent: false,
      }); // Mantém o nome visível
    }

    //console.log('✅ Depois da seleção:', this.meioPagamentoFiltroControl.value);
  }

  selecionarMeioPagamentoPorCodigo(PayMethCod: any) {
    const forma = this.formaspagamento.find(f => f.PayMethCod === PayMethCod);

    if (forma) {
      this.pagamentoControl.setValue(forma.Descript); // Exibe o nome na inicialização
      this.meioPagamentoFiltroControl.setValue(forma.Descript, {
        emitEvent: false,
      }); // Mantém o nome visível
      this.PayMethCodSelecionado = forma.PayMethCod;
    }
  }

  abrirDropdownMeioPagamento(): void {
    //console.log('📢 Tentando abrir a dropdown de meio de pagamento');
    /*console.log(
      '📌 Valor antes de abrir:',
      this.meioPagamentoFiltroControl.value
    );*/

    if (this.formaspagamento.length > 0) {
      if (
        !this.meioPagamentoFiltroControl.value ||
        this.meioPagamentoFiltroControl.value.trim() === ''
      ) {
        this.meioPagamentoFiltroControl.setValue('');
      }

      this.changeDetectorRef.detectChanges();
    } else {
      console.warn('⚠ Nenhum dado disponível para abrir o dropdown.');
    }
  }

  cancel(): void {
    this.indiceItemEditando = null;
    this.limparCamposNovoItem();
    this.dialogRef.close(true);
  }

  salvarAlteracoesOcamento(): void {
    this.dialogRef.close(true);
  }

  /*carregarOrcamentos(): void {
    this.loading = true;
    this.openService.carregarOrcamentos().subscribe(
      orcamentos => {
        this.orcamentos = orcamentos;
        this.orcamentosFiltrados = [...this.orcamentos];
        this.loading = false;
      },
      error => {
        this.loading = false;
      }
    );
  }*/

  separarStringDoNumero(numAtCard: string): void {
    const numeros = numAtCard.replace(/\D/g, '');
  }

  carregarOrcamentos(): void {
    this.loading = true;
    this.openService.obterAtCard().subscribe(
      numbersCard => {
        this.numberCard = numbersCard.map(card => card.DocEntry.toString());
        this.numberCardFiltrado = [...this.numberCard];
        this.ultimoNumAtCard = this.numberCard[this.numberCard.length - 1];

        this.contadorPedido = 0;
        this.loading = false;

        this.separarStringDoNumero(this.ultimoNumAtCard);

        this.contadorPedido = 0;
        this.loading = false;
      },
      error => {
        console.error('Erro ao carregar orçamentos:', error);
        this.loading = false;
      }
    );
    this.openService.carregarOrcamentos().subscribe(
      orcamentos => {
        this.orcamentos = orcamentos;
        this.orcamentosFiltrados = [...this.orcamentos];
        this.loading = false;
      },
      error => {
        this.loading = false;
      }
    );
  }

  filtrarOrcamentos(): void {
    const {
      DocNum,
      DocDate,
      CardName,
      CardCode,
      DocStatus,
      CANCELED,
      DocEntry,
    } = this.filtroForm.value;
    this.orcamentosFiltrados = this.orcamentos.filter(orcamento => {
      return (
        (DocNum
          ? orcamento.DocNum.toLowerCase().includes(DocNum.toLowerCase())
          : true) &&
        (DocDate
          ? orcamento.DocDate.toLowerCase().includes(DocDate.toLowerCase())
          : true) &&
        (CardName
          ? (orcamento.CardName || '')
              .toLowerCase()
              .includes(CardName.toLowerCase())
          : true) &&
        (CardCode
          ? (orcamento.CardCode || '')
              .toLowerCase()
              .includes(CardCode.toLowerCase())
          : true) &&
        (DocStatus
          ? (orcamento.DocStatus || '')
              .toLowerCase()
              .includes(DocStatus.toLowerCase())
          : true) &&
        (CANCELED
          ? (orcamento.CANCELED || '')
              .toLowerCase()
              .includes(CANCELED.toLowerCase())
          : true) &&
        (DocEntry
          ? (orcamento.DocEntry || '')
              .toLowerCase()
              .includes(DocEntry.toLowerCase())
          : true)
      );
    });
  }

  adicionarItem() {
    const nidice = this.indiceSequencial++;
    const nItem = this.nItemControl.value;
    const descricaoProduto = this.descricaoProdutoControl.value;
    const quantidade = this.quantidadeControl.value;
    const numeroOC = this.numeroOCControl.value;
    const desconto = this.descontoControl.value;
    const cod_venda = this.codControl.value;
    const aplicacao = this.aplicacaoControl.value;
    const cultura = this.culturaControl.value;
    const precoUnitario = this.precoUnitarioControl.value;
    const data = this.dataEntregaControl.value;
    const observacoes = this.observacoesControl.value;
    const valorNumerico = parseFloat(precoUnitario.replace(/[^\d.-]/g, ''));
    const precoUnitarioFormatado = this.currencyPipe.transform(
      valorNumerico / 100,
      'BRL',
      'symbol',
      '1.2-2'
    );

    const idCodVenda = this.getIdByTipo(cod_venda);
    const item: Item = {
      nindice: nidice,
      nItem: nItem,
      cod_venda: idCodVenda,
      descricaoProduto: descricaoProduto,
      numeroOC: numeroOC,
      quantidade: quantidade,
      desconto: desconto,
      aplicacao: aplicacao,
      cultura: cultura,
      precoUnitario: precoUnitario,
      data: data,
      observacoes: observacoes,
    };

    this.items.push(item);
    this.dadosTabela.push(item);
    this.changeDetectorRef.detectChanges();
    this.limparCampos();
  }

  limparCampos() {
    this.nItemControl.reset();
    this.descricaoProdutoControl.reset();
    this.quantidadeControl.reset();
    this.descontoControl.reset();
    this.aplicacaoControl.reset();
    this.culturaControl.reset();
    this.precoUnitarioControl.reset();
    this.dataEntregaControl.reset();
    this.observacoesControl.reset();
  }

  selecionarLinha(linha: any) {
    this.linhasSelecionadas = linha;
  }

  editarItem() {
    this.editarAtivo = true;
    this.fieldsDisabledEditActive();
    this.fieldsItemDisabledEditActive();
    this.fieldsPricemDisabledEditActive();
    this.toggleFormControls();
    this.changeDetectorRef.detectChanges();

    if (this.linhasSelecionadas) {
      this.nItemControl.setValue(this.linhasSelecionadas.nItem);
      this.descricaoProdutoControl.setValue(
        this.linhasSelecionadas.descricaoProduto
      );
      this.quantidadeControl.setValue(this.linhasSelecionadas.quantidade);
      this.descontoControl.setValue(this.linhasSelecionadas.desconto);
      this.aplicacaoControl.setValue(this.linhasSelecionadas.aplicacao);
      this.culturaControl.setValue(this.linhasSelecionadas.cultura);
      this.precoUnitarioControl.setValue(this.linhasSelecionadas.precoUnitario);
      this.dataEntregaControl.setValue(this.linhasSelecionadas.data);
      this.observacoesControl.setValue(this.linhasSelecionadas.observacoes);
    }
  }

  salvarAlteracoes() {
    if (this.editarAtivo) {
      if (this.linhasSelecionadas) {
        this.linhasSelecionadas.nItem = this.nItemControl.value;
        this.linhasSelecionadas.descricaoProduto =
          this.descricaoProdutoControl.value;
        this.linhasSelecionadas.quantidade = this.quantidadeControl.value;
        this.linhasSelecionadas.desconto = this.descontoControl.value;
        this.linhasSelecionadas.aplicacao = this.aplicacaoControl.value;
        this.linhasSelecionadas.cultura = this.culturaControl.value;
        this.linhasSelecionadas.precoUnitario = this.precoUnitarioControl.value;

        this.linhasSelecionadas.data = this.dataEntregaControl.value;
        this.linhasSelecionadas.observacoes = this.observacoesControl.value;
        const idVenda = this.getIdByTipo(this.codControl.value);

        this.linhasSelecionadas.cod_venda = idVenda;
        if (this.todosItensComCodVendaDefinido()) {
          this.codControl.disable();
          const tipoVenda = this.getTipoByCodVenda(
            this.linhasSelecionadas.cod_venda
          );
          this.codControl.setValue(tipoVenda);
        }
      }

      this.limparCampos();
      this.editarAtivo = false;
    } else {
      this.adicionarItem();
    }
  }

  verificarCodVendaIgual(): boolean {
    const novoCodVenda = this.data.orcamento.DocEntry;

    for (const item of this.dadosTabela) {
      const idVendaItem = this.getIdByTipo(item.cod_venda);

      if (idVendaItem !== novoCodVenda) {
        return false;
      }
    }

    return true;
  }

  todosItensComCodVendaDefinido(): boolean {
    return this.dadosTabela.every(
      item => item.cod_venda !== undefined && item.cod_venda !== null
    );
  }

  getIdByTipo(tipo: string): number | undefined {
    const result = this.CodVenda.find(item => item.Tipo === tipo);

    return result ? result.id : undefined;
  }

  cancelarOrcamento(selecionado: any): void {
    this.loading = true;
    const DocEntry = this.data.orcamento.DocEntry;

    if (!DocEntry) {
      this.loading = false;
      return;
    }

    this.enviarCancelamento(DocEntry.toString());
  }

  enviarCancelamento(DocEntry: number): void {
    const dialogRef = this.dialog.open(DialogEditarComponent, {
      width: '250px',
      data: {
        message: 'Você tem certeza que deseja cancelar o orçamento?',
        showSuccessMessage: false,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.loading = true;
        this.openService.orcamentoCancelar(DocEntry).subscribe({
          next: () => {
            this.cancel();

            dialogRef.close();
          },
          error: err => {
            this.loading = false;
            const dialogRef = this.dialog.open(DialogErrorCancelarComponent, {
              width: '250px',
            });
          },
        });
      } else {
        this.loading = false;
      }
    });
  }

  editarOrcamento(selecionado: any): void {
    //this.loading = true;

    console.log('Selecionado', selecionado);

    if (typeof selecionado === 'number') {
      selecionado = { DocNum: selecionado.toString() };
      console.log('Selecionado aqui', selecionado);
    }

    if (!selecionado || !selecionado.DocNum) {
      console.log('Não passou', selecionado);
      return;
    }

    this.orcamentoSelecionado = selecionado;

    if (!this.orcamentosFiltrados || this.orcamentosFiltrados.length === 0) {
      console.log('Retornando', this.orcamentosFiltrados);
      return;
    }

    console.log('Iniciando');

    const orcamentoEditar = this.orcamentosFiltrados.find(orcamento => {});

    const docentry = this.data.orcamento.DocEntry;

    console.log('DocEntry', docentry);

    const codCliente = this.codClienteControl.value;
    const CdEntrega = this.CdEntregaControl.value;
    const vendedor = this.SlpCodeSelecionado;
    const observacao = this.observacoescadControl.value;
    const metodopg = this.pagamentoControl.value;
    const condicaoPg = this.PayMethCodSelecionado;
    const freteControl = this.freteCodigo;
    const NumAtCard = this.AtCard.value;
    const documentLines = this.dadosTabela.map(item => {
      const precoUnitarioNumerico = parseFloat(
        item.precoUnitario.replace(/[,.R$]/g, '')
      );
      return {
        ItemCode: item.nItem,
        Quantity: item.quantidade,
        UnitPrice: precoUnitarioNumerico,
        Usage: item.cod_venda,
        U_SOLU_Cultura: item.cultura,
        U_SOLU_TP_Aplicacao: item.aplicacao,
        U_OM_NumberOC: item.numeroOC,
        U_OM_NumberOCLine: null,
        ShipDate: item.data,
        DiscountPercent: parseFloat(item.desconto),
      };
    });

    console.log('Recebeu osa dados');

    const cdEntregaObj = this.cdsEntrega.find(
      c => c.BPLName.toString() === CdEntrega
    );
    const cdEntregaId = cdEntregaObj ? cdEntregaObj.BPLId : null;

    const requestBody = {
      CardCode: codCliente,
      //DocDueDate: '2024-04-19',
      DocObjectCode: 23,
      //BPL_IDAssignedToInvoice: cdEntregaId,
      SalesPersonCode: vendedor,
      NumAtCard: NumAtCard,
      Comments: `${observacao} \n             Alterações Realizadas`,
      U_SOLU_MEIO_PAG: metodopg,
      PaymentGroupCode: condicaoPg,
      DiscountPercent: 0.0,
      Address: 'Entrega',
      //Address2: endereco,
      DocumentLines: documentLines,
      TaxExtension: {
        Incoterms: freteControl,
      },
    };

    console.log('Request Body', requestBody);

    const dialogRef = this.dialog.open(DialogEditarComponent, {
      width: '250px',
      data: {
        message: 'Você tem certeza que deseja editar o orçamento?',
        showSuccessMessage: false,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.openService.editarOrcamento(requestBody, docentry).subscribe(
          response => {
            this.dialogRef.close(true);
            dialogRef.afterClosed().subscribe(() => {
              this.dialogRef.close(true);
            });
          },
          error => {
            const dialogRef = this.dialog.open(DialogEditarComponent, {
              width: '250px',
              data: {
                message: 'Erro ao enviar Orçamento',
                showSuccessMessage: true,
              },
            });

            this.loading = false;
          }
        );
      }
    });
  }

  modificarOrcamento(selecionado: any): void {
    this.loading = true;

    const docentry = this.data.orcamento.DocEntry;

    if (!docentry) {
      this.loading = false;
      return;
    }

    this.orcamentoSelecionado = selecionado;

    if (!this.orcamentosFiltrados || this.orcamentosFiltrados.length === 0) {
      this.loading = false;
      return;
    }

    this.orcamentoSelecionado = this.orcamentosFiltrados.find(orcamento => {
      return orcamento.DocEntry === docentry;
    });

    if (!this.orcamentoSelecionado) {
      this.dialog.open(DialogErrorEditarComponent, {
        width: '250px',
      });

      this.loading = false;
      return;
    }

    const dialogRef = this.dialog.open(ModificarComponent, {
      data: { observacao: this.orcamentoSelecionado.observacao || '' },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loading = false;
      if (result !== undefined) {
        this.orcamentoSelecionado.observacao = result;
        this.continuarAtualizacaoOrcamento(docentry);
      }
    });
  }

  continuarAtualizacaoOrcamento(docEntry: any): void {
    const codCliente = this.codClienteControl.value;
    const CdEntrega = this.CdEntregaControl.value;
    const vendedor = this.SlpCodeSelecionado;
    const observables = this.observacoescadControl.value;
    const observacao = this.orcamentoSelecionado.observacao || '';
    const metodopg = this.pagamentoControl.value;
    const condicaoPg = this.PayMethCodSelecionado;
    const endereco = this.enderecoControl.value;
    const NumAtCard = this.AtCard.value;
    const documentLines = this.dadosTabela.map(item => {
      const precoUnitarioNumerico = parseFloat(
        item.precoUnitario.replace(/[,.R$]/g, '')
      );
      return {
        ItemCode: item.nItem,
        Quantity: item.quantidade,
        UnitPrice: precoUnitarioNumerico,
        Usage: item.cod_venda,
        U_SOLU_Cultura: item.cultura,
        U_SOLU_TP_Aplicacao: item.aplicacao,
        U_OM_NumberOC: item.numeroOC,
        U_OM_NumberOCLine: null,
        ShipDate: item.data,
        DiscountPercent: parseFloat(item.desconto),
      };
    });

    const cdEntregaObj = this.cdsEntrega.find(
      c => c.BPLName.toString() === CdEntrega
    );
    const cdEntregaId = cdEntregaObj ? cdEntregaObj.BPLId : null;

    const requestBody = {
      CardCode: codCliente,
      DocDueDate: '2024-04-19',
      DocObjectCode: 23,
      BPL_IDAssignedToInvoice: cdEntregaId,
      SalesPersonCode: vendedor,
      NumAtCard: NumAtCard,
      Comments: `${observacao} \n                                             Alterações Realizadas`,
      U_SOLU_MEIO_PAG: metodopg,
      PaymentGroupCode: condicaoPg,
      DiscountPercent: 0.0,
      Address: 'Entrega',
      Address2: endereco,
      DocumentLines: documentLines,
      TaxExtension: {
        Incoterms: 1,
      },
    };

    this.loading = true;

    this.openService.editarOrcamento(requestBody, docEntry).subscribe(
      response => {
        const dialogRef = this.dialog.open(DialogPedidoComponent, {
          width: '250px',
          data: {
            message: 'Modificações enviadas com Sucesso!',
            showSuccessMessage: true,
          },
        });

        dialogRef.afterClosed().subscribe(() => {
          this.loading = false;

          this.dialogRef.close(true);
        });
      },
      error => {
        this.loading = false;
      }
    );
  }

  async carregarCdsEntrega(): Promise<void> {
    try {
      this.loading = true;
      this.openService.carregarCdsEntrega().subscribe(cdsEntrega => {
        this.cdsEntrega = cdsEntrega;
        this.cdsEntregaFiltrados = [...this.cdsEntrega];
        this.loading = false;
        const BPLName = this.data.orcamento.BPLName;
        this.selecionarCdEntregaPorNome(BPLName);
      });
    } catch (error) {
      this.loading = false;
    }
  }

  filtrarCdEntrega(): void {
    const { BPLName, BPLId } = this.filtroForm.value;
    this.cdsEntregaFiltrados = this.cdsEntrega.filter(cdEntrega => {
      return (
        (BPLId
          ? cdEntrega.BPLId.toLowerCase().includes(BPLId.toLowerCase())
          : true) &&
        (BPLName
          ? (cdEntrega.BPLName || '')
              .toLowerCase()
              .includes(BPLName.toLowerCase())
          : true)
      );
    });
  }
  /*selecionarCdEntrega(event: any) {
    const selectedBplName = event.option.value;
    this.data.orcamento.BPLName = selectedBplName;
    const cdEntrega = this.cdsEntrega.find(
      cd => cd.BPLName.toLowerCase() === selectedBplName.toLowerCase()
    );
    if (cdEntrega) {
      this.CdEntregaControl.setValue(cdEntrega.BPLName);
      this.BPLIdSelecionado = cdEntrega.BPLId;
    }
  }*/

  selecionarCdEntrega(event: any) {
    this.cdEntrega = event.option.value;
    this.CdEntregaControl.setValue(this.cdEntrega.BPLName);
    this.BPLIdSelecionado = this.cdEntrega.BPLId;
  }

  selecionarCdEntregaPorNome(BPLName: string) {
    const cdEntrega = this.cdsEntrega.find(
      cd => cd.BPLName.toLowerCase() === BPLName.toLowerCase()
    );
    if (cdEntrega) {
      this.CdEntregaControl.setValue(cdEntrega.BPLName);
      this.BPLIdSelecionado = cdEntrega.BPLId;
    }
  }

  enviarPedido(): void {
    this.loading = true;

    const docEntry = this.data.orcamento.DocEntry;

    if (!docEntry) {
      this.loading = false;
      console.error('DocEntry não encontrado!');
      return;
    }

    this.openService
      .envioPedido(docEntry)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: () => {
          const dialogRef = this.dialog.open(DialogPedidoComponent, {
            width: '350px',
            data: {
              message: 'Pedido enviado para assinatura ClickSign com sucesso!',
              showSuccessMessage: true,
            },
          });

          dialogRef.afterClosed().subscribe(() => {
            this.cancel();
          });
        },
        error: err => {
          console.error('Erro ao enviar pedido para ClickSign:', err);

          this.dialog.open(DialogPedidoComponent, {
            width: '350px',
            data: {
              message: 'Erro ao enviar pedido para ClickSign.',
              showSuccessMessage: false,
            },
          });
        },
      });
  }

  private tentarEnvioPedido(requestBody: any, tentativas: number = 0): void {
    console.log(
      `Tentativa de envio do pedido (${tentativas + 1}):`,
      requestBody
    );
    this.openService
      .envioPedido(requestBody)
      .pipe(
        catchError(error => {
          console.error('Erro ao enviar pedido:', error);
          return throwError(error);
        }),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        response => {
          const successDialogRef = this.dialog.open(DialogPedidoComponent, {
            width: '350px',
            data: {
              message: 'Pedido Aprovado com Sucesso!',
              showSuccessMessage: true,
            },
          });
          successDialogRef.afterClosed().subscribe(() => {
            this.cancel();
          });
        },
        error => {
          if (error.code === -1116 && tentativas < 3) {
            console.log(
              'Erro de duplicidade no AtCard, gerando um novo AtCard e tentando novamente...'
            );
            const novoAtCard = this.gerarNovoAtCard(this.AtCard.value);
            requestBody.NumAtCard = novoAtCard;
            console.log('Novo AtCard gerado para reenvio:', novoAtCard);
            this.tentarEnvioPedido(requestBody, tentativas + 1);
          } else if (tentativas >= 2) {
            console.error('Erro ao aprovar o pedido:', error);
            const errorDialogRef = this.dialog.open(DialogPedidoComponent, {
              width: '350px',
              data: {
                message: 'Erro ao Aprovar pedido!',
                showSuccessMessage: true,
              },
            });
            errorDialogRef.afterClosed().subscribe(() => {});
          }
        }
      );
  }

  modificarAtCardEEnviarOrcamento(requestBody: any): void {
    const novoAtCard = this.gerarNovoAtCard(this.AtCard.value);
    requestBody.NumAtCard = novoAtCard;

    const docentry = this.data.orcamento.DocEntry;
    const codCliente = this.codClienteControl.value;
    const dataVenc = this.dataVencimentoControl.value;
    const CdEntrega = this.CdEntregaControl.value;
    const vendedor = this.SlpCodeSelecionado;
    const observacao = this.observacoescadControl.value;
    const metodoPg = this.pagamentoControl.value;
    const condicaoPg = this.PayMethCodSelecionado;
    const endereco = this.enderecoControl.value;
    const entrega = this.fazendaControl.value;

    const documentLines = this.dadosTabela.map(item => {
      const precoUnitarioNumerico = parseFloat(
        item.precoUnitario.replace(/[,.R$]/g, '')
      );
      return {
        ItemCode: item.nItem,
        Quantity: item.quantidade,
        UnitPrice: precoUnitarioNumerico,
        Usage: item.cod_venda,
        U_SOLU_Cultura: item.cultura,
        U_SOLU_TP_Aplicacao: item.aplicacao,
        U_OM_NumberOC: item.numeroOC,
        U_OM_NumberOCLine: null,
        ShipDate: item.data,
        DiscountPercent: parseFloat(item.desconto),
      };
    });

    const cdEntregaObj = this.cdsEntrega.find(
      c => c.BPLName.toString() === CdEntrega
    );
    const cdEntregaId = cdEntregaObj ? cdEntregaObj.BPLId : null;

    const requestBodyOrcamento = {
      CardCode: codCliente,
      DocDueDate: dataVenc,
      DocObjectCode: 23,
      BPL_IDAssignedToInvoice: cdEntregaId,
      SalesPersonCode: vendedor,
      NumAtCard: novoAtCard,
      Comments: observacao,
      U_SOLU_MEIO_PAG: metodoPg,
      PaymentGroupCode: condicaoPg,
      DiscountPercent: 0.0,
      Address: 'Entrega',
      Address2: endereco,
      DocumentLines: documentLines,
      TaxExtension: {
        Incoterms: 1,
      },
    };

    console.log('Request body do orçamento:', requestBodyOrcamento);
    this.openService.editarOrcamento(requestBodyOrcamento, docentry).subscribe(
      response => {
        console.log('Orçamento editado com sucesso:', response);
        this.enviarPedido();
      },
      error => {
        console.error('Erro ao editar orçamento:', error);
      }
    );
  }

  gerarNovoAtCard(atCardExistente: string): string {
    const letras = atCardExistente.substring(0, 3);

    const numeros = atCardExistente.substring(3);

    const novaParteNumerica = this.incrementarUltimoNumero(numeros);

    const novoAtCard = letras + novaParteNumerica;

    console.log('Novo AtCard gerado:', novoAtCard);
    return novoAtCard;
  }

  incrementarUltimoNumero(numeros: string): string {
    const numeroAtual = parseInt(numeros, 10);
    const novoNumero = numeroAtual + 1;
    return novoNumero.toString();
  }

  private async iniciarVerificacao(numAtCard: any): Promise<boolean> {
    const maxTentativas = 2;
    const delay = 5000;
    let tentativas = 0;
    let atCardAtual = numAtCard;

    while (tentativas < maxTentativas) {
      console.log(
        `Tentativa ${
          tentativas + 1
        }: Verificando se o AtCard ${atCardAtual} já existe...`
      );
      try {
        const numbersCard = await this.openService.obterAtCard().toPromise();
        const numberCardFiltrado = numbersCard.map(card =>
          card.NumAtCard.toString()
        );

        if (numberCardFiltrado.includes(atCardAtual) || !atCardAtual) {
          console.log(
            'AtCard já existe ou está vazio. Gerando um novo AtCard...'
          );

          atCardAtual = this.gerarNovoAtCard(atCardAtual);

          if (!atCardAtual) {
            console.log(
              'AtCard gerado ainda está vazio. Tentando novamente...'
            );
            tentativas++;
            continue;
          }

          console.log(
            'Chamando modificarAtCardEEnviarOrcamento com o novo AtCard:',
            atCardAtual
          );
          this.modificarAtCardEEnviarOrcamento({ NumAtCard: atCardAtual });
          return true;
        } else {
          console.log('AtCard é válido:', atCardAtual);
          this.AtCard.setValue(atCardAtual);
          return true;
        }
      } catch (error) {
        console.error('Erro ao obter AtCard:', error);
        return false;
      }
    }

    console.error(
      'Não foi possível gerar um AtCard válido após todas as tentativas'
    );
    return false;
  }

  envioEmail(NumAtCard: any): void {
    const valorAtCard = NumAtCard;
    const partes = valorAtCard.match(/\d+/g);
    const codigoVenda = partes ? partes.join('') : '';

    const docEntry = codigoVenda;

    this.openService.envioEmail(docEntry).subscribe(emailResponse => {});
  }

  toggleFormControls() {
    if (!this.editarAtivo) {
      this.nItemControl.disable();
      this.numeroOCControl.disable();
      this.descricaoProdutoControl.disable();
      this.descontoControl.disable();
      this.quantidadeControl.disable();
      this.precoUnitarioControl.disable();
      this.aplicacaoControl.disable();
      this.culturaControl.disable();
      this.codControl.disable();
      this.dataEntregaControl.disable();
    } else {
      this.nItemControl.enable();
      this.numeroOCControl.enable();
      this.descricaoProdutoControl.enable();
      this.descontoControl.enable();
      this.quantidadeControl.enable();
      this.precoUnitarioControl.enable();
      this.aplicacaoControl.enable();
      this.culturaControl.enable();
      this.codControl.enable();
      this.dataEntregaControl.enable();
    }
  }

  valueChangesFieldsHeaderValidarCabeçalho(): void {
    this.dataVencimentoControl.valueChanges.subscribe(value => {
      this.validarCabecalho();
    });
    this.freteControl.valueChanges.subscribe(value => {
      this.validarCabecalho();
    });
    this.CdEntregaControl.valueChanges.subscribe(value => {
      this.validarCabecalho();
    });
    this.vendedorControl.valueChanges.subscribe(value => {
      this.validarCabecalho();
    });
    this.condicaoPagamentoFiltroControl.valueChanges.subscribe(value => {
      this.validarCabecalho();
    });
    this.meioPagamentoFiltroControl.valueChanges.subscribe(value => {
      this.validarCabecalho();
    });
  }

  validarCabecalho(): void {
    this.camposInvalidosCabecalho = [];
    if (!this.dataVencimentoControl.value)
      this.camposInvalidosCabecalho.push('Data de Entrega');
    if (!this.freteControl.value) this.camposInvalidosCabecalho.push('Frete');
    if (!this.CdEntregaControl.value)
      this.camposInvalidosCabecalho.push('Centro de Distribuição de Entrega');
    if (!this.vendedorControl.value)
      this.camposInvalidosCabecalho.push('Vendedor');
    if (!this.condicaoPagamentoFiltroControl.value)
      this.camposInvalidosCabecalho.push('Condição de Pagamento');
    if (!this.meioPagamentoFiltroControl.value)
      this.camposInvalidosCabecalho.push('Meio de Pagamento');

    this.dadosCabecalhoValidos = this.camposInvalidosCabecalho.length === 0;
  }

  validarPriceValid(): void {
    this.camposInvalidosPrice = [];

    const campos = [
      this.faixaCompraControl.value,
      this.sanatizacaoProdutoControl.value,
    ];

    this.formPriceIniciado = campos.some(valor => !!valor);

    if (!this.faixaCompraControl.value)
      this.camposInvalidosPrice.push('Faixa de Compra');
    if (!this.sanatizacaoProdutoControl.value)
      this.camposInvalidosPrice.push('Sanitização do Produto');

    this.dadosPriceValidos = this.camposInvalidosPrice.length === 0;
  }

  gerarCodPedido(event: any): void {
    const opcaoSelecionada = event.value;

    const partes = opcaoSelecionada.split(' ');

    console.log(partes);

    const codigoVenda = partes[1];
    const numCard = this.numberCardFiltrado.shift();

    console.log(numCard);

    let numCardValido = numCard ? numCard : '';
    if (numCardValido.length > 0) {
      const numCardArray = numCardValido.split('');
      const indexParaModificar = numCardArray.length - 1;

      if (indexParaModificar >= 0) {
        const novoDigito =
          (parseInt(numCardArray[indexParaModificar]) +
            this.contadorPedido +
            2) %
          10;
        numCardArray[indexParaModificar] = novoDigito.toString();
        numCardValido = numCardArray.join('');
      }
    }

    const codigoPedido = `${codigoVenda}${numCardValido}`;
    console.log('codigoPedido', codigoPedido);
    this.codPedidoControl.setValue(codigoPedido);
  }

  validarProductItem(): void {
    this.camposInvalidosProduto = [];

    const campos = [
      this.nItemControl.value,
      this.descricaoProdutoControl.value,
      this.quantidadeAplicacoesControl.value,
      this.doseBiologicaControl.value,
      this.numeroHectaresControl.value,
      this.precoUnitarioControl.value,
      this.quantidadeControl.value,
      this.codControl.value,
      this.aplicacaoControl.value,
      this.culturaControl.value,
      this.dataEntregaControl.value,
    ];

    this.formProdutoIniciado = campos.some(valor => !!valor);

    if (!this.nItemControl.value) this.camposInvalidosProduto.push('Nº Item');
    if (!this.descricaoProdutoControl.value)
      this.camposInvalidosProduto.push('Descrição do Produto');
    if (!this.quantidadeAplicacoesControl.value)
      this.camposInvalidosProduto.push('Quantidade de Aplicações');
    if (!this.doseBiologicaControl.value)
      this.camposInvalidosProduto.push('Dose Biológica (L)');
    if (!this.numeroHectaresControl.value)
      this.camposInvalidosProduto.push('Número de Hectares');
    if (!this.precoUnitarioControl.value)
      this.camposInvalidosProduto.push('Preço Unitário');
    if (!this.quantidadeControl.value)
      this.camposInvalidosProduto.push('Quantidade');
    if (!this.codControl.value)
      this.camposInvalidosProduto.push('Código de Venda');
    if (!this.aplicacaoControl.value)
      this.camposInvalidosProduto.push('Aplicação');
    if (!this.culturaControl.value) this.camposInvalidosProduto.push('Cultura');
    if (!this.dataEntregaControl.value)
      this.camposInvalidosProduto.push('Data de Entrega');

    this.validDataProductItem = this.camposInvalidosProduto.length === 0;
  }

  fieldsDisabledEditActive(): void {
    if (this.editarAtivo) {
      this.dataVencimentoControl.enable();
      this.freteControl.enable();
      this.CdEntregaControl.enable();
      this.vendedorControl.enable();
      this.vendedorFiltroControl.enable();
      this.condicaoPagamentoControl.enable();
      this.condicaoPagamentoFiltroControl.enable();
      //this.formaPagamentoControl.enable();
      this.meioPagamentoFiltroControl.enable();
      this.observacoescadControl.enable();
    } else {
      this.CdEntregaControl.disable();
      this.dataVencimentoControl.disable();
      this.freteControl.disable();
      this.CdEntregaControl.disable();
      //this.vendedorControl.disable();
      this.vendedorFiltroControl.disable();
      this.condicaoPagamentoControl.disable();
      this.condicaoPagamentoFiltroControl.disable();
      this.formaPagamentoControl.disable();
      this.meioPagamentoFiltroControl.disable();
      this.observacoescadControl.disable();
    }
  }

  fieldsItemDisabledEditActive(): void {
    if (this.editarAtivo) {
      this.nItemControl.enable();
      this.descricaoProdutoControl.enable();
      this.quantidadeAplicacoesControl.enable();
      this.doseBiologicaControl.enable();
      this.numeroHectaresControl.enable();
      this.nrKitControl.enable();
      this.custoKgLControl.enable();
      this.custoTotalHaBiologicoControl.enable();
      this.precoUnitarioControl.enable();
      this.quantidadeControl.enable();
      this.descontoControl.enable();
      this.codControl.enable();
      this.aplicacaoControl.enable();
      this.culturaControl.enable();
      this.dataEntregaControl.enable();
      this.numeroOCControl.enable();
      this.newValorTotalControl.enable();
    } else {
      this.nItemControl.disable();
      this.descricaoProdutoControl.disable();
      this.quantidadeAplicacoesControl.disable();
      this.doseBiologicaControl.disable();
      this.numeroHectaresControl.disable();
      this.nrKitControl.disable();
      this.custoKgLControl.disable();
      this.custoTotalHaBiologicoControl.disable();
      this.precoUnitarioControl.disable();
      this.quantidadeControl.disable();
      this.descontoControl.disable();
      this.codControl.disable();
      this.aplicacaoControl.disable();
      this.culturaControl.disable();
      this.dataEntregaControl.disable();
      this.numeroOCControl.disable();
      this.newValorTotalControl.disable();
    }
  }

  fieldsPricemDisabledEditActive(): void {
    if (this.editarAtivo) {
      this.faixaCompraControl.enable();
      this.sanatizacaoProdutoControl.enable();
    } else {
      this.faixaCompraControl.disable();
      this.sanatizacaoProdutoControl.disable();
    }
  }
  carregarFaixasDeCompra(): void {
    this.openService.obterFaixasDeCompra().subscribe(
      faixas => {
        this.faixasDeCompra = faixas;
      },
      error => {
        console.error('Erro ao carregar faixas de compra mínima:', error);
      }
    );
    console.log('faixasDeCompra', this.faixasDeCompra);
  }

  async carregarTiposSanitizacao(): Promise<void> {
    try {
      const inoculums = await this.openService
        .obterListaInoculums()
        .toPromise();

      // Extrair valores únicos de "sanitization_type" e filtrar nulos
      this.tiposSanitizacao = [
        ...new Set(
          inoculums
            .map((item: any) => item.sanitization_type)
            .filter((tipo: string | null) => tipo !== null)
        ),
      ];
    } catch (error) {
      console.error('Erro ao carregar tipos de sanitização:', error);
    }
  }

  calcularCustoTotalHaBiologico(): void {
    const quantidadeAplicacoes =
      Number(this.quantidadeAplicacoesControl.value) || 0;
    const doseBiologica = Number(this.doseBiologicaControl.value) || 0;
    const custoKgL =
      this.converterValorMonetarioParaNumero(this.custoKgLControl.value) || 0;

    if (quantidadeAplicacoes && doseBiologica && custoKgL) {
      const custoTotalHaBiologico =
        quantidadeAplicacoes * doseBiologica * custoKgL;
      this.custoTotalHaBiologicoControl.setValue(
        this.formatarValorMonetario(custoTotalHaBiologico)
      );
    } else {
      this.custoTotalHaBiologicoControl.setValue('R$ 0,00');
    }
  }

  converterValorMonetarioParaNumero(valor: string): number {
    try {
      if (!valor) return 0;
      const valorLimpo = valor.replace(/[^0-9,.-]/g, '');
      const numero = parseFloat(valorLimpo.replace('.', '').replace(',', '.'));
      return isNaN(numero) ? 0 : numero;
    } catch (error) {
      console.error('Erro ao converter valor monetário:', error);
      return 0;
    }
  }

  formatarValorMonetario(valor: any): string {
    if (!valor) return 'R$ 0,00';

    // Remove caracteres não numéricos exceto ponto e vírgula
    let valorLimpo = valor.toString().replace(/[^0-9,.-]/g, '');

    // Converte para número
    let valorNumerico = parseFloat(valorLimpo.replace(',', '.'));

    // Formata o valor como moeda brasileira
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(valorNumerico);
  }

  validarERecalcularValorTotal(): void {
    const quantidade = parseFloat(this.quantidadeControl.value) || 0;
    const precoUnitario = this.converterValorMonetarioParaNumero(
      this.precoUnitarioControl.value
    );

    if (quantidade > 0 && precoUnitario > 0) {
      const valorTotal = quantidade * precoUnitario;
      this.newValorTotalControl.setValue(
        this.formatarValorMonetario(valorTotal)
      );
    } else {
      this.newValorTotalControl.setValue(this.formatarValorMonetario(0));
    }
  }

  atualizarNrKit(): void {
    const itemCode = this.nItemControl.value;
    const quantidadeAplicacoes =
      Number(this.quantidadeAplicacoesControl.value) || 0;
    const doseBiologica = Number(this.doseBiologicaControl.value) || 0;
    const numeroHectares = Number(this.numeroHectaresControl.value) || 0;

    if (itemCode) {
      const quantidadeKits = this.calcularQuantidadeKits(
        itemCode,
        quantidadeAplicacoes,
        doseBiologica,
        numeroHectares
      );
      this.nrKitControl.setValue(quantidadeKits.toString());
      this.quantidadeControl.setValue(Math.ceil(quantidadeKits)); // Atualiza quantidade com valor arredondado
    } else {
      this.nrKitControl.setValue('');
      this.quantidadeControl.setValue(''); // Limpa o campo se o código do item não estiver definido
    }
  }

  calcularQuantidadeKits(
    itemCode: string,
    quantidadeAplicacoes: number,
    doseBiologica: number,
    numeroHectares: number
  ): number {
    // Verificar se os valores são válidos
    quantidadeAplicacoes = Number(quantidadeAplicacoes) || 0;
    doseBiologica = Number(doseBiologica) || 0;
    numeroHectares = Number(numeroHectares) || 0;

    // Buscar a regra específica do item
    const regra = this.regrasDeCalculo.find(r => r.item_code === itemCode);

    if (!regra) {
      console.warn(
        `Regra de cálculo não encontrada para o item ${itemCode}. Usando divisor padrão de 500.`
      );
    }

    const divisor = regra ? parseFloat(regra.divisor) : 500;

    // Realizar o cálculo
    const quantidadeKits =
      (quantidadeAplicacoes * doseBiologica * numeroHectares) / divisor;

    return Math.ceil(quantidadeKits); // Arredondamento para cima
  }

  get selectUtilizado() {
    return false;
  }

  async selecionarProdutoNovoItem(event: any) {
    const itemSelecionado = this.itemsFiltrados.find(
      product =>
        product.ItemCode === event.option.value ||
        product.ItemName === event.option.value
    );

    if (itemSelecionado) {
      // Atualiza os controles de formulário diretamente para um novo item
      this.nItemControl.setValue(itemSelecionado.ItemCode, {
        emitEvent: false,
      });
      this.descricaoProdutoControl.setValue(itemSelecionado.ItemName, {
        emitEvent: false,
      });

      const novoItem = {
        nItem: itemSelecionado.ItemCode,
        descricaoProduto: itemSelecionado.ItemName,
        codVenda: this.codControl.value,
        aplicacao: this.aplicacaoControl.value,
        cultura: this.culturaControl.value,
        dataEntrega: this.dataEntregaControl.value,
        precoUnitario: '',
        custoKgL: '',
      };

      try {
        await this.preencherCustoKgL(itemSelecionado.ItemCode, novoItem);
        this.custoKgLControl.setValue(novoItem.custoKgL, { emitEvent: false });

        await this.preencherPrecoUnitario(itemSelecionado.ItemCode, novoItem);
        this.precoUnitarioControl.setValue(novoItem.precoUnitario);
      } catch (error) {
        console.error(
          'Erro ao preencher os valores ou buscar Inoculum:',
          error
        );
      }
    } else {
      console.warn(
        'Nenhum item encontrado com o valor selecionado:',
        event.option.value
      );
    }

    this.changeDetectorRef.detectChanges();
  }

  async preencherCustoKgL(itemCode: string, item: any) {
    try {
      const faixaSelecionada = this.faixaCompraControl.value;

      if (!faixaSelecionada || !faixaSelecionada.description) {
        console.warn('Nenhuma faixa de compra válida selecionada.');
        item.custoKgL = ''; // Atualiza diretamente no item
        return;
      }

      const faixaSelecionadaValor = faixaSelecionada.description;
      const inoculumsResponse = await this.openService
        .obterPrecosInoculums()
        .toPromise();
      const cultureMediaResponse = await this.openService
        .obterPrecosCultureMedia()
        .toPromise();

      const combinedPrices = [...inoculumsResponse, ...cultureMediaResponse];

      const matchingItem = combinedPrices.find(
        p =>
          p.itemcode === itemCode &&
          p.price_ranges.some(
            range =>
              this.verificarFaixaDeConsumo(
                range.consumption_range,
                faixaSelecionadaValor
              ) && range.price_type === 'Preço/L Kit'
          )
      );

      if (matchingItem) {
        const matchingRange = matchingItem.price_ranges.find(
          range =>
            this.verificarFaixaDeConsumo(
              range.consumption_range,
              faixaSelecionadaValor
            ) && range.price_type === 'Preço/L Kit'
        );

        item.custoKgL = matchingRange ? matchingRange.value : ''; // Atualiza no item
      } else {
        console.warn(
          'Nenhum item correspondente encontrado para ItemCode:',
          itemCode
        );
        item.custoKgL = ''; // Limpa o campo se não houver correspondência
      }
    } catch (error) {
      console.error('Erro ao preencher o Custo Kg/L:', error);
      item.custoKgL = '';
    }
  }

  private verificarFaixaDeConsumo(
    consumptionRange: string,
    faixaSelecionada: string
  ): boolean {
    if (!consumptionRange || !faixaSelecionada) return false;

    // Remove espaços e converte para minúsculas para comparação
    const faixaNormalizada = faixaSelecionada.toLowerCase().replace(/\s+/g, '');
    const rangeNormalizado = consumptionRange.toLowerCase().replace(/\s+/g, '');

    // Verifica se a faixa selecionada é compatível com o range da tabela
    if (
      faixaNormalizada.includes('acima') &&
      rangeNormalizado.includes('acima')
    ) {
      return faixaNormalizada === rangeNormalizado;
    } else if (
      faixaNormalizada.includes('até') &&
      rangeNormalizado.includes('até')
    ) {
      const valorSelecionado =
        parseFloat(faixaNormalizada.match(/(\d+)[mk]/)?.[1] || '0') * 1000;
      const valorRange =
        parseFloat(rangeNormalizado.match(/(\d+)[mk]/)?.[1] || '0') * 1000;
      return valorSelecionado === valorRange;
    }
    return false;
  }

  async preencherPrecoUnitario(itemCode: string, item: any): Promise<void> {
    try {
      const faixaSelecionada = this.faixaCompraControl.value;
      if (!faixaSelecionada || !faixaSelecionada.description) {
        console.warn('Nenhuma faixa de compra válida selecionada.');
        item.precoUnitario = '0';
        return;
      }

      const faixaSelecionadaValor = faixaSelecionada.description;

      const inoculumsResponse = await this.openService
        .obterPrecosInoculums()
        .toPromise();
      const cultureMediaResponse = await this.openService
        .obterPrecosCultureMedia()
        .toPromise();
      const combinedPrices = [...inoculumsResponse, ...cultureMediaResponse];

      const matchingItem = combinedPrices.find(
        p =>
          p.itemcode === itemCode &&
          p.price_ranges.some(
            range =>
              this.verificarFaixaDeConsumo(
                range.consumption_range,
                faixaSelecionadaValor
              ) && range.price_type === 'Preço/Unidade'
          )
      );

      if (matchingItem) {
        const matchingRange = matchingItem.price_ranges.find(
          range =>
            this.verificarFaixaDeConsumo(
              range.consumption_range,
              faixaSelecionadaValor
            ) && range.price_type === 'Preço/Unidade'
        );
        item.precoUnitario = this.formatarValorMonetario(
          matchingRange?.value || 0
        );
      } else {
        item.precoUnitario = this.formatarValorMonetario(0);
      }
    } catch (error) {
      console.error('Erro ao preencher Preço Unitário:', error);
      item.precoUnitario = '0';
    }
  }

  recalcularNovoValorTotal(): void {
    const quantidade = parseFloat(this.quantidadeControl.value) || 0;
    const precoUnitario =
      parseFloat(
        (this.precoUnitarioControl.value || '0')
          .replace(/\./g, '') // Remove separadores de milhar
          .replace(',', '.') // Substitui vírgulas por ponto
      ) || 0;

    if (isNaN(precoUnitario) || precoUnitario <= 0) {
      console.warn(
        `Preço unitário inválido: ${this.precoUnitarioControl.value}`
      );
      this.newValorTotalControl.setValue('0.00');
      return;
    }

    const valorTotal = quantidade * precoUnitario;
    this.newValorTotalControl.setValue(valorTotal.toFixed(2)); // Atualiza o controle com o valor formatado
  }

  recalcularValorTotal(item: any): void {
    const quantidade = parseFloat(item.quantidade) || 0;
    const precoUnitario = this.converterValorMonetarioParaNumero(
      item.precoUnitario
    );
    const valorTotal = quantidade * precoUnitario;

    item.valorTotal = this.formatarValorMonetario(valorTotal);
  }

  // Função para calcular Total (Investimento por Ha)
  calcularInvestimentoPorHa(): void {
    this.totalInvestimentoPorHa = this.dadosTabela.reduce((total, item) => {
      const custoTotalHaBiologico = this.converterValorMonetarioParaNumero(
        item.custoTotalHaBiologico
      );
      return total + custoTotalHaBiologico;
    }, 0);

    // Add value from current form if it exists
    const formCustoTotal = this.converterValorMonetarioParaNumero(
      this.custoTotalHaBiologicoControl.value
    );
    this.totalInvestimentoPorHa += formCustoTotal;
  }

  calcularInvestimentoTotalNoManejo(): void {
    this.investimentoTotalNoManejo = this.dadosTabela.reduce((total, item) => {
      const custoTotalHaBiologico = this.converterValorMonetarioParaNumero(
        item.custoTotalHaBiologico
      );
      const numeroHectares = Number(item.numeroHectares) || 0;
      return total + custoTotalHaBiologico * numeroHectares;
    }, 0);

    // Add value from current form if it exists
    const formCustoTotal = this.converterValorMonetarioParaNumero(
      this.custoTotalHaBiologicoControl.value
    );
    const formNumeroHectares = Number(this.numeroHectaresControl.value) || 0;
    this.investimentoTotalNoManejo += formCustoTotal * formNumeroHectares;
  }

  calcularSomaValorTotalItens(): void {
    const valorTotalItensTabela = this.dadosTabela.reduce((total, item) => {
      return total + this.converterValorMonetarioParaNumero(item.valorTotal);
    }, 0);

    const valorTotalFormulario = this.converterValorMonetarioParaNumero(
      this.newValorTotalControl.value
    );
    this.somaValorTotalItens = valorTotalFormulario + valorTotalItensTabela;
  }

  atualizarCalculos(): void {
    this.calcularInvestimentoPorHa();
    this.calcularInvestimentoTotalNoManejo();
    this.calcularSomaValorTotalItens();
  }

  recalcularNrKit(item: any): void {
    if (
      item.nItem &&
      item.quantidadeAplicacoes &&
      item.doseBiologica &&
      item.numeroHectares
    ) {
      const quantidadeKits = this.calcularQuantidadeKits(
        item.nItem,
        item.quantidadeAplicacoes,
        item.doseBiologica,
        item.numeroHectares
      );

      // Arredonda o valor de Nr Kit para cima e atualiza a quantidade com o mesmo valor
      item.nrKit = quantidadeKits.toString();
      item.quantidade = Math.ceil(quantidadeKits);
    } else {
      item.nrKit = '';
      item.quantidade = ''; // Limpa a quantidade se os valores forem inválidos
    }
  }

  /*adicionarNovoItem(): void {
    const novoItem = {
      nItem: this.nItemControl.value,
      descricaoProduto: this.descricaoProdutoControl.value,
      quantidadeAplicacoes: this.quantidadeAplicacoesControl.value,
      doseBiologica: this.doseBiologicaControl.value,
      numeroHectares: this.numeroHectaresControl.value,
      custoKgL: this.custoKgLControl.value,
      custoTotalHaBiologico: this.custoTotalHaBiologicoControl.value,
      precoUnitario: this.precoUnitarioControl.value,
      quantidade: this.quantidadeControl.value,
      desconto: this.descontoControl.value,
      codVenda: this.codControl.value,
      aplicacao: this.aplicacaoControl.value,
      cultura: this.culturaControl.value,
      dataEntrega: this.dataEntregaControl.value,
      numeroOC: this.numeroOCControl.value,
      nrKit: this.nrKitControl.value,
      valorTotal: this.newValorTotalControl.value,
    };

    if (this.indiceItemEditando !== null) {
      this.dadosTabela.splice(this.indiceItemEditando, 1, novoItem);
      this.indiceItemEditando = null;
    } else {
      this.dadosTabela.unshift(novoItem);
    }

    this.recalcularValorTotal(novoItem);
    this.dadosTabela.push(novoItem);

    this.atualizarCalculos();
    this.recalcularNrKit(novoItem);
    this.limparCamposNovoItem();
    this.scrollToLeft();

    console.log(`Item adicionado:`, {
      nItem: novoItem.nItem,
      descricaoProduto: novoItem.descricaoProduto,
      quantidade: novoItem.quantidade,
      precoUnitario: novoItem.precoUnitario,
      valorTotal: novoItem.valorTotal,
    });

    console.log('Item full adicionado', novoItem);
  }*/

  adicionarNovoItem(): void {
    const novoItem = {
      nItem: this.nItemControl.value,
      descricaoProduto: this.descricaoProdutoControl.value,
      quantidadeAplicacoes: this.quantidadeAplicacoesControl.value,
      doseBiologica: this.doseBiologicaControl.value,
      numeroHectares: this.numeroHectaresControl.value,
      custoKgL: this.custoKgLControl.value,
      custoTotalHaBiologico: this.custoTotalHaBiologicoControl.value,
      precoUnitario: this.precoUnitarioControl.value,
      quantidade: this.quantidadeControl.value,
      desconto: this.descontoControl.value,
      codVenda: this.codControl.value,
      aplicacao: this.aplicacaoControl.value,
      cultura: this.culturaControl.value,
      dataEntrega: this.dataEntregaControl.value,
      numeroOC: this.numeroOCControl.value,
      nrKit: this.nrKitControl.value,
      valorTotal: this.newValorTotalControl.value,
    };

    this.recalcularValorTotal(novoItem); // ✅ faz o cálculo antes de adicionar

    if (this.indiceItemEditando !== null) {
      this.dadosTabela.splice(this.indiceItemEditando, 1, novoItem);
      this.indiceItemEditando = null;

      if (!this.itensEditados.includes(novoItem.nItem)) {
        this.itensEditados.push(novoItem.nItem);
      }

      this.changeDetectorRef.detectChanges();
    } else {
      this.dadosTabela = this.dadosTabela.filter(
        i => i.nItem !== novoItem.nItem
      );
      this.dadosTabela.unshift(novoItem); // adiciona no topo
    }

    this.recalcularNrKit(novoItem);
    this.atualizarCalculos();
    this.limparCamposNovoItem();
    this.scrollToLeft();

    console.log('Item atualizado/adicionado:', novoItem);
  }

  limparCamposNovoItem(): void {
    this.nItemControl.reset();
    this.descricaoProdutoControl.reset();
    this.quantidadeAplicacoesControl.reset();
    this.doseBiologicaControl.reset();
    this.numeroHectaresControl.reset();
    this.nrKitControl.reset();
    this.custoKgLControl.reset();
    this.custoTotalHaBiologicoControl.reset();
    this.precoUnitarioControl.reset();
    this.quantidadeControl.reset();
    this.descontoControl.reset();
    this.codControl.reset();
    this.aplicacaoControl.reset();
    this.culturaControl.reset();
    this.dataEntregaControl.reset();
    this.numeroOCControl.reset();
    this.newValorTotalControl.reset();
  }

  scrollToLeft() {
    this.itensScroll.nativeElement.scrollTo({ left: 0, behavior: 'smooth' });
  }

  get itensSemKit(): any[] {
    return (
      this.dadosTabela?.filter(i => i.tipoGrupo !== 'kitComplementar') || []
    );
  }

  async selecionarItensPadrao(
    itemCodePrincipal: string,
    quantidadeItemPrincipal: number,
    codVenda: string,
    aplicacao: string,
    cultura: string,
    dataEntrega: Date,
    sanitizationType: string
  ): Promise<void> {
    try {
      // Remove itens não correspondentes ao novo sanitizationType
      this.dadosTabela = this.dadosTabela.filter(
        item =>
          item.sanitization_type === sanitizationType ||
          item.adicionadoPor !== 'selecionarItensPadrao'
      );

      // Obtém os itens padrão da API
      const inoculums = await this.openService
        .obterListaInoculums()
        .toPromise();
      let itensPadrao = inoculums.filter(
        item => item.sanitization_type === sanitizationType
      );

      if (sanitizationType === 'QUALYFARM') {
        // Inclui apenas itens com conditions === null ou ""
        itensPadrao = itensPadrao.filter(
          item => item.conditions === null || item.conditions === ''
        );

        // Itera pelos itens padrão para aplicar a lógica de substituição do rule_qtd_mult
        itensPadrao = itensPadrao.map(item => {
          const itemReferente = inoculums.find(
            refItem =>
              refItem.itemcode === itemCodePrincipal &&
              refItem.conditions === item.itemcode
          );

          if (itemReferente) {
            return { ...item, rule_qtd_mult: itemReferente.rule_qtd_mult };
          }

          return item;
        });
      }

      // Soma das quantidades dos itens não complementares
      const somaNaoKitComplementar =
        this.dadosTabela
          .filter(item => item.tipoGrupo !== 'kitComplementar')
          .reduce(
            (soma, item) => soma + (parseFloat(item.quantidade) || 0),
            0
          ) + (parseFloat(this.quantidadeControl.value) || 0);

      // Processar cada item padrão
      for (const item of itensPadrao) {
        const ruleQtdMult = parseFloat(item.rule_qtd_mult) || 1;
        const divisor = parseFloat(item.divisor) || 1;

        // Calcular a quantidade com a nova fórmula
        let quantidadeCalculada = Math.ceil(
          (somaNaoKitComplementar * ruleQtdMult) / divisor
        );

        const itemExistente = this.dadosTabela.find(
          i =>
            i.nItem === item.itemcode &&
            i.adicionadoPor === 'selecionarItensPadrao'
        );

        if (itemExistente) {
          // Atualizar a quantidade e recalcular o valor total
          itemExistente.quantidade = quantidadeCalculada;
          this.recalcularValorTotal(itemExistente);
        } else {
          // Adicionar novo item com quantidade calculada
          const novoItem = {
            nItem: item.itemcode,
            descricaoProduto: item.itemname,
            precoUnitario: '',
            custoKgL: '',
            quantidade: quantidadeCalculada,
            desconto: 0,
            valorTotal: 0,
            tipoGrupo: 'kitComplementar',
            adicionadoPor: 'selecionarItensPadrao',
            codVenda: codVenda,
            aplicacao: aplicacao,
            cultura: cultura,
            dataEntrega: dataEntrega,
            sanitization_type: sanitizationType,
          };

          try {
            await this.preencherCustoKgL(item.itemcode, novoItem);
            await this.preencherPrecoUnitario(item.itemcode, novoItem);
            this.recalcularValorTotal(novoItem);
            this.dadosTabela.push(novoItem);

            this.atualizarKitsComplementares(
              itemCodePrincipal,
              quantidadeItemPrincipal,
              novoItem
            );
          } catch (error) {
            console.error(
              `Erro ao adicionar item padrão ${item.itemcode}:`,
              error
            );
          }
        }
      }

      this.atualizarCalculos();
      this.changeDetectorRef.detectChanges();
    } catch (error) {
      console.error('Erro ao carregar itens padrão:', error);
    }
  }

  async atualizarKitsComplementares(
    itemCodePrincipal: string,
    quantidadeItemPrincipal: number,
    itemPrincipal: any
  ) {
    try {
      // Carregar as informações de inoculums e meios de cultura
      const inoculums = await this.openService
        .obterListaInoculums()
        .toPromise();
      const meiosDeCultura = await this.openService
        .obterMeiosDeCultura()
        .toPromise();

      const inoculumRelacionado = inoculums.find(
        inoculum => inoculum.itemcode === itemCodePrincipal
      );

      if (inoculumRelacionado && inoculumRelacionado.cultureMedia) {
        const itemCultureMedia = this.itemsFiltrados.find(
          product =>
            product.ItemCode === inoculumRelacionado.cultureMedia.itemcode
        );

        if (itemCultureMedia) {
          const meioDeCultura = meiosDeCultura.find(
            meio => meio.itemcode === itemCultureMedia.ItemCode
          );

          if (meioDeCultura) {
            // Calcula a nova quantidade para os kits complementares
            const quantidadeKitsComplementares =
              quantidadeItemPrincipal * (meioDeCultura.rule_qtd_mult || 1);

            // Buscar preço unitário do kit complementar
            const faixaSelecionada =
              this.faixaCompraControl.value?.description || '';
            const faixaSelecionadaValor = faixaSelecionada.match(/\d+k/)?.[0];
            const combinedPrices = [
              ...(await this.openService.obterPrecosInoculums().toPromise()),
              ...(await this.openService.obterPrecosCultureMedia().toPromise()),
            ];

            const matchingPrice = combinedPrices.find(
              p =>
                p.itemcode === itemCultureMedia.ItemCode &&
                p.price_ranges.some(
                  range =>
                    range.consumption_range.includes(faixaSelecionadaValor) &&
                    range.price_type === 'Preço/Unidade Meio de Cultura'
                )
            );

            const precoUnitario =
              matchingPrice?.price_ranges.find(
                range =>
                  range.consumption_range.includes(faixaSelecionadaValor) &&
                  range.price_type === 'Preço/Unidade Meio de Cultura'
              )?.value || 0;

            // Atualiza ou adiciona o kit complementar na tabela de dados
            const kitExistente = this.dadosTabela.find(
              item =>
                item.nItem === itemCultureMedia.ItemCode &&
                item.itemPrincipal === itemCodePrincipal
            );

            if (kitExistente) {
              kitExistente.quantidade = quantidadeKitsComplementares;
              kitExistente.precoUnitario = precoUnitario;

              // Propagar valores do item principal
              kitExistente.codVenda = itemPrincipal.codVenda;
              kitExistente.aplicacao = itemPrincipal.aplicacao;
              kitExistente.cultura = itemPrincipal.cultura;
              kitExistente.dataEntrega = itemPrincipal.dataEntrega;

              this.recalcularValorTotal(kitExistente); // Recalcula o valor total do kit existente
            } else {
              const novoKit = {
                nItem: itemCultureMedia.ItemCode,
                descricaoProduto: itemCultureMedia.ItemName,
                quantidade: quantidadeKitsComplementares,
                precoUnitario: precoUnitario,
                valorTotal: 0, // Será recalculado abaixo
                tipoGrupo: 'kitComplementar',
                itemPrincipal: itemCodePrincipal,
                // Propagar valores do item principal
                codVenda: itemPrincipal.codVenda,
                aplicacao: itemPrincipal.aplicacao,
                cultura: itemPrincipal.cultura,
                dataEntrega: itemPrincipal.dataEntrega,
              };

              this.recalcularValorTotal(novoKit); // Recalcula o valor total do novo kit
              this.dadosTabela.push(novoKit);
            }
          } else {
            console.warn(
              `Meio de Cultura não encontrado para ItemCode: ${itemCultureMedia.ItemCode}`
            );
          }
        } else {
          console.warn(
            'Nenhum CultureMedia associado encontrado para o ItemCode:',
            inoculumRelacionado.cultureMedia.itemcode
          );
        }
      }
    } catch (error) {
      console.error('Erro ao atualizar kits complementares:', error);
    }
  }

  getDataEntrega(item: any): string {
    const data = item.dataEntrega || item.data;
    if (!data) return 'Não Informado';

    const parsed = new Date(data);
    return isNaN(parsed.getTime())
      ? 'Data inválida'
      : parsed.toLocaleDateString('pt-BR');
  }

  getCodVenda(item: any): string {
    return item.codVenda || item.cod_venda || 'Não Informado';
  }

  /*getNumeroOCSeguro(valor: any): string {
    return typeof valor === 'string' && valor.trim()
      ? valor.trim()
      : typeof valor === 'number'
      ? valor.toString()
      : 'Não Informado';
  }*/

  getNumeroOCSeguro(valor: any): string {
    if (
      valor === null ||
      valor === undefined ||
      valor === 0 ||
      valor === '0' ||
      (typeof valor === 'string' && valor.trim() === '')
    ) {
      return 'Não Informado';
    }

    return valor.toString().trim();
  }

  isQuantidadeAplicacoes(item: any): boolean {
    return item?.quantidadeAplicacoes != null;
  }
  isDoseBiologica(item: any): boolean {
    return item?.doseBiologica != null;
  }
  isNumeroHectares(item: any): boolean {
    return item?.numeroHectares != null;
  }
  isNrKit(item: any): boolean {
    return item?.nrKit != null;
  }

  getValorTotalSeguro(item: any): string {
    const valor = item?.valorTotal ?? item?.total;
    return valor !== undefined && valor !== null ? `${valor}` : 'Não informado';
  }

  carregarItemParaEdicao(item: any, index: number): void {
    this.indiceItemEditando = index;

    this.changeDetectorRef.detectChanges();

    console.log('Carregamento de item', item);

    this.nItemControl.setValue(item.nItem);
    this.descricaoProdutoControl.setValue(item.descricaoProduto);
    this.quantidadeAplicacoesControl.setValue(item.quantidadeAplicacoes);
    this.doseBiologicaControl.setValue(item.doseBiologica);
    this.numeroHectaresControl.setValue(item.numeroHectares);
    this.nrKitControl.setValue(item.nrKit);
    this.custoKgLControl.setValue(item.custoKgL);
    this.custoTotalHaBiologicoControl.setValue(item.custoTotalHaBiologico);
    this.precoUnitarioControl.setValue(item.precoUnitario);
    this.quantidadeControl.setValue(item.quantidade);
    this.descontoControl.setValue(item.desconto);
    this.aplicacaoControl.setValue(item.aplicacao);
    this.culturaControl.setValue(item.cultura);
    this.dataEntregaControl.setValue(item.data ? new Date(item.data) : null);
    this.numeroOCControl.setValue(item.numeroOC);
    this.newValorTotalControl.setValue(item.valorTotal ?? item.total);

    setTimeout(() => {
      this.itensScroll?.nativeElement?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }, 0);
  }

  itemFoiEditado(item: any): boolean {
    return this.itensEditados.includes(item.nItem);
  }
}
