import { Component } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { AuthService } from 'app/core/auth/auth.service'; // Importe o AuthService
import { APP_CONSTANTS_SESSION } from 'app/core/constants/app.constants';

@Component({
  selector: 'app-session-alert',
  templateUrl: './session-alert.component.html',
  styleUrls: ['./session-alert.component.scss'],
})
export class SessionAlertComponent {
  private sessionDuration = APP_CONSTANTS_SESSION.SESSION_DURATION / 1000;

  constructor(
    private _snackBarRef: MatSnackBarRef<SessionAlertComponent>,
    private _authService: AuthService
  ) {}

  /*renewSession(): void {
    console.log('🔄 Sessão renovada pelo usuário.');
    this._authService.renewSession();
    this._snackBarRef.dismiss();
  }*/

  renewSession(): void {
    if (!this._authService.canRenewSession()) {
      console.warn(
        '⛔ [RENEW SESSION] Token expirando, Desconectando usuário!'
      );
      this.logOut();
      return;
    }

    this._authService.renewSession();
    this._authService.dismissSessionAlert();
    //console.log('✅ [RENEW SESSION] Sessão renovada com sucesso!');
  }

  logOut(): void {
    this._authService.dismissSessionAlert();
    this._authService.signOut().subscribe(() => {
      console.log('✅ [LOGOUT] Usuário foi desconectado!');
    });
  }
}
