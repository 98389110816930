import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { OrdersOpenService } from '../orders-open.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Item, Cadastro } from 'app/modules/admin/sales/Types';
import { map, startWith } from 'rxjs/operators';
import { UserService, User } from 'app/core/services/auth/user.service';
import { Dialog } from '@angular/cdk/dialog';
import { DialogPedidoComponent } from '../dialog-pedido/dialog-pedido.component';
import { DialogCancelarComponent } from '../dialog-cancelar/dialog-cancelar.component';
import { switchMap, catchError, finalize } from 'rxjs/operators';
import { DialogErrorEditarComponent } from '../dialog-error-editar/dialog-error-editar.component';
import { DialogEditarComponent } from 'app/modules/admin/sales/open-sales/open-sales-dialog/dialog-editar/dialog-editar.component';
import { ModificarService } from '../modificar/modificar.service';
import { ModificarComponent } from '../modificar/modificar.component';
import { CurrencyPipe } from '@angular/common';
@Component({
  selector: 'app-order-open-dialog',
  templateUrl: './order-open-dialog.component.html',
  styleUrls: ['./order-open-dialog.component.scss'],
})
export class OrderOpenDialogComponent implements OnInit {
  editarOrcamento: boolean = false;
  loading: boolean = false;
  editarAtivo: boolean = false;

  codClienteControl = new FormControl();
  cpfControl = new FormControl();
  telefoneControl = new FormControl();
  fazendaControl = new FormControl();
  enderecoControl = new FormControl();
  dataVencimentoControl = new FormControl();
  codPedidoControl = new FormControl();
  codControl = new FormControl();
  clienteControl = new FormControl();
  localEntregaControl = new FormControl();
  freteControl = new FormControl('ENTREGA');
  observacoescadControl = new FormControl();
  textoControl = new FormControl();
  pagamentoControl = new FormControl();
  inscricaoControl = new FormControl();
  inscricaoMunicipalControl = new FormControl();
  itemControl = new FormControl();

  produtoControl = new FormControl();

  vendedorControl = new FormControl();
  emailControl = new FormControl();
  CdEntregaControl = new FormControl();
  formaPagamentoControl = new FormControl();
  observacoesControl = new FormControl();
  numeroOCControl = new FormControl();
  quantidadeControl = new FormControl();
  descontoControl = new FormControl();
  aplicacaoControl = new FormControl();
  nItemControl = new FormControl();
  descricaoProdutoControl = new FormControl();
  precoUnitarioControl = new FormControl();
  dataEntregaControl = new FormControl();
  culturaControl = new FormControl();
  CdEntregaId = new FormControl();
  AtCard = new FormControl();

  cardcodeSelecionado: number | null = null;
  vendedoresFiltrados: any[] = [];
  vendedores: any[] = [];
  vendedor: any = null;
  filteredVendedores: any;
  filteredItems: any;
  culturasFiltradas: any[] = [];
  culturas: any[] = [];
  orcamentosFiltrados: any[] = [];
  orcamentoSelecionado: any;
  linhasSelecionadas: any;
  editMode: boolean = false;
  dadosTabela: any[] = [];
  private indiceSequencial: number;
  items: any[] = [];
  itemsFiltrados: any[] = [];
  filtroForm: FormGroup;
  clientes: any[] = [];
  clientesFiltrados: any[] = [];
  cdsEntrega: any[] = [];
  cdsEntregaFiltrados: any[] = [];
  BPLIdSelecionado: string;
  SlpCodeSelecionado: number | null = null;
  filteredformaspagamento: any;
  formaspagamentoFiltradas: any[] = [];
  formaspagamento: any[] = [];
  PayMethCodSelecionado: number | null = null;
  filteredClientes: any;
  currentUser: User;
  GroupNumSelecionado: string;
  modificar: string = '';
  selecionado: any;
  condicoesPagamento: any[] = [];
  condicoesPagamentoFiltradas: any[] = [];
  filteredCondicaoPagamento: any;
  condicao: any = null;
  constructor(
    private currencyPipe: CurrencyPipe,
    public matDialogRef: MatDialogRef<any>,
    public router: Router,
    private dialog: MatDialog,
    private openService: OrdersOpenService,
    private modificarService: ModificarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private userAuth: UserService
  ) {
    let items: Item[] = [];

    let cabecalho: Cadastro[] = [];
    this.indiceSequencial = 0;
    this.filtroForm = this.formBuilder.group({
      CardCode: [''],
      Nome_do_PN: [''],
      CNPJ_CPF: [''],
      Telefone: [''],
      U_CADF_Fazenda: [''],
    });
    this.carregarCdEntregas();
  }

  ngOnInit() {
    this.carregarCondicaoPg();
    this.codControl = new FormControl(
      { value: '', disabled: this.selectUtilizado !== false },
      Validators.required
    );
    this.carregarItems();
    this.carregarMeioCultura();
    this.loadOrcamentos();
    this.carregarVendedores();
    this.carregarClientes();
    this.carregarMetodoPagamento();
    this.modificarService.currentObservacao.subscribe(modificar => {
      this.modificar = modificar;
    });

    this.userAuth.get().subscribe(user => {
      this.currentUser = user;

      this.loading = false;
      this.changeDetectorRef.detectChanges();
    });

    this.filteredItems = this.itemControl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.ItemName)),
      map(nome => (nome ? this._filterItems(nome) : this.items.slice()))
    );
    this.filteredClientes = this.clienteControl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.Nome_do_PN)),
      map(nome => {
        const cardCode = this.data.orcamento.CardCode || '';
        return this._filterClientes(nome, cardCode);
      })
    );
    this.filteredformaspagamento = this.pagamentoControl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.Descript)),
      map(nome => {
        const PayMethodCode = this.data.orcamento.PaymentMethod || '';
        return this._filterPagamentos(nome, PayMethodCode);
      })
    );
    this.precoUnitarioControl.valueChanges.subscribe(value => {
      if (value !== null && value !== undefined) {
        const formattedValue = this.currencyPipe.transform(
          value,
          'BRL',
          'symbol',
          '1.0-2'
        );
        this.precoUnitarioControl.setValue(formattedValue, {
          emitEvent: false,
        });
      }
    });

    this.pagamentoControl.valueChanges
      .pipe(
        startWith(''),
        map(value => (typeof value === 'string' ? value : value.Descript)),
        map(nome => {
          const PayMethodCode = this.data.orcamento.PaymentMethod || '';
          return this._filterPagamentos(nome, PayMethodCode);
        })
      )
      .subscribe((formaspagamentoFiltradas: any[]) => {
        this.formaspagamentoFiltradas = formaspagamentoFiltradas;
      });
    this.filteredVendedores = this.vendedorControl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.SlpName)),
      map(nome => {
        const salesPersonCode = this.data.orcamento.SalesPersonCode || '';
        return this._filterVendedores(nome, salesPersonCode);
      })
    );
    this.vendedorControl.valueChanges
      .pipe(
        startWith(''),
        map(value => (typeof value === 'string' ? value : value.SlpName)),
        map(nome => {
          const salesPersonCode = this.data.orcamento.SalesPersonCode || '';
          return this._filterVendedores(nome, salesPersonCode);
        })
      )
      .subscribe((vendedoresFiltrados: any[]) => {
        this.filteredVendedores = vendedoresFiltrados;
      });

    this.clienteControl.valueChanges
      .pipe(
        startWith(''),
        map(value => (typeof value === 'string' ? value : value.Nome_do_PN)),
        map(nome => {
          const cardCode = this.data.orcamento.CardCode || '';
          return this._filterClientes(nome, cardCode);
        })
      )
      .subscribe((clientesFiltrados: any[]) => {
        this.clientesFiltrados = clientesFiltrados;
      });

    this.nItemControl.valueChanges.subscribe(value => {
      const produtoSelecionado = this.itemsFiltrados.find(
        produto => produto.nItem === value
      );
      if (produtoSelecionado) {
        this.descricaoProdutoControl.setValue(
          produtoSelecionado.DescricaoDoProduto
        );
      }
    });

    this.descricaoProdutoControl.valueChanges.subscribe(value => {
      const produtoSelecionado = this.itemsFiltrados.find(
        produto => produto.DescricaoDoProduto === value
      );
      if (produtoSelecionado) {
        this.nItemControl.setValue(produtoSelecionado.ItemCode);
      }
    });

    this.culturaControl.valueChanges.subscribe(value => {
      const culturaSelecionada = this.culturasFiltradas.find(
        produto => produto.cultura === value
      );
      if (culturaSelecionada) {
        this.culturaControl.setValue(culturaSelecionada.Name);
      }
    });

    if (this.data) {
      this.codClienteControl.setValue(this.data.orcamento.CardCode);
      this.clienteControl.setValue(this.data.orcamento.CardName);
      this.cpfControl.setValue(this.data.orcamento.TaxExtension.CPF);
      this.observacoescadControl.setValue(this.data.orcamento.Comments);
      this.dataVencimentoControl.setValue(this.data.orcamento.DocDueDate);
      this.vendedorControl.setValue(this.data.orcamento.SalesPersonCode);
      this.formaPagamentoControl.setValue(this.data.orcamento.PaymentMethod);
      this.pagamentoControl.setValue(this.data.orcamento.PaymentGroupCode);
      this.codPedidoControl.setValue(this.data.orcamento.DocEntry);
      this.CdEntregaControl.setValue(this.data.orcamento.BPLName);
      this.enderecoControl.setValue(this.data.orcamento.Address);
      this.inscricaoControl.setValue(
        this.data.orcamento.TaxExtension.inscricao_estadual
      );
      this.AtCard.setValue(this.data.orcamento.NumAtCard);

      if (
        this.data.orcamento.DocumentLines &&
        Array.isArray(this.data.orcamento.DocumentLines)
      ) {
        this.data.orcamento.DocumentLines.forEach((line: any) => {
          this.adicionarItemFromAPI(line);
        });
      } else {
        console.error('Dados de orçamento não encontrados.');
      }
      const salesPersonCode = this.data.orcamento.SalesPersonCode;
      const vendedor = this.vendedores.find(v => v.SlpCode === salesPersonCode);
      if (vendedor) {
        this.vendedorControl.setValue(vendedor ? vendedor.SlpName : '');
      }
      const cardCode = this.data.orcamento.CardCode;
      const cliente = this.clientes.find(c => c.CardCode === cardCode);
      if (cliente) {
        this.clienteControl.setValue(cliente ? cliente.Nome_do_PN : '');
        this.telefoneControl.setValue(cliente ? cliente.Telefone : '');
        this.fazendaControl.setValue(cliente ? cliente.U_CADF_Fazenda : '');
        this.localEntregaControl.setValue(
          cliente ? cliente.U_CADF_Fazenda : ''
        );
      }
      const PayMethCod = this.data.orcamento.PaymentMethod;
      const formapagamento = this.formaspagamento.find(
        f => f.PayMethCod === PayMethCod
      );
      if (formapagamento) {
        this.pagamentoControl.setValue(
          formapagamento ? formapagamento.Descript : ''
        );
      }

      const CdEntregaName = this.data.orcamento.BPLName;
      const cdEntrega = this.cdsEntrega.find(c => c.BPLName === CdEntregaName);

      if (cdEntrega) {
        this.BPLIdSelecionado = cdEntrega.BPLId;
      } else {
        console.error('CdEntrega não encontrado para o nome:', CdEntregaName);
      }
    }
  }

  private _filterItems(nome: string): any[] {
    const filterValue = nome.toLowerCase();
    return this.items.filter(
      item =>
        item.ItemCode.toLowerCase().includes(filterValue) ||
        (item.DescricaoDoProduto || '').toLowerCase().includes(filterValue)
    );
  }
  alterarOrcamento() {
    this.editarOrcamento = true;
  }
  toggleEditMode() {
    this.editMode = !this.editMode;
  }
  cancel(): void {
    this.matDialogRef.close(true);
    this.loading = false;
  }

  async carregarClientes(): Promise<void> {
    this.loading = true;
    try {
      const clientes = await this.openService.obterClientes().toPromise();
      this.clientes = clientes;
      this.clientesFiltrados = [...this.clientes];
      this.loading = false;

      const cardCode = this.data.orcamento.CardCode;

      if (cardCode) {
        this.selecionarClientePorCardCode(cardCode);
      } else {
        console.warn('CardCode do pedido está vazio ou indefinido:', cardCode);
      }
    } catch (error) {
      console.error('Erro ao carregar clientes:', error);
      this.loading = false;
    }
  }

  filtrarClientes(): void {
    const { U_CADF_Fazenda, Nome_do_PN, CNPJ_CPF, Telefone, CardCode, Email } =
      this.filtroForm.value;
    this.clientesFiltrados = this.clientes.filter(cliente => {
      return (
        (U_CADF_Fazenda
          ? cliente.U_CADF_Fazenda.toLowerCase().includes(
              U_CADF_Fazenda.toLowerCase()
            )
          : true) &&
        (Nome_do_PN
          ? (cliente.Nome_do_PN || '')
              .toLowerCase()
              .includes(Nome_do_PN.toLowerCase())
          : true) &&
        (CardCode
          ? (cliente.CardCode || '')
              .toLowerCase()
              .includes(CardCode.toLowerCase())
          : true) &&
        (Email
          ? (cliente.Email || '').toLowerCase().includes(Email.toLowerCase())
          : true) &&
        (CNPJ_CPF
          ? (cliente.CNPJ_CPF || '')
              .toLowerCase()
              .includes(CNPJ_CPF.toLowerCase())
          : true) &&
        (Telefone
          ? (cliente.Telefone || '')
              .toLowerCase()
              .includes(Telefone.toLowerCase())
          : true)
      );
    });
  }

  selecionarCliente(event: any) {
    const cardcodeSelecionado = event.option.value;
    this.data.orcamento.CardCode = cardcodeSelecionado;

    const cliente = this.clientes.find(c => c.CardCode === cardcodeSelecionado);

    if (cliente) {
      this.clienteControl.setValue(cliente.Nome_do_PN);

      this.cardcodeSelecionado = cliente.CardCode;
    } else {
      console.error(
        'Cliente não encontrado para o CardCode:',
        cardcodeSelecionado
      );
    }
  }

  selecionarClientePorCardCode(cardCode: string) {
    const cleanedCardCode = cardCode.trim().toLowerCase();

    const cliente = this.clientes.find(c => {
      const clienteCardCode = c.CardCode.trim().toLowerCase();
      return clienteCardCode === cleanedCardCode;
    });

    if (cliente) {
      if (this.filtroForm) {
        this.filtroForm.patchValue({
          CardCode: cliente.CardCode,
          Nome_do_PN: cliente.Nome_do_PN,
          CNPJ_CPF: cliente.CNPJ_CPF,
          Telefone: cliente.Telefone,
          U_CADF_Fazenda: cliente.U_CADF_Fazenda,
        });
      } else {
        console.error('filtroForm não está definido.');

        return;
      }
      this.clienteControl.setValue(cliente.Nome_do_PN);
      this.fazendaControl.setValue(cliente.U_CADF_Fazenda);
      this.localEntregaControl.setValue(cliente.U_CADF_Fazenda);
      this.telefoneControl.setValue(cliente.Telefone);
      this.cpfControl.setValue(cliente.CNPJ_CPF);
      this.emailControl.setValue(cliente.Email);
      this.cardcodeSelecionado = cliente.CardCode;
    } else {
      console.error('Cliente não encontrado para o CardCode:', cardCode);
    }
  }

  private _filterClientes(nome: string, cardCode: string): any[] {
    const filterValue = nome.toLowerCase();
    return this.clientes.filter(
      cliente =>
        (cardCode
          ? cliente.CardCode.toLowerCase().includes(cardCode.toLowerCase())
          : true) && cliente.Nome_do_PN.toLowerCase().includes(filterValue)
    );
  }

  private _filterPagamentos(nome: string, PayMethodCode: string): any[] {
    const filterValue = nome.toLowerCase();
    return this.formaspagamento.filter(
      option =>
        option.Descript.toLowerCase().includes(filterValue) ||
        option.PayMethCod.toString().includes(filterValue) ||
        option.PayMethCod.toString() === PayMethodCode
    );
  }
  carregarMetodoPagamento(): void {
    this.loading = true;
    this.openService.obterMetodoPagamento().subscribe(
      formaspagamento => {
        this.formaspagamento = formaspagamento;
        this.formaspagamentoFiltradas = [...this.formaspagamento];
        this.loading = false;
        const PayMethodCode = this.data.orcamento.PaymentMethod;
        this.selecionarFormaPagamentoPorCodigo(PayMethodCode);
      },
      error => {
        console.error('Erro ao carregar formaspagamento:', error);
        this.loading = false;
      }
    );
  }

  filtrarMetodoPagamento(): void {
    const { PayMethCod, Descript } = this.filtroForm.value;
    this.formaspagamentoFiltradas = this.formaspagamento.filter(
      formapagamento => {
        return (
          (PayMethCod
            ? formapagamento.PayMethCod.toString()
                .toLowerCase()
                .includes(PayMethCod.toLowerCase())
            : true) &&
          (Descript
            ? (formapagamento.Descript || '')
                .toLowerCase()
                .includes(Descript.toLowerCase())
            : true)
        );
      }
    );
  }

  selecionarFormaPagamentoPorCodigo(PayMethCod: string) {
    const cleanedPayMethCod = PayMethCod.trim().toLowerCase();

    const formapagamento = this.formaspagamento.find(
      f => f.PayMethCod.trim().toLowerCase() === cleanedPayMethCod
    );

    if (formapagamento) {
      this.formaPagamentoControl.setValue(formapagamento.Descript);
      this.PayMethCodSelecionado = formapagamento.PayMethCod;
    } else {
      console.error(
        'Forma de pagamento não encontrada para o PayMethCod:',
        PayMethCod
      );
    }
  }

  private _filterCondicoesPagamento(nome: string, CondPgMeth: string): any[] {
    const filterValue = nome;
    return this.condicoesPagamento.filter(
      condicao =>
        condicao.PymntGroup.includes(filterValue) ||
        condicao.GroupNum.toString().includes(filterValue) ||
        condicao.GroupNum.toString() === CondPgMeth
    );
  }

  carregarCondicaoPg(): void {
    this.loading = true;
    this.openService.condicaoPagamento().subscribe(
      condicoespg => {
        this.condicoesPagamento = condicoespg;
        this.condicoesPagamentoFiltradas = [...this.condicoesPagamento];
        this.loading = false;
        const CondPgMeth = this.data.orcamento.PaymentGroupCode;
        if (CondPgMeth) {
          this.selecionarCondicaoPagamentoPorCodigo(CondPgMeth);
        }
      },
      error => {
        console.error('Erro ao carregar condições de pagamento:', error);
        this.loading = false;
      }
    );
  }

  filtrarCondicoesPagamento(): void {
    const { PymntGroup, GroupNum } = this.filtroForm.value;
    this.condicoesPagamentoFiltradas = this.condicoesPagamento.filter(
      condicao => {
        return (
          (GroupNum
            ? condicao.GroupNum.toString()
                .toLowerCase()
                .includes(GroupNum.toLowerCase())
            : true) &&
          (PymntGroup
            ? (condicao.PymntGroup || '')
                .toLowerCase()
                .includes(PymntGroup.toLowerCase())
            : true)
        );
      }
    );
  }

  selecionarCondicaoPagamentoPorCodigo(CondPgMeth: string) {
    if (CondPgMeth) {
      const cleanedCondPgMeth = CondPgMeth;

      const condicaoPagamento = this.condicoesPagamento.find(
        c => c.GroupNum === cleanedCondPgMeth
      );

      if (condicaoPagamento) {
        this.pagamentoControl.setValue(condicaoPagamento.PymntGroup);
        this.GroupNumSelecionado = condicaoPagamento.CondPgMeth;
      } else {
        console.error(
          'Condição de pagamento não encontrada para o CondPgMeth:',
          CondPgMeth
        );
      }
    } else {
      console.error('CondPgMeth está indefinido ou vazio:', CondPgMeth);
    }
  }
  carregarVendedores(): void {
    this.loading = true;
    this.openService.obterVendedores().subscribe(
      vendedores => {
        if (vendedores) {
          this.vendedores = vendedores;
          this.vendedoresFiltrados = [...this.vendedores];

          const salesPersonCode = this.data?.orcamento?.SalesPersonCode;
          if (salesPersonCode) {
            this.selecionarVendedorPorCodigo(salesPersonCode);
          } else {
            console.error('SalesPersonCode não encontrado em data.orcamento');
          }
        } else {
          console.error('Nenhum vendedor foi carregado');
        }
        this.loading = false;
      },
      error => {
        console.error('Erro ao carregar vendedores:', error);
        this.loading = false;
      }
    );
  }

  filtrarVendedores(): void {
    const { SlpCode, SlpName } = this.filtroForm.value;
    this.vendedoresFiltrados = this.vendedores.filter(vendedor => {
      return (
        (SlpCode
          ? vendedor.SlpCode.toString()
              .toLowerCase()
              .includes(SlpCode.toLowerCase())
          : true) &&
        (SlpName
          ? (vendedor.SlpName || '')
              .toLowerCase()
              .includes(SlpName.toLowerCase())
          : true)
      );
    });
  }

  selecionarVendedor(event: any) {
    const selectedSlpCode = event.option.value;
    this.data.orcamento.SalesPersonCode = selectedSlpCode;

    const vendedor = this.vendedores.find(
      v => v.SlpCode.toString() === selectedSlpCode
    );
    if (vendedor) {
      this.vendedorControl.setValue(vendedor.SlpName);
      this.SlpCodeSelecionado = vendedor.SlpCode;
    } else {
      console.error(
        'Vendedor não encontrado para o SalesPersonCode:',
        selectedSlpCode
      );
    }
  }

  selecionarVendedorPorCodigo(salesPersonCode: string) {
    const vendedor = this.vendedores.find(v => v.SlpCode === salesPersonCode);
    if (vendedor) {
      this.vendedorControl.setValue(vendedor.SlpName);
      this.SlpCodeSelecionado = vendedor.SlpCode;
    } else {
      console.error(
        'Vendedor não encontrado para o SalesPersonCode:',
        salesPersonCode
      );
    }
  }

  private _filterVendedores(nome: string, salesPersonCode: string): any[] {
    const filterValue = nome ? nome.toLowerCase() : '';
    return this.vendedores.filter(
      vendedor =>
        (salesPersonCode
          ? vendedor.SlpCode.toString()
              .toLowerCase()
              .includes(salesPersonCode.toString().toLowerCase())
          : true) && vendedor.SlpName.toLowerCase().includes(filterValue)
    );
  }

  loadOrcamentos() {
    this.openService.pedidosDetails().subscribe(
      (orcamentos: any[]) => {
        this.orcamentosFiltrados = orcamentos;
      },
      error => {
        console.error('Erro ao carregar orçamentos:', error);
      }
    );
  }

  modificarOrcamento(selecionado: any): void {
    this.loading = true;

    const docentry = this.codPedidoControl.value;

    if (!docentry) {
      console.error(
        'O objeto selecionado não possui a propriedade DocEntry.',
        selecionado
      );
      this.loading = false;
      return;
    }

    this.orcamentoSelecionado = selecionado;

    if (!this.orcamentosFiltrados || this.orcamentosFiltrados.length === 0) {
      console.error(
        'A lista de orçamentos filtrados está vazia ou indefinida.'
      );
      this.loading = false;
      return;
    }

    this.orcamentoSelecionado = this.orcamentosFiltrados.find(orcamento => {
      return orcamento.DocEntry === docentry;
    });

    if (!this.orcamentoSelecionado) {
      this.dialog.open(DialogErrorEditarComponent, {
        width: '250px',
      });
      console.error(
        'Nenhum orçamento encontrado para editar com DocEntry:',
        docentry
      );
      this.loading = false;
      return;
    }

    const dialogRef = this.dialog.open(ModificarComponent, {
      data: { observacao: this.orcamentoSelecionado.observacao || '' },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loading = false;
      if (result !== undefined) {
        this.orcamentoSelecionado.observacao = result;
        this.continuarAtualizacaoOrcamento(docentry);
      }
    });
  }

  continuarAtualizacaoOrcamento(docEntry: number): void {
    const codCliente = this.codClienteControl.value;
    const CdEntrega = this.CdEntregaControl.value;
    const vendedor = this.SlpCodeSelecionado;
    const observables = this.observacoescadControl.value;
    const observacao = this.orcamentoSelecionado.observacao || '';
    const metodopg = this.PayMethCodSelecionado;
    const condicaoPg = this.GroupNumSelecionado;
    const endereco = this.enderecoControl.value;
    const documentLines = this.dadosTabela.map(item => ({
      ItemCode: item.nItem,
      Quantity: item.quantidade,
      UnitPrice: item.precoUnitario,
      Usage: item.cod_venda,
      U_SOLU_Cultura: item.cultura,
      U_SOLU_TP_Aplicacao: item.aplicacao,
      U_OM_NumberOC: item.numeroOC,
      U_OM_NumberOCLine: null,
      ShipDate: item.data,
      DiscountPercent: item.desconto,
    }));

    const cdEntregaObj = this.cdsEntrega.find(
      c => c.BPLName.toString() === CdEntrega
    );
    const cdEntregaId = cdEntregaObj ? cdEntregaObj.BPLId : null;

    const requestBody = {
      CardCode: codCliente,
      DocDueDate: '2024-04-19',
      DocObjectCode: 23,
      SalesPersonCode: vendedor,
      NumAtCard: '01874',
      Comments: `Observaçõs do Orçamento: ${observables}     Modificações solicitadas: ${observacao}`,
      PaymentMethod: metodopg,
      GroupNumber: condicaoPg,
      DiscountPercent: 0.0,

      Address2: endereco,
      DocumentLines: documentLines,
      TaxExtension: {
        Incoterms: 1,
      },
    };

    this.loading = true;

    this.openService.editarOrcamento(requestBody, docEntry).subscribe(
      response => {
        const dialogRef = this.dialog.open(DialogPedidoComponent, {
          width: '250px',
          data: {
            message: 'Modificações enviadas com Sucesso!',
            showSuccessMessage: true,
          },
        });

        dialogRef.afterClosed().subscribe(() => {
          this.loading = false;

          this.matDialogRef.close(true);
        });
      },
      error => {
        console.error('Erro ao enviar dados para a API:', error);
        this.loading = false;
      }
    );
  }

  subscribeToObservacaoChanges() {
    this.modificarService.currentObservacao.subscribe(observacaoModf => {});
  }

  async carregarItems(): Promise<void> {
    try {
      this.loading = true;
      this.items = await this.openService.carregarItems().toPromise();
      this.itemsFiltrados = [...this.items];
      this.loading = false;
    } catch (error) {
      console.error('Erro ao carregar itens:', error);
      this.loading = false;
    }
  }

  filtrarItens(): void {
    const { nItemControl, descricaoProdutoControl } = this.filtroForm.value;
    this.itemsFiltrados = this.items.filter(item => {
      const itemCodeMatches = nItemControl
        ? item.ItemCode.toLowerCase().includes(nItemControl.toLowerCase())
        : true;
      const descricaoMatches = descricaoProdutoControl
        ? (item.DescricaoDoProduto || '')
            .toLowerCase()
            .includes(descricaoProdutoControl.toLowerCase())
        : true;
      return itemCodeMatches && descricaoMatches;
    });
  }
  selecionarProduto(event: any) {
    const itemSelecionado = this.itemsFiltrados.find(
      item =>
        item.ItemCode === event.option.value ||
        item.ItemName === event.option.value
    );
    if (itemSelecionado) {
      this.filtroForm.get('nItemControl').setValue(itemSelecionado.ItemCode);
      this.filtroForm
        .get('descricaoProdutoControl')
        .setValue(itemSelecionado.ItemName);
    }
  }

  async carregarMeioCultura(): Promise<void> {
    this.loading = true;
    try {
      const response = await this.openService.carregarMeioCultura().toPromise();
      this.culturas = response.listaMeioCultura;
      this.culturasFiltradas = [...this.culturas];
    } catch (error) {
      console.error('Erro ao carregar meios de cultura:', error);
    } finally {
      this.loading = false;
    }
  }

  filtrarCultura(): void {
    const { Name, Code } = this.filtroForm.value;
    this.culturasFiltradas = this.culturas.filter(cultura => {
      return (
        (Code
          ? cultura.Code.toLowerCase().includes(Code.toLowerCase())
          : true) &&
        (Name
          ? (cultura.Name || '').toLowerCase().includes(Name.toLowerCase())
          : true)
      );
    });
  }

  carregarCdEntregas(): void {
    this.loading = true;
    this.openService.carregarCdsEntrega().subscribe(
      cdsEntrega => {
        if (cdsEntrega) {
          this.cdsEntrega = cdsEntrega;
          this.cdsEntregaFiltrados = [...this.cdsEntrega];

          const BPLName = this.data?.orcamento?.BPLName;
          if (BPLName) {
            this.selecionarCdEntregaPorNome(BPLName);
          } else {
            console.error('BPLId não encontrado em data.orcamento');
          }
        } else {
          console.error('Nenhum CdEntrega foi carregado');
        }
        this.loading = false;
      },
      error => {
        console.error('Erro ao carregar CdEntregas:', error);
        this.loading = false;
      }
    );
  }

  filtrarCdEntregas(): void {
    const { BPLId, BPLName } = this.filtroForm.value;
    this.cdsEntregaFiltrados = this.cdsEntrega.filter(
      cdEntrega =>
        (BPLId
          ? cdEntrega.BPLId.toString()
              .toLowerCase()
              .includes(BPLId.toLowerCase())
          : true) &&
        (BPLName
          ? cdEntrega.BPLName.toLowerCase().includes(BPLName.toLowerCase())
          : true)
    );
  }

  selecionarCdEntrega(event: any) {
    const selectedBplName = event.option.value;
    this.data.orcamento.BPLName = selectedBplName;

    const cdEntrega = this.cdsEntrega.find(
      cd => cd.BPLName.toLowerCase() === selectedBplName.toLowerCase()
    );
    if (cdEntrega) {
      this.CdEntregaControl.setValue(cdEntrega.BPLName);
      this.BPLIdSelecionado = cdEntrega.BPLId;
    } else {
      console.error(
        'CdEntrega não encontrado para o BPLName:',
        selectedBplName
      );
    }
  }

  selecionarCdEntregaPorNome(BPLName: string) {
    const cdEntrega = this.cdsEntrega.find(
      cd => cd.BPLName.toLowerCase() === BPLName.toLowerCase()
    );
    if (cdEntrega) {
      this.CdEntregaControl.setValue(cdEntrega.BPLName);
      this.BPLIdSelecionado = cdEntrega.BPLId;
    } else {
      console.error('CdEntrega não encontrado para o BPLName:', BPLName);
    }
  }

  private _filterCdEntregas(nome: string, BPLId: string): any[] {
    const filterValue = nome ? nome.toLowerCase() : '';
    return this.cdsEntrega.filter(
      cdEntrega =>
        (BPLId
          ? cdEntrega.BPLId.toString()
              .toLowerCase()
              .includes(BPLId.toLowerCase())
          : true) && cdEntrega.BPLName.toLowerCase().includes(filterValue)
    );
  }

  get selectUtilizado() {
    return false;
  }

  adicionarItemFromAPI(data: any): void {
    const nindice = this.indiceSequencial++;
    const nItem = data.ItemCode;
    const descricaoProduto = data.ItemDescription;
    const quantidade = data.Quantity;
    const desconto = data.DiscountPercent;
    const aplicacao = data.U_SOLU_TP_Aplicacao;
    const cod_venda = data.Usage;
    const cultura = data.U_SOLU_Cultura;
    const precoUnitario = `${data.Currency} ${data.Price} `;
    const dataEntrega = data.ShipDate;
    const observacoes = data.observacoes;
    const valorNumerico = parseFloat(precoUnitario.replace(/[^\d.-]/g, ''));
    const precoUnitarioFormatado = this.currencyPipe.transform(
      valorNumerico / 100,
      'BRL',
      'symbol',
      '1.0-2'
    );

    const item: Item = {
      nindice: nindice,
      nItem: nItem,
      descricaoProduto: descricaoProduto,
      quantidade: quantidade,
      desconto: desconto,
      aplicacao: aplicacao,
      cultura: cultura,
      precoUnitario: precoUnitarioFormatado,
      data: dataEntrega,
      observacoes: observacoes,
      cod_venda: cod_venda,
      numeroOC: 0,
    };

    this.dadosTabela.push(item);
  }

  selecionarLinha(linha: any) {
    this.linhasSelecionadas = linha;

    if (this.linhasSelecionadas.DocEntry) {
      this.loading = true;

      if (this.AtCard) {
        this.AtCard.setValue(this.linhasSelecionadas.NumAtCard);
      } else {
        console.error('Função AtCard não encontrada');
      }
    } else {
      console.error('DocEntry não encontrado na linha selecionada');
    }
  }

  cancelarOrcamento(selecionado: any): void {
    this.loading = true;
    const DocEntry = this.codPedidoControl.value;

    if (!DocEntry) {
      console.error('O campo codPedidoControl está vazio.');
      this.loading = false;
      return;
    }
    const dialogRef = this.dialog.open(DialogCancelarComponent, {
      width: '250px',
      data: {
        message: 'Você tem certeza que deseja cancelar o orçamento?',
        showSuccessMessage: false,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.enviarCancelamento(DocEntry.toString());
      } else {
        this.loading = false;
      }
    });
  }

  enviarCancelamento(DocEntry: number): void {
    this.loading = true;

    this.openService.orcamentoCancelar(DocEntry).subscribe({
      next: () => {
        this.cancel();

        const dialogRef = this.dialog.open(DialogCancelarComponent, {
          width: '250px',
          data: {
            message: 'Orçamento cancelado com sucesso.',
            showSuccessMessage: true,
          },
        });

        dialogRef.afterClosed().subscribe(() => {
          this.loading = false;
        });
      },
      error: err => {
        this.loading = false;
        console.error('Erro ao cancelar o orçamento:', err);
      },
    });
  }

  enviarPedido(): void {
    this.loading = true;

    const dataVenc = this.dataVencimentoControl.value;
    const CdEntregaId = this.BPLIdSelecionado;
    const documentLines = this.dadosTabela.map(item => ({
      BaseEntry: this.codPedidoControl.value,
      BaseType: '23',
      BaseLine: item.nindice,
    }));
    const requestBody = {
      DocDueDate: dataVenc,
      BPL_IDAssignedToInvoice: CdEntregaId,
      DocumentLines: documentLines,
    };

    const dialogRef = this.dialog.open(DialogPedidoComponent, {
      width: '500px',
      data: {
        message: 'Deseja realmente aprovar este pedido?',
        showSuccessMessage: false,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.openService
          .envioPedido(requestBody)
          .pipe(
            catchError(error => {
              console.error('Erro ao enviar pedido:', error);
              return [];
            }),
            finalize(() => {
              this.loading = false;
            })
          )
          .subscribe(
            response => {
              const successDialogRef = this.dialog.open(DialogPedidoComponent, {
                width: '350px',
                data: {
                  message: 'Pedido Aprovado com Sucesso!',
                  showSuccessMessage: true,
                },
              });
              successDialogRef.afterClosed().subscribe(() => {
                this.cancel();

                this.iniciarVerificacao(this.AtCard.value);

                this.logApproval();
              });
            },
            error => {
              const errorDialogRef = this.dialog.open(DialogPedidoComponent, {
                width: '350px',
                data: {
                  message: 'Erro ao Aprovar pedido!',
                  showSuccessMessage: true,
                },
              });
              errorDialogRef.afterClosed().subscribe(() => {
                console.error('Erro ao enviar pedido:', error);
              });
            }
          );
      } else {
        this.loading = false;
      }
    });
  }

  async iniciarVerificacao(NumAtCard: string): Promise<void> {
    const maxTentativas = 12;
    const delay = 5000;
    let tentativas = 0;

    while (tentativas < maxTentativas) {
      try {
        const numbersCard = await this.openService.obterAtCard().toPromise();

        const numberCardFiltrado = numbersCard.map(card =>
          card.DocEntry.toString()
        );

        if (numberCardFiltrado.length > 0) {
          const ultimoDocEntry = numberCardFiltrado[0];
          this.envioEmail(ultimoDocEntry);

          this.loading = false;
          return;
        } else {
          console.error('Nenhum DocEntry retornado:', numberCardFiltrado);
        }
      } catch (error) {
        console.error('Erro ao obter último DocEntry:', error);
        this.loading = false;
        return;
      }

      await new Promise(resolve => setTimeout(resolve, delay));
      tentativas++;
    }

    console.error('Máximo de tentativas alcançado. Parando a verificação.');
    this.loading = false;
  }

  envioEmail(NumAtCard: any): void {
    const valorAtCard = NumAtCard;
    const partes = valorAtCard.match(/\d+/g);
    const codigoVenda = partes ? partes.join('') : '';

    const docEntry = codigoVenda;

    this.openService.envioEmail(docEntry).subscribe(
      emailResponse => {},
      error => {
        console.error('Erro ao enviar email:', error);
      }
    );
  }

  logApproval(): void {
    if (this.currentUser) {
      const fullName = `${this.currentUser.firstname} ${this.currentUser.lastname}`;
      const logMessage = `User ${fullName} (ID: ${this.currentUser.id}) approved the action.`;
    }
  }
}
