import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { OpenSalesOrderComponent } from './open-sales-order/open-sales-order.component';
import { MatDialog } from '@angular/material/dialog';
import { OpenSalesService } from './open-sales.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, map, startWith, forkJoin, timeout } from 'rxjs';
import { Farm } from 'app/core/models/admin/farms.model';
import { FarmsService } from 'app/core/services/admin/farms.service';
import { OpenSalesDialogComponent } from './open-sales-dialog/open-sales-dialog.component';
import { result } from 'lodash';
import { Console } from 'console';
import { UserService } from 'app/core/services/auth/user.service';
import { User } from 'app/core/models/auth/user.model';
import { SearchOrcamentoComponent } from './search-orcamento/search-orcamento.component';
import { FilterModel } from 'app/core/models/admin/filter.models';

@Component({
  selector: 'app-open-sales',
  templateUrl: './open-sales.component.html',
  styleUrls: ['./open-sales.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OpenSalesComponent implements OnInit {
  isMobile: boolean = false;
  isLoading = false;
  ordenacaoCrescente = true;
  public statusSelecionado: string = '';
  ordenacaoCrescenteCardName: boolean = true;

  ordenacaoCrescenteDocNum: boolean = true;
  isAdmin = false;
  orcamentoControl = new FormControl();
  loading: boolean = false;
  filtroForm: FormGroup;
  orcamentosFiltrados: any[] = [];
  orcamentos: any[] = [];
  filteredOrcamentos: Observable<any[]>;
  currentUser: User;
  editarAtivo: boolean = false;
  isClient = false;
  isComercial = false;
  clicavel = true;
  linhasSelecionadas: any;
  farms$: Observable<Farm[]>;
  farms: Farm[];
  erpIds: any[] = [];
  vendedorLogado: string;
  vendedores: any[] = [];
  vendedoresFiltrados: any[] = [];

  limit: number = 10;
  currentPage: number = 1;
  isLoadingMore: boolean = false;
  allDataLoaded: boolean = false;

  @ViewChild('scrollContainer', { static: false }) scrollContainer!: ElementRef;

  constructor(
    private _farmsService: FarmsService,
    private userAuth: UserService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private orcamentosService: OpenSalesService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  /**
   * Detecta se é mobile
   */
  checkScreenSize() {
    this.isMobile = window.innerWidth <= 768;
    localStorage.setItem('isMobile', JSON.stringify(this.isMobile));
  }

  async ngOnInit(): Promise<void> {
    this.changeDetectorRef.detectChanges();

    this.orcamentoControl.valueChanges.subscribe(value => {
      this.filtrarOrcamentosPorCliente();
    });

    this.filteredOrcamentos = this.orcamentoControl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value?.CardName)),
      map(nome =>
        nome ? this._filterOrcamentos(nome) : this.orcamentos.slice()
      )
    );

    await this.carregarOrcamentos();

    this.filtroForm = this.fb.group({
      DocNum: [''],
      DocDate: [''],
      CardName: [''],
      CardCode: [''],
      DocStatus: [''],
      CANCELED: [''],
      DocEntry: [''],
    });

    /**
     * Detecta se é mobile
     */
    this.checkScreenSize();
    window.addEventListener('resize', () => this.checkScreenSize());
  }
  async carregarOrcamentos(): Promise<void> {
    this.isLoading = true;
    try {
      const user = await this.userAuth.get().toPromise();
      this.currentUser = user;
      this.isClient = user.roles.some(r => r.scope === 'cliente');
      this.isComercial = user.roles.some(r => r.scope === 'comercial');
      this.isAdmin = user.roles.some(r => r.scope === 'admin');

      if (this.isClient) {
        this.farms = await this._farmsService
          .searchFarmByUser(user.id)
          .toPromise();
        this.erpIds = this.farms.map(farm => farm.erp_id);
        await this.carregarPedidos();
      } else if (this.isComercial) {
        await this.carregarVendedor();
      } else if (this.isAdmin) {
        await this.carregarOrcamentosAdmin();
      }
    } catch (error) {
    } finally {
      this.isLoading = false;
      this.changeDetectorRef.detectChanges();
    }
  }

  verificarComentarios(comments: string): string {
    try {
      if (!comments) {
        return 'N';
      }

      const modificacoesIndex = comments.indexOf('Modificações solicitadas:');
      const alteracoesIndex = comments.indexOf('Alterações Realizadas');

      if (modificacoesIndex !== -1 && alteracoesIndex !== -1) {
        if (modificacoesIndex < alteracoesIndex) {
          return 'Y';
        } else {
          return 'P';
        }
      } else if (modificacoesIndex !== -1) {
        return 'M';
      } else {
        return 'N';
      }
    } catch (error) {
      return 'N';
    }
  }

  async carregarOrcamentosPorComercial(): Promise<void> {
    try {
      this.orcamentos = await this.orcamentosService
        .filtrarPorNomeUsuario(this.vendedorLogado)
        .toPromise();
      this.orcamentosFiltrados = [...this.orcamentos];

      this.orcamentos = this.orcamentos.map(orcamento => ({
        ...orcamento,
        modificacoesSolicitadas: this.verificarComentarios(orcamento.Comments),
      }));
      this.filtrarOrcamentos();
    } catch (error) {
      console.error('Erro ao carregar orçamentos por comercial:', error);
    }
  }

  carregarPedidos(): void {
    this.loading = true;
    const observables = this.farms.map(farm =>
      this.orcamentosService.filtrarPorCardCode(farm.erp_id)
    );

    this.loading = true;

    forkJoin(observables).subscribe(
      resultados => {
        this.orcamentos = resultados.flat();
        this.orcamentos = this.orcamentos.map(orcamento => ({
          ...orcamento,
          modificacoesSolicitadas: this.verificarComentarios(
            orcamento.Comments
          ),
        }));
        this.filtrarOrcamentos();
        this.loading = false;
      },
      error => {
        this.loading = false;
      }
    );
  }
  carregarOrcamentosAdmin(): void {
    this.loading = true;
    this.orcamentosService
      .carregarOrcamentos()
      .toPromise()
      .then(
        orcamentos => {
          this.orcamentos = orcamentos.map(orcamento => ({
            ...orcamento,
            modificacoesSolicitadas: this.verificarComentarios(
              orcamento.Comments
            ),
          }));
          this.orcamentosFiltrados = [...this.orcamentos];
          this.filtrarOrcamentos();
          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      );
  }

  async carregarVendedor(): Promise<void> {
    if (this.currentUser && this.currentUser.email) {
      try {
        this.vendedores = await this.orcamentosService
          .obterVendedores()
          .toPromise();
        this.vendedoresFiltrados = [...this.vendedores];
        const email = this.currentUser.email;
        this.selecionarVendedor(email);
      } catch (error) {}
    }
  }
  selecionarVendedor(email: string) {
    const emailSelecionado = email.trim().toLowerCase();

    const vendedor = this.vendedores.find(v => {
      const emailVendedor = v.Email?.trim().toLowerCase();
      return emailVendedor === emailSelecionado;
    });

    if (vendedor) {
      this.vendedorLogado = vendedor.SlpName;
      this.carregarOrcamentosPorComercial();
    }
  }
  filtrarVendedores(): void {
    const { SlpCode, SlpName, Email } = this.filtroForm.value;
    this.vendedoresFiltrados = this.vendedores.filter(vendedor => {
      return (
        (SlpCode
          ? vendedor.SlpCode.toLowerCase().includes(SlpCode.toLowerCase())
          : true) &&
        (SlpName
          ? vendedor.SlpName.toLowerCase().includes(SlpName.toLowerCase())
          : true) &&
        (Email
          ? (vendedor.Email || '').toLowerCase().includes(Email.toLowerCase())
          : true)
      );
    });
  }

  private _filterOrcamentos(nome: string): any[] {
    const filterValue = nome ? nome.toLowerCase() : '';
    return this.orcamentos.filter(orcamento =>
      orcamento.CardName.toLowerCase().includes(filterValue)
    );
  }

  /*filtrarOrcamentosPorCliente(): void {
    const clienteFiltro = this.orcamentoControl.value;
    const filterValue = clienteFiltro ? clienteFiltro.toLowerCase() : '';
    if (filterValue && typeof filterValue === 'string') {
      this.orcamentosFiltrados = this.orcamentos.filter(
        orcamento =>
          orcamento.CardName &&
          orcamento.CardName.toLowerCase().includes(filterValue)
      );
    } else {
      this.orcamentosFiltrados = [...this.orcamentos];
    }
  }*/

  filtrarOrcamentosPorCliente(): void {
    const clienteFiltro = this.orcamentoControl.value;
    const filterValue = clienteFiltro ? clienteFiltro.toLowerCase() : '';

    if (filterValue && typeof filterValue === 'string') {
      this.orcamentosFiltrados = this.orcamentos.filter(
        orcamento =>
          orcamento.CardName &&
          orcamento.CardName.toLowerCase().includes(filterValue)
      );
    } else {
      // 🟢 Se o campo de pesquisa for apagado, restauramos a lista completa
      this.orcamentosFiltrados = [...this.orcamentos];
      this.allDataLoaded = false; // 🟢 Permite carregar mais itens após pesquisa
    }
  }

  filtrarOrcamentos(): void {
    const {
      DocNum,
      DocDate,
      CardName,
      CardCode,
      DocStatus,
      CANCELED,
      DocEntry,
      NumAtCard,
      Comments,
    } = this.filtroForm.value;

    this.orcamentosFiltrados = this.orcamentos.filter(orcamento => {
      return (
        (DocNum
          ? orcamento.DocNum.toLowerCase().includes(DocNum.toLowerCase())
          : true) &&
        (DocDate
          ? orcamento.DocDate.toLowerCase().includes(DocDate.toLowerCase())
          : true) &&
        (CardName
          ? (orcamento.CardName || '')
              .toLowerCase()
              .includes(CardName.toLowerCase())
          : true) &&
        (CardCode
          ? (orcamento.CardCode || '')
              .toLowerCase()
              .includes(CardCode.toLowerCase())
          : true) &&
        (DocStatus
          ? (orcamento.DocStatus || '')
              .toLowerCase()
              .includes(DocStatus.toLowerCase())
          : true) &&
        (CANCELED
          ? (orcamento.CANCELED || '')
              .toLowerCase()
              .includes(CANCELED.toLowerCase())
          : true) &&
        (DocEntry
          ? (orcamento.DocEntry || '')
              .toLowerCase()
              .includes(DocEntry.toLowerCase())
          : true) &&
        (NumAtCard
          ? (orcamento.NumAtCard || '')
              .toLowerCase()
              .includes(NumAtCard.toLowerCase())
          : true) &&
        (Comments
          ? (orcamento.Comments || '')
              .toLowerCase()
              .includes(Comments.toLowerCase())
          : true)
      );
    });

    this.loading = false;
    if (this.orcamentosFiltrados.length === 0) {
    }
  }

  createSalesOrder(): void {
    const dialogRef = this.dialog.open(OpenSalesOrderComponent, {
      autoFocus: true,
      disableClose: true,
      /*width: '1050px',
      height: 'auto',*/
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Result', result);
      if (result) {
        this.carregarOrcamentos();
      }

      this.changeDetectorRef.detectChanges();
    });
  }

  selecionarLinha(linha: any): void {
    this.isLoading = true;
    setTimeout(() => {
      this.linhasSelecionadas = linha;
      this.editarItem();
      this.isLoading = false;
    }, 1000);
  }

  editarItem(): void {
    this.isLoading = true;
    if (this.linhasSelecionadas && this.linhasSelecionadas.DocNum) {
      this.editarAtivo = true;

      if (this.linhasSelecionadas.DocEntry) {
        this.openDialog(this.linhasSelecionadas.DocEntry);
      } else {
        this.editarAtivo = false;
      }
    }
    this.isLoading = false;
  }

  openDialog(DocEntry: any): void {
    this.loading = true;

    this.orcamentosService.orcamentoDetails(DocEntry).subscribe(
      detalhes => {
        const dialogRef = this.dialog.open(OpenSalesDialogComponent, {
          data: detalhes,
          disableClose: true,
          autoFocus: false,
        });
        this.clicavel = true;
        dialogRef.afterClosed().subscribe(result => {
          this.editarAtivo = false;
          this.clicavel = true;
          if (result) {
            this.recargarOrcamentos();
          }
          this.changeDetectorRef.detectChanges();
          this.loading = false;
        });
      },
      error => {
        this.clicavel = true;
        this.loading = false;
        this.editarAtivo = false;
      }
    );
  }

  searchDialog() {
    const dialogRef = this.dialog.open(SearchOrcamentoComponent, {
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result: any[]) => {
      if (result) {
        this.aplicarFiltro(result);
      }
    });

    dialogRef.componentInstance.filterApplied.subscribe(
      (filters: FilterModel[]) => {
        this.aplicarFiltro(filters);
      }
    );
  }

  aplicarFiltro(filters: any) {
    if (!filters || filters.length === 0) {
      return;
    }

    if (this.isComercial && this.vendedorLogado) {
      filters.push({
        field: 'nomeVendedor',
        filter: this.vendedorLogado,
        value: this.vendedorLogado,
      });
    }

    const filtroCardCode = filters.find(
      (filter: any) => filter.field === 'CardCode'
    );
    const hasCardCodeFilter = !!filtroCardCode;

    let orcamentosFiltradosTotal: any[] = [];

    const selectedStatus = filters.find(f => f.field === 'status')?.value;

    if (this.isClient) {
      const cardCodes = hasCardCodeFilter
        ? [filtroCardCode.filter]
        : Array.from(
            new Set(this.orcamentos.map(orcamento => orcamento.CardCode))
          );

      const observables = cardCodes
        .map(cardCode => {
          let queryParams = '';

          filters.forEach(filter => {
            if (
              filter.field !== 'CardCode' &&
              filter.field !== 'status' &&
              filter.value &&
              filter.value !== ''
            ) {
              const encodedValue = encodeURIComponent(filter.filter);
              queryParams += `${filter.field}=${encodedValue}&`;
            }
          });

          queryParams += `CardCode=${encodeURIComponent(cardCode)}`;
          queryParams = queryParams.replace(/&$/, '');

          if (queryParams) {
            const url = `/api/consulta/listaOrcamentos?${queryParams}`;

            this.loading = true;
            return this.orcamentosService.buscarDados(url);
          }
          return null;
        })
        .filter(obs => obs !== null);

      if (observables.length > 0) {
        forkJoin(observables).subscribe(
          (responses: any[]) => {
            responses.forEach(response => {
              orcamentosFiltradosTotal =
                orcamentosFiltradosTotal.concat(response);
            });

            if (selectedStatus && selectedStatus.length > 0) {
              if (Array.isArray(selectedStatus)) {
                orcamentosFiltradosTotal = orcamentosFiltradosTotal.filter(
                  orcamento =>
                    selectedStatus.includes(
                      this.getStatus(
                        orcamento.DocStatus,
                        orcamento.CANCELED,
                        orcamento.modificacoesSolicitadas
                      )
                    )
                );
              } else {
                orcamentosFiltradosTotal = orcamentosFiltradosTotal.filter(
                  orcamento =>
                    this.getStatus(
                      orcamento.DocStatus,
                      orcamento.CANCELED,
                      orcamento.modificacoesSolicitadas
                    ) === selectedStatus
                );
              }
            }
            this.orcamentosFiltrados = orcamentosFiltradosTotal;
            this.loading = false;
          },
          error => {
            this.loading = false;

            orcamentosFiltradosTotal = this.orcamentos;
            if (selectedStatus && selectedStatus.length > 0) {
              if (Array.isArray(selectedStatus)) {
                orcamentosFiltradosTotal = orcamentosFiltradosTotal.filter(
                  orcamento =>
                    selectedStatus.includes(
                      this.getStatus(
                        orcamento.DocStatus,
                        orcamento.CANCELED,
                        orcamento.modificacoesSolicitadas
                      )
                    )
                );
              } else {
                orcamentosFiltradosTotal = orcamentosFiltradosTotal.filter(
                  orcamento =>
                    this.getStatus(
                      orcamento.DocStatus,
                      orcamento.CANCELED,
                      orcamento.modificacoesSolicitadas
                    ) === selectedStatus
                );
              }
            }
            this.orcamentosFiltrados = orcamentosFiltradosTotal;
          }
        );
      } else {
        orcamentosFiltradosTotal = this.orcamentos;
        if (selectedStatus && selectedStatus.length > 0) {
          if (Array.isArray(selectedStatus)) {
            orcamentosFiltradosTotal = orcamentosFiltradosTotal.filter(
              orcamento =>
                selectedStatus.includes(
                  this.getStatus(
                    orcamento.DocStatus,
                    orcamento.CANCELED,
                    orcamento.modificacoesSolicitadas
                  )
                )
            );
          } else {
            orcamentosFiltradosTotal = orcamentosFiltradosTotal.filter(
              orcamento =>
                this.getStatus(
                  orcamento.DocStatus,
                  orcamento.CANCELED,
                  orcamento.modificacoesSolicitadas
                ) === selectedStatus
            );
          }
        }
        this.orcamentosFiltrados = orcamentosFiltradosTotal;
      }
    } else {
      let queryParams = '';

      filters.forEach(filter => {
        if (
          filter.field !== 'CardCode' &&
          filter.field !== 'status' &&
          filter.value &&
          filter.value !== ''
        ) {
          const encodedValue = encodeURIComponent(filter.filter);
          queryParams += `${filter.field}=${encodedValue}&`;
        }
      });

      queryParams = queryParams.replace(/&$/, '');

      if (queryParams) {
        const url = `/api/consulta/listaOrcamentos?${queryParams}`;

        this.loading = true;
        this.orcamentosService.buscarDados(url).subscribe(
          (response: any) => {
            orcamentosFiltradosTotal = response;

            if (selectedStatus && selectedStatus.length > 0) {
              if (Array.isArray(selectedStatus)) {
                orcamentosFiltradosTotal = orcamentosFiltradosTotal.filter(
                  orcamento =>
                    selectedStatus.includes(
                      this.getStatus(
                        orcamento.DocStatus,
                        orcamento.CANCELED,
                        orcamento.modificacoesSolicitadas
                      )
                    )
                );
              } else {
                orcamentosFiltradosTotal = orcamentosFiltradosTotal.filter(
                  orcamento =>
                    this.getStatus(
                      orcamento.DocStatus,
                      orcamento.CANCELED,
                      orcamento.modificacoesSolicitadas
                    ) === selectedStatus
                );
              }
            }

            this.orcamentosFiltrados = orcamentosFiltradosTotal;
            this.loading = false;
          },
          error => {
            this.loading = false;

            orcamentosFiltradosTotal = this.orcamentos;
            if (selectedStatus && selectedStatus.length > 0) {
              if (Array.isArray(selectedStatus)) {
                orcamentosFiltradosTotal = orcamentosFiltradosTotal.filter(
                  orcamento =>
                    selectedStatus.includes(
                      this.getStatus(
                        orcamento.DocStatus,
                        orcamento.CANCELED,
                        orcamento.modificacoesSolicitadas
                      )
                    )
                );
              } else {
                orcamentosFiltradosTotal = orcamentosFiltradosTotal.filter(
                  orcamento =>
                    this.getStatus(
                      orcamento.DocStatus,
                      orcamento.CANCELED,
                      orcamento.modificacoesSolicitadas
                    ) === selectedStatus
                );
              }
            }

            this.orcamentosFiltrados = orcamentosFiltradosTotal;
          }
        );
      } else {
        orcamentosFiltradosTotal = this.orcamentos;
        if (selectedStatus && selectedStatus.length > 0) {
          if (Array.isArray(selectedStatus)) {
            orcamentosFiltradosTotal = orcamentosFiltradosTotal.filter(
              orcamento =>
                selectedStatus.includes(
                  this.getStatus(
                    orcamento.DocStatus,
                    orcamento.CANCELED,
                    orcamento.modificacoesSolicitadas
                  )
                )
            );
          } else {
            orcamentosFiltradosTotal = orcamentosFiltradosTotal.filter(
              orcamento =>
                this.getStatus(
                  orcamento.DocStatus,
                  orcamento.CANCELED,
                  orcamento.modificacoesSolicitadas
                ) === selectedStatus
            );
          }
        }

        this.orcamentosFiltrados = orcamentosFiltradosTotal;
      }
    }
  }

  limparFiltros(): void {
    this.orcamentosFiltrados = [...this.orcamentos];
  }

  filtrarPorCampo(
    orcamento: any,
    campoFiltro: string,
    valorFiltro: string
  ): boolean {
    let campoVendas: string;

    switch (campoFiltro) {
      case 'CardName':
        campoVendas = 'CardName';
        break;
      case 'CardCode':
        campoVendas = 'CardCode';
        break;
      case 'DocEntry':
        campoVendas = 'DocEntry';
        break;

      default:
        campoVendas = campoFiltro;
    }

    if (orcamento[campoVendas]) {
      return orcamento[campoVendas].toString().includes(valorFiltro);
    }

    return false;
  }

  ordenarPorData(): void {
    if (this.ordenacaoCrescente) {
      this.orcamentosFiltrados = this.orcamentos.sort(
        (a, b) => new Date(b.DocDate).getTime() - new Date(a.DocDate).getTime()
      );
    } else {
      this.orcamentosFiltrados = this.orcamentos.sort(
        (a, b) => new Date(a.DocDate).getTime() - new Date(b.DocDate).getTime()
      );
    }

    this.ordenacaoCrescente = !this.ordenacaoCrescente;
  }

  ordenarPorCardName(): void {
    if (this.ordenacaoCrescenteCardName) {
      this.orcamentosFiltrados = this.orcamentos.sort((a, b) =>
        new String(b.CardName).localeCompare(a.CardName)
      );
    } else {
      this.orcamentosFiltrados = this.orcamentos.sort((a, b) =>
        new String(a.CardName).localeCompare(b.CardName)
      );
    }

    this.ordenacaoCrescenteCardName = !this.ordenacaoCrescenteCardName;
  }

  ordenarPorDocNum(): void {
    if (this.ordenacaoCrescenteDocNum) {
      this.orcamentosFiltrados = this.orcamentos.sort(
        (a, b) => a.DocNum - b.DocNum
      );
    } else {
      this.orcamentosFiltrados = this.orcamentos.sort(
        (a, b) => b.DocNum - a.DocNum
      );
    }

    this.ordenacaoCrescenteDocNum = !this.ordenacaoCrescenteDocNum;
  }

  recargarOrcamentos(): void {
    this.loading = true;

    if (this.isClient) {
      this.carregarPedidos();
    } else if (this.isComercial) {
      this.carregarVendedor();
    } else if (this.isAdmin) {
      this.carregarOrcamentosAdmin();
    } else {
      this.loading = false;
    }
  }

  getStatus(
    status: string,
    CANCELED: string,
    modificacoesSolicitadas: string
  ): string {
    if (status === 'C') {
      if (CANCELED === 'Y') {
        return 'Cancelado';
      } else if (CANCELED === 'N') {
        return 'Aprovado';
      } else {
        return 'Desconhecido';
      }
    } else if (status === 'O') {
      if (modificacoesSolicitadas === 'M') {
        return 'Modificações Solicitadas Pendentes';
      } else if (
        modificacoesSolicitadas === 'Y' ||
        modificacoesSolicitadas === 'P'
      ) {
        return 'Pendente de Aprovação';
      } else {
        return 'Pendente de Aprovação';
      }
    } else {
      return 'Desconhecido';
    }
  }

  getStatusColor(
    status: string,
    CANCELED: string,
    modificacoesSolicitadas: string
  ): string {
    if (status === 'C') {
      if (CANCELED === 'Y') {
        return 'red';
      } else if (CANCELED === 'N') {
        return 'green';
      } else {
        return 'gray';
      }
    } else if (status === 'O') {
      if (modificacoesSolicitadas === 'M') {
        return 'orange';
      } else if (
        modificacoesSolicitadas === 'Y' ||
        modificacoesSolicitadas === 'P'
      ) {
        return 'blue';
      } else {
        return 'blue';
      }
    }
  }

  filtrarPorStatus(status: string): void {
    this.statusSelecionado = status;
    if (this.statusSelecionado === '') {
      this.orcamentosFiltrados = this.orcamentos;
    } else {
      this.orcamentosFiltrados = this.orcamentos.filter(
        orcamento =>
          this.getStatus(
            orcamento.DocStatus,
            orcamento.CANCELED,
            orcamento.modificacoesSolicitadas
          ) === this.statusSelecionado
      );
    }
  }

  /**
   * GetIcon para item da linha
   * @param status
   * @param CANCELED
   * @param modificacoesSolicitadas
   * @returns
   */
  getStatusIcon(
    status: string,
    CANCELED: string,
    modificacoesSolicitadas: string
  ): string {
    if (status === 'C') {
      return CANCELED === 'Y' ? 'cancel' : 'check_circle';
    } else if (status === 'O') {
      if (modificacoesSolicitadas === 'M') {
        return 'warning';
      } else if (
        modificacoesSolicitadas === 'Y' ||
        modificacoesSolicitadas === 'P'
      ) {
        return 'hourglass_empty';
      } else {
        return 'help'; // Ícone para status desconhecido
      }
    }
    return 'help';
  }

  /**
   * GetClass para linha de status
   */
  getStatusClass(
    status: string,
    CANCELED: string,
    modificacoesSolicitadas: string
  ): string {
    if (status === 'C') {
      return CANCELED === 'Y' ? 'status-cancelado' : 'status-aprovado';
    } else if (status === 'O') {
      if (modificacoesSolicitadas === 'M') {
        return 'status-modificado';
      } else if (
        modificacoesSolicitadas === 'Y' ||
        modificacoesSolicitadas === 'P'
      ) {
        return 'status-pendente';
      } else {
        return 'status-desconhecido';
      }
    }
    return 'status-desconhecido';
  }

  /**
   * Carregamento scrool
   * @returns
   */
  onScroll(): void {
    if (!this.scrollContainer || !this.scrollContainer.nativeElement) return;
    if (this.isLoadingMore || this.allDataLoaded) return;

    const container = this.scrollContainer.nativeElement;
    const bottomPosition = container.scrollTop + container.clientHeight;

    if (bottomPosition >= container.scrollHeight - 10) {
      this.fetchMoreSales();
    }
  }

  /**
   * Lista de orçamentos para carregamento scrool
   * @returns
   */
  fetchMoreSales(): void {
    if (this.isLoadingMore || this.allDataLoaded) return;

    this.isLoadingMore = true;
    this.isLoading = true;

    this.orcamentosService
      .carregarOrcamentosPages(this.currentPage, this.limit)
      .subscribe((orcamentos: any[]) => {
        if (orcamentos.length === 0) {
          this.allDataLoaded = true;
        } else {
          this.orcamentos = [...this.orcamentos, ...orcamentos];

          if (this.orcamentosFiltrados.length > 0) {
            this.orcamentosFiltrados = [...this.orcamentos];
          }

          this.currentPage++;
        }

        this.isLoadingMore = false;
        this.isLoading = false;
      });
  }

  downlodPdfSalesBudget(docEntry: number) {
    this.isLoading = true;
    this.orcamentosService.downlodPdfSalesBudget(docEntry).subscribe({
      next: blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `orcamento-${docEntry}.pdf`;
        a.click();
        window.URL.revokeObjectURL(url);
      },
      error: err => {
        console.error('Erro ao baixar PDF:', err);
      },
      complete: () => {
        this.isLoading = false; // 🔥 SOMENTE QUANDO TERMINAR
      },
    });
  }

  onDownloadOrcamento(event: MouseEvent, docEntry: number): void {
    event.preventDefault();
    event.stopPropagation(); // Impede que o clique "suba" para o card
    this.downlodPdfSalesBudget(docEntry);
  }
}
