<link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css" />
<div class="mb-2 w-full">
    <div *ngIf="isLoading" class="progress-bar-container">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="pt-4 flex justify-center w-full">
        <div class="rounded-lg shadow-2xl bg-card w-full mx-8 px-4 p-4">
            <div class="home">
                <div *ngIf="isMobile" class="header">
                    <div
                        class="flex pr-5 md:pr-0 items-center font-sans text-3xl text-neutral-500 font-bold">
                        <i class="fas fa-shopping-cart mr-3 text-green-800"></i>
                        Orçamento - Contação de Venda
                    </div>

                    <div class="buttons-container">
                        <button
                            [matTooltip]="'Voltar'"
                            mat-flat-button=""
                            class="button filter-button bg-gray-100 hover:bg-gray-200 text-black"
                            routerLink="/sales">
                            <i class="fas fa-arrow-left mr-2"></i>
                        </button>

                        <!-- Botão de Filtrar -->
                        <button
                            [matTooltip]="'Filtrar orçamentos'"
                            mat-flat-button=""
                            class="button filter-button bg-green-700 hover:bg-green-800 text-white"
                            (click)="searchDialog()">
                            <i class="fa-solid fa-filter"></i>
                        </button>

                        <!-- Botão de Limpar Filtros -->
                        <button
                            [matTooltip]="'Limpar filtros'"
                            mat-flat-button
                            class="button clear-button bg-gray-500 hover:bg-gray-600 text-white"
                            (click)="limparFiltros()">
                            <i class="fas fa-times mr-2"></i>
                        </button>

                        <!-- Botão de Nova Venda -->
                        <button
                            [matTooltip]="'Criar orçamentos'"
                            mat-flat-button=""
                            *ngIf="isComercial || isAdmin"
                            class="button new-sale-button bg-orange-500 hover:bg-orange-600 text-white"
                            (click)="createSalesOrder()">
                            <i class="fas fa-plus mr-2"></i>
                        </button>
                    </div>
                </div>
                <div *ngIf="!isMobile" class="header">
                    <div
                        class="flex pr-5 md:pr-0 items-center font-sans text-3xl text-neutral-500 font-bold">
                        <i class="fas fa-shopping-cart mr-3 text-green-800"></i>
                        Orçamento - Contação de Venda
                    </div>

                    <div class="buttons-container">
                        <button
                            [matTooltip]="'Voltar'"
                            mat-flat-button=""
                            class="button filter-button bg-gray-100 hover:bg-gray-200 text-black"
                            routerLink="/sales">
                            <i class="fas fa-arrow-left mr-2"></i>
                            <span>Voltar</span>
                        </button>

                        <!-- Botão de Filtrar -->
                        <button
                            [matTooltip]="'Filtrar orçamentos'"
                            mat-flat-button=""
                            class="button filter-button bg-green-700 hover:bg-green-800 text-white"
                            (click)="searchDialog()">
                            <mat-icon
                                class="icon-size-5"
                                matPrefix
                                [svgIcon]="'heroicons_solid:search'"></mat-icon>
                            <span>Filtrar</span>
                        </button>

                        <!-- Botão de Limpar Filtros -->
                        <button
                            [matTooltip]="'Limpar orçamentos'"
                            mat-flat-button
                            class="button clear-button bg-gray-500 hover:bg-gray-600 text-white"
                            (click)="limparFiltros()">
                            <i class="fas fa-times mr-2"></i>
                            <span>Limpar Filtros</span>
                        </button>

                        <!-- Botão de Nova Venda -->
                        <button
                            [matTooltip]="'Criar orçamentos'"
                            mat-flat-button=""
                            *ngIf="isComercial || isAdmin"
                            class="button new-sale-button bg-orange-500 hover:bg-orange-600 text-white"
                            (click)="createSalesOrder()">
                            <i class="fas fa-plus mr-2"></i>
                            <span>Nova Venda</span>
                        </button>
                    </div>
                </div>
                <div
                    *ngIf="isMobile"
                    class="flex flex-col mt-3 gap-2 w-full -mb-3">
                    <!-- Searchbar -->
                    <div class="pl-2 relative w-full">
                        <input
                            type="text"
                            class="w-full px-4 py-2 focus:outline-none focus:border-orange-500 focus:ring-0 bg-white border border-gray-300 rounded-lg mb-1"
                            placeholder="Informe o Cliente"
                            [formControl]="orcamentoControl"
                            (input)="filtrarOrcamentosPorCliente()" />
                    </div>

                    <!-- Botões ocupando toda a largura -->
                    <div class="flex flex-col gap-2 w-full">
                        <button
                            class="button bg-gray-200 hover:bg-gray-300 text-black px-6 py-2 rounded-lg flex items-center gap-2 w-full min-w-[350px] justify-center"
                            (click)="ordenarPorCardName()">
                            <mat-icon class="mat-icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 -960 960 960"
                                    width="24px"
                                    fill="black">
                                    <path
                                        d="M480-120 320-280l56-56 64 63v-414l-64 63-56-56 160-160 160 160-56 57-64-64v414l64-63 56 56-160 160Z" />
                                </svg>
                            </mat-icon>
                            Cliente
                        </button>

                        <button
                            class="button bg-gray-200 hover:bg-gray-300 text-black px-6 py-2 rounded-lg flex items-center gap-2 w-full min-w-[350px] justify-center"
                            (click)="ordenarPorData()">
                            <mat-icon class="mat-icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 -960 960 960"
                                    width="24px"
                                    fill="black">
                                    <path
                                        d="M480-120 320-280l56-56 64 63v-414l-64 63-56-56 160-160 160 160-56 57-64-64v414l64-63 56 56-160 160Z" />
                                </svg>
                            </mat-icon>
                            Data
                        </button>

                        <button
                            class="button bg-gray-200 hover:bg-gray-300 text-black px-6 py-2 rounded-lg flex items-center gap-2 w-full min-w-[350px] justify-center"
                            (click)="ordenarPorDocNum()">
                            <mat-icon class="mat-icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 -960 960 960"
                                    width="24px"
                                    fill="black">
                                    <path
                                        d="M480-120 320-280l56-56 64 63v-414l-64 63-56-56 160-160 160 160-56 57-64-64v414l64-63 56 56-160 160Z" />
                                </svg>
                            </mat-icon>
                            Nº Doc
                        </button>

                        <!-- Botão de Status -->
                        <button
                            mat-button
                            [matMenuTriggerFor]="statusMenu"
                            class="bg-gray-200 hover:bg-gray-300 text-black px-4 py-2 rounded-lg flex items-center gap-2 justify-center w-full">
                            <mat-icon class="text-black">sort</mat-icon>
                            <span>Status</span>
                        </button>

                        <mat-menu #statusMenu="matMenu">
                            <button
                                mat-menu-item
                                (click)="filtrarPorStatus('Cancelado')">
                                Cancelado
                            </button>
                            <button
                                mat-menu-item
                                (click)="filtrarPorStatus('Aprovado')">
                                Aprovado
                            </button>
                            <button
                                mat-menu-item
                                (click)="
                                    filtrarPorStatus(
                                        'Modificações Solicitadas Pendentes'
                                    )
                                ">
                                Modificações Solicitadas Pendentes
                            </button>
                            <button
                                mat-menu-item
                                (click)="
                                    filtrarPorStatus('Pendente de Aprovação')
                                ">
                                Pendente de Aprovação
                            </button>
                            <button
                                mat-menu-item
                                (click)="filtrarPorStatus('Desconhecido')">
                                Desconhecido
                            </button>
                            <button
                                mat-menu-item
                                (click)="filtrarPorStatus('')">
                                Mostrar Todos
                            </button>
                        </mat-menu>
                    </div>
                </div>
                <div *ngIf="!isMobile" class="flex flex-col mt-3">
                    <div
                        class="content flex items-center gap-3 w-full"
                        *ngIf="!isClient">
                        <!-- Searchbar -->
                        <div class="pl-2 relative w-full max-w-md">
                            <input
                                type="text"
                                class="w-full px-4 py-2 focus:outline-none focus:border-orange-500 focus:ring-0 bg-white border border-gray-300 rounded-lg"
                                placeholder="Informe o Cliente"
                                [formControl]="orcamentoControl"
                                (input)="filtrarOrcamentosPorCliente()" />
                        </div>

                        <!-- Botões lado a lado -->
                        <div class="flex gap-2 items-center">
                            <button
                                class="button bg-gray-200 hover:bg-gray-300 text-black px-6 py-2 rounded-lg flex items-center gap-2 min-w-[140px]"
                                (click)="ordenarPorCardName()">
                                <mat-icon class="mat-icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="24px"
                                        viewBox="0 -960 960 960"
                                        width="24px"
                                        fill="black">
                                        <path
                                            d="M480-120 320-280l56-56 64 63v-414l-64 63-56-56 160-160 160 160-56 57-64-64v414l64-63 56 56-160 160Z" />
                                    </svg>
                                </mat-icon>
                                Cliente
                            </button>

                            <button
                                class="button bg-gray-200 hover:bg-gray-300 text-black px-6 py-2 rounded-lg flex items-center gap-2 min-w-[140px]"
                                (click)="ordenarPorData()">
                                <mat-icon class="mat-icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="24px"
                                        viewBox="0 -960 960 960"
                                        width="24px"
                                        fill="black">
                                        <path
                                            d="M480-120 320-280l56-56 64 63v-414l-64 63-56-56 160-160 160 160-56 57-64-64v414l64-63 56 56-160 160Z" />
                                    </svg>
                                </mat-icon>
                                Data
                            </button>

                            <button
                                class="button bg-gray-200 hover:bg-gray-300 text-black px-6 py-2 rounded-lg flex items-center gap-2 min-w-[140px]"
                                (click)="ordenarPorDocNum()">
                                <mat-icon class="mat-icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="24px"
                                        viewBox="0 -960 960 960"
                                        width="24px"
                                        fill="black">
                                        <path
                                            d="M480-120 320-280l56-56 64 63v-414l-64 63-56-56 160-160 160 160-56 57-64-64v414l64-63 56 56-160 160Z" />
                                    </svg>
                                </mat-icon>
                                Nº Doc
                            </button>

                            <div class="tab-arrow">
                                <button
                                    mat-button
                                    [matMenuTriggerFor]="statusMenu"
                                    class="bg-gray-200 hover:bg-gray-300 text-black px-4 py-2 rounded-lg flex items-center gap-2">
                                    <mat-icon class="text-black">sort</mat-icon>
                                    <span>Status</span>
                                </button>

                                <mat-menu #statusMenu="matMenu">
                                    <button
                                        mat-menu-item
                                        (click)="filtrarPorStatus('Cancelado')">
                                        Cancelado
                                    </button>
                                    <button
                                        mat-menu-item
                                        (click)="filtrarPorStatus('Aprovado')">
                                        Aprovado
                                    </button>
                                    <button
                                        mat-menu-item
                                        (click)="
                                            filtrarPorStatus(
                                                'Modificações Solicitadas Pendentes'
                                            )
                                        ">
                                        Modificações Solicitadas Pendentes
                                    </button>
                                    <button
                                        mat-menu-item
                                        (click)="
                                            filtrarPorStatus(
                                                'Pendente de Aprovação'
                                            )
                                        ">
                                        Pendente de Aprovação
                                    </button>
                                    <button
                                        mat-menu-item
                                        (click)="
                                            filtrarPorStatus('Desconhecido')
                                        ">
                                        Desconhecido
                                    </button>
                                    <button
                                        mat-menu-item
                                        (click)="filtrarPorStatus('')">
                                        Mostrar Todos
                                    </button>
                                </mat-menu>
                            </div>
                        </div>
                    </div>
                </div>

                <hr *ngIf="isMobile" class="mb-3" />
                <hr *ngIf="!isMobile" class="mb-2" />

                <div
                    #scrollContainer
                    class="orcamentos-container mt-2 mb-4 overflow-y-auto max-h-[600px] pl-3 pr-3"
                    (scroll)="onScroll()">
                    <div
                        class="orcamento-card"
                        *ngFor="let orcamento of orcamentosFiltrados"
                        [ngClass]="
                            getStatusClass(
                                orcamento.DocStatus,
                                orcamento.CANCELED,
                                orcamento.modificacoesSolicitadas
                            )
                        "
                        (click)="selecionarLinha(orcamento)">
                        <!-- Ícone e Número do Cliente -->
                        <div class="orcamento-info">
                            <div class="orcamento-header">
                                <mat-icon class="status-icon">
                                    {{
                                        getStatusIcon(
                                            orcamento.DocStatus,
                                            orcamento.CANCELED,
                                            orcamento.modificacoesSolicitadas
                                        )
                                    }}
                                </mat-icon>
                                <span class="doc-num"
                                    ># {{ orcamento.CardCode }}</span
                                >
                            </div>

                            <!-- Nome do Cliente e Número do Orçamento -->
                            <div class="orcamento-detalhes">
                                <strong>{{ orcamento.CardName }}</strong>
                                <div class="pedido">
                                    Nº {{ orcamento.DocNum }}
                                </div>
                            </div>
                        </div>

                        <!-- Data do Orçamento e Status -->
                        <div class="orcamento-status">
                            <div class="data-orcamento">
                                {{ orcamento.DocDate | date : 'dd/MM/yyyy' }}
                            </div>
                            <div
                                class="status-text"
                                [ngClass]="
                                    getStatusClass(
                                        orcamento.DocStatus,
                                        orcamento.CANCELED,
                                        orcamento.modificacoesSolicitadas
                                    )
                                ">
                                {{
                                    getStatus(
                                        orcamento.DocStatus,
                                        orcamento.CANCELED,
                                        orcamento.modificacoesSolicitadas
                                    )
                                }}
                            </div>
                            <button
                                [matTooltip]="'Baixar PDF do Orçamento'"
                                class="bg-green-100 hover:bg-green-400 rounded-full hover:text-white text-gray-500 p-3"
                                (click)="
                                    onDownloadOrcamento(
                                        $event,
                                        orcamento.DocEntry
                                    );
                                    $event.stopPropagation();
                                    $event.preventDefault()
                                ">
                                <i class="fa-solid fa-file-pdf"></i> &nbsp;
                                Baixar PDF
                            </button>
                        </div>
                    </div>

                    <div *ngIf="isLoadingMore" class="loading">
                        Carregando mais orçamentos...
                    </div>

                    <div
                        *ngIf="orcamentosFiltrados.length === 0"
                        class="no-data">
                        Nenhum Orçamento disponível
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
