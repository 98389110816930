import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip'; 
import { StockComponent } from './stock/stock.component';
import { StockFormComponent } from './stock-form/stock-form.component';
import { FilterDialogComponent } from './filter-dialog/filter-dialog.component';

// Importação de módulos do Angular Material
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
// Se estiver usando datas nativas, importe também:
import { MatNativeDateModule } from '@angular/material/core';

@NgModule({
  declarations: [
    StockComponent,
    StockFormComponent,
    FilterDialogComponent  // Adicione o componente de diálogo aqui
  ],
  imports: [ 
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatTooltipModule,  
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  exports: [
    StockComponent,
    StockFormComponent
  ]
})
export class StockModule { }