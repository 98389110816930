import { Component, OnInit, OnDestroy } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { APP_CONSTANTS_SESSION } from 'app/core/constants/app.constants';
import { SessionAlertComponent } from 'app/shared/components/toast-session/session-alert.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
@Component({
  selector: 'app-session-timer',
  templateUrl: './session-timer.component.html',
  styleUrls: ['./session-timer.component.scss'],
})
export class SessionTimerComponent implements OnInit, OnDestroy {
  timeLeft: number = 0;
  private timerSubscription: Subscription;
  private sessionRenewedSubscription: Subscription;
  showTimer: boolean = false;
  private alertTriggered: boolean = false;

  constructor(
    private _authService: AuthService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  /**
   * Inicialização do componente
   */
  ngOnInit(): void {
    this.updateTimeLeft();
    this.timerSubscription = interval(1000).subscribe(() => {
      this.updateTimeLeft();
    });

    this.sessionRenewedSubscription =
      this._authService.sessionRenewed$.subscribe(renewed => {
        if (renewed) {
          //console.log('🕒 [DEBUG] Relógio atualizado após renovação da sessão!');
          this.updateTimeLeft();
        }
      });
  }

  /**
   * Atualiza tempo restante e relogio
   */
  /*updateTimeLeft(): void {
    const loginTime = parseInt(
      localStorage.getItem(APP_CONSTANTS_SESSION.LOGIN_TIME_KEY) || '0',
      10
    );
    const now = Date.now();
    const expirationTime = loginTime + APP_CONSTANTS_SESSION.SESSION_DURATION;

    this.timeLeft = Math.max(0, Math.floor((expirationTime - now) / 1000));

    this.showTimer = this.timeLeft <= APP_CONSTANTS_SESSION.CLOCK_SHOW;

    if (this.timeLeft <= 0) {
      console.log('⏳ Sessão expirada! Realizando logout...');
      this._authService.signOut().subscribe();
      localStorage.removeItem('sessionWarningToast');
      this.router.navigate(['/sign-in']);
    }

    if (this.timeLeft === 60 && !this.alertTriggered) {
      this._authService.triggerSessionWarning();
      this.alertTriggered = true;
    }

    if (this.timeLeft < 60 && !localStorage.getItem('sessionWarningToast')) {
      localStorage.setItem('sessionWarningToast', 'true');
      this._authService.triggerSessionWarning();
      this.alertTriggered = true;
    }
  }*/
  updateTimeLeft(): void {
    const loginTime = parseInt(
      localStorage.getItem(APP_CONSTANTS_SESSION.LOGIN_TIME_KEY) || '0',
      10
    );
    const now = Date.now();
    const expirationTime = loginTime + APP_CONSTANTS_SESSION.SESSION_DURATION;

    this.timeLeft = Math.max(0, Math.floor((expirationTime - now) / 1000));
    this.showTimer = this.timeLeft <= APP_CONSTANTS_SESSION.CLOCK_SHOW;

    // 🔹 Antes de deslogar, verifica se a sessão foi renovada
    if (this.timeLeft <= 0) {
      const lastRenewTime = parseInt(
        localStorage.getItem(APP_CONSTANTS_SESSION.LOGIN_TIME_KEY) || '0',
        10
      );
      const newExpirationTime =
        lastRenewTime + APP_CONSTANTS_SESSION.SESSION_DURATION;

      if (newExpirationTime > now) {
        console.log('🔄 Sessão foi renovada recentemente. NÃO deslogando.');
        return; // 🚀 Evita logout indevido
      }

      console.log('⏳ Sessão expirada! Realizando logout...');
      this._authService.signOut().subscribe();
      localStorage.removeItem('sessionWarningToast');
      this.router.navigate(['/sign-in']);
    }

    if (this.timeLeft === 60 && !this.alertTriggered) {
      this._authService.triggerSessionWarning();
      this.alertTriggered = true;
    }

    if (this.timeLeft < 60 && !localStorage.getItem('sessionWarningToast')) {
      localStorage.setItem('sessionWarningToast', 'true');
      this._authService.triggerSessionWarning();
      this.alertTriggered = true;
    }
  }

  /**
   * Alerta Toast
   */
  showSessionAlert(): void {
    this.snackBar.openFromComponent(SessionAlertComponent, {
      duration: APP_CONSTANTS_SESSION.WARNING_TIME,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'custom-snackbar-container',
    });
  }

  /**
   * Formatador para mostrar o tempo
   */
  get formattedTimeLeft(): string {
    const minutes = Math.floor(this.timeLeft / 60);
    const seconds = this.timeLeft % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
      2,
      '0'
    )}`;
  }

  /**
   * Para destruição do componente
   */
  ngOnDestroy(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }

    if (this.sessionRenewedSubscription) {
      this.sessionRenewedSubscription.unsubscribe();
    }
  }

  /**
   * Definição de cor para o alerta
   * @returns
   */
  getAlertClass(): string {
    return this.timeLeft > 60 ? 'safe' : 'warning';
  }
}
