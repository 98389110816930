<!-- eslint-disable prettier/prettier -->
<!-- Container Principal -->
<div class="flex flex-col custom-container -m-6">
    <!-- Cabeçalho do Orçamento -->
    <div
        class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">
            Atualizar orçamento de Vendas ({{
                codOrcamento || '---'
            }})
        </div>
        <button mat-icon-button (click)="cancel()" [tabIndex]="-1">
            <mat-icon
                class="text-white"
                [matTooltip]="'Sair'"
                [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>

    <!-- Indicador de Carregamento -->
    <div *ngIf="loading" class="loading-overlay">
        <mat-spinner diameter="50" color="accent"></mat-spinner>
    </div>

    <!-- Formulário Principal -->
    <div class="flex-auto overflow-y-auto max-h-[80vh] p-4">
        <!-- Formulário Principal -->
        <div class="mb-2 pl-2">
            <div
                class="flex items-center gap-2 text-2xl font-semibold text-gray-800">
                <mat-icon class="text-primary text-3xl">inventory_2</mat-icon>
                <span>Cabeçalho</span>
            </div>
            <hr class="border-0 h-[0.5px] bg-gray-300 mt-1 mb-2 my-4" />
        </div>

        <div
            *ngIf="camposInvalidosCabecalho.length > 0 && editarAtivo"
            class="text-sm text-red-600 mt-1 ml-3"
            >
            <p>
                <strong>Preencha os campos: </strong>
                {{ camposInvalidosCabecalho.join(", ") }}
            </p>
        </div>

        <!-- ID CLIENTE CLIENTE CPF/CNPJ -->
        <div class="grid grid-cols-12 gap-3 pl-2 mt-1">

            <!-- Cliente -->
            <div class="col-span-6">
                <mat-form-field
                    class="w-full"
                    disabled="true"
                    appearance="outline">
                    <mat-icon
                        class="icon-size-5"
                        matPrefix
                        [svgIcon]="'heroicons_solid:search'"></mat-icon>
                    <mat-label>Cliente</mat-label>
                    <input
                        type="text"
                        disabled="true"
                        [readonly]="true"
                        matInput
                        [formControl]="clienteControl" />
                </mat-form-field>
            </div>

            <!-- Código do Cliente -->
            <div class="col-span-3">
                <mat-form-field
                    class="w-full"
                    disabled="true"
                    appearance="outline">
                    <mat-label>Cod Cliente</mat-label>
                    <input
                        type="text"
                        disabled="true"
                        matInput
                        [formControl]="codClienteControl"
                        [readonly]="true" />
                </mat-form-field>
            </div>

            <!-- CNPJ -->
            <div class="col-span-3">
                <mat-form-field
                    class="w-full"
                    disabled="true"
                    appearance="outline">
                    <mat-label>CPF/CNPJ</mat-label>
                    <input
                        type="text"
                        matInput
                        disabled="true"
                        [readonly]="true"
                        [formControl]="cpfControl" />
                </mat-form-field>
            </div>
        </div>

        <!-- IE FAZENDA TELEFONE END -->
        <div class="grid grid-cols-12 gap-3 pl-2 -mt-3">
            <div class="col-span-3">
                <mat-form-field
                    class="w-full"
                    disabled="true"
                    appearance="outline">
                    <mat-label class="label">Inscrição Estadual</mat-label>
                    <input
                        type="text"
                        disabled="true"
                        [readonly]="true"
                        matInput
                        [formControl]="inscricaoControl" />
                </mat-form-field>
            </div>

            <div class="col-span-3">
                <mat-form-field
                    class="w-full"
                    disabled="true"
                    appearance="outline">
                    <mat-label class="label">Fazenda</mat-label>
                    <input
                        type="text"
                        matInput
                        [formControl]="fazendaControl"
                        disabled="true"
                        [readonly]="true" />
                </mat-form-field>
            </div>

            <div class="col-span-3">
                <mat-form-field
                    class="w-full"
                    disabled="true"
                    appearance="outline">
                    <mat-label>Telefone</mat-label>
                    <input
                        type="text"
                        matInput
                        [formControl]="telefoneControl"
                        disabled="true"
                        [readonly]="true" />
                </mat-form-field>
            </div>

            <div class="col-span-3">
                <mat-form-field
                    class="w-full"
                    disabled="true"
                    appearance="outline">
                    <mat-label class="label">Endereço</mat-label>
                    <input
                        type="text"
                        matInput
                        [formControl]="enderecoControl"
                        disabled="true"
                        [readonly]="true" />
                </mat-form-field>
            </div>
        </div>

        <!-- DT ENTREGA FRETE CD -->
        <div class="grid grid-cols-12 gap-3 pl-2 -mt-3">
            <div class="col-span-3">
                <mat-form-field
                    class="w-full mr-2"
                    appearance="outline">
                    <mat-label>Data de Entrega</mat-label>
                    <input
                        matInput
                        [formControl]="dataVencimentoControl"
                        [matDatepicker]="pickerScheduledE" />
                    <mat-datepicker-toggle
                        matSuffix
                        [for]="pickerScheduledE"
                        [disabled]="!editarAtivo"></mat-datepicker-toggle>
                    <mat-datepicker #pickerScheduledE></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="col-span-3">
                <mat-form-field
                    class="w-full"
                    appearance="outline">
                    <mat-label>Frete</mat-label>
                    <input
                        matInput
                        [formControl]="freteControl"
                        [matAutocomplete]="autoFrete"/>
                    <mat-autocomplete
                        #autoFrete="matAutocomplete"
                        (optionSelected)="selecionarFrete($event)">
                        <mat-option
                            class=""
                            *ngFor="let frete of fretesFiltrados"
                            [value]="frete.DESCRICAO">
                            {{ frete.DESCRICAO }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>

            <div class="col-span-6">
                <mat-form-field
                    class="w-full"
                    appearance="outline">
                    <mat-label>Cd.Entrega</mat-label>
                    <input
                        matInput
                        [formControl]="CdEntregaControl"
                        [matAutocomplete]="autoCdEntrega" />
                    <mat-autocomplete
                        #autoCdEntrega="matAutocomplete"
                        (optionSelected)="selecionarCdEntrega($event)">
                        <mat-option
                            class=""
                            [disabled]="!editarAtivo"
                            *ngFor="let cdEntrega of filteredCdEntrega"
                            [value]="cdEntrega">
                            {{ cdEntrega.BPLName }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>

        <!-- VEND COND PAG MEIO PAG -->
        <div class="grid grid-cols-12 gap-3 pl-2 -mt-3">
            <div class="col-span-3">
                <mat-form-field class="w-full" appearance="outline">
                    <mat-label class="label">Vendedor</mat-label>
                    <input
                        type="text"
                        matInput
                        [formControl]="vendedorFiltroControl"
                        [matAutocomplete]="autoVendedor"
                        placeholder="Digite para buscar..."
                        (focus)="abrirDropdown()"
                        (click)="abrirDropdown()" />
                    <mat-autocomplete
                        #autoVendedor="matAutocomplete"
                        (optionSelected)="selecionarVendedor($event)">
                        <mat-option
                            class=""
                            *ngFor="
                                let vendedor of _vendedoresFiltrados | async
                            "
                            [value]="vendedor.SlpCode">
                            {{ vendedor.SlpName }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>

            <div class="col-span-2">
                <mat-form-field class="w-full" appearance="outline">
                    <mat-label class="label">Condição de Pagamento</mat-label>
                    <input
                        type="text"
                        matInput
                        [formControl]="condicaoPagamentoFiltroControl"
                        [matAutocomplete]="autoCondicao"
                        placeholder="Digite para buscar..."
                        (focus)="abrirDropdownCondicao()"
                        (click)="abrirDropdownCondicao()" />
                    <mat-autocomplete
                        #autoCondicao="matAutocomplete"
                        (optionSelected)="selecionarCondicaoPagamento($event)">
                        <mat-option
                            class=""
                            *ngFor="
                                let condicaopagamento of _condicoesPagamentoFiltradas
                                    | async
                            "
                            [value]="condicaopagamento.GroupNum">
                            {{ condicaopagamento.PymntGroup }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>

            <div class="col-span-2">
                <mat-form-field class="w-full" appearance="outline">
                    <mat-label class="label">Meio de Pagamento</mat-label>
                    <input
                        type="text"
                        matInput
                        [formControl]="meioPagamentoFiltroControl"
                        [matAutocomplete]="autoMeioPagamento"
                        (focus)="abrirDropdownMeioPagamento()"
                        (click)="abrirDropdownMeioPagamento()" />

                    <mat-autocomplete
                        #autoMeioPagamento="matAutocomplete"
                        (optionSelected)="selecionarMeioPagamento($event)">
                        <mat-option
                            class=""
                            *ngFor="
                                let forma of _formaspagamentoFiltradas | async
                            "
                            [value]="forma.FPAGAMENTO">
                            {{ forma.FPAGAMENTO }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="col-span-5">
                <mat-form-field class="w-full" appearance="outline">
                    <mat-label class="label">Observações</mat-label>
                    <input
                        type="text"
                        matInput
                        [formControl]="observacoescadControl"/>
                </mat-form-field>
            </div>
        </div>

        <!-- Seção de Manejo Biológico -->
        <div class="mb-2 pl-2">
            <div class="flex items-center gap-2 text-2xl font-semibold text-gray-800">
            <mat-icon class="text-primary text-3xl">inventory_2</mat-icon>
            <span>Preço - Manejo Biológico</span>
            </div>
            <hr class="border-0 h-[0.5px] bg-gray-300 mt-1 mb-2 my-4" />
        </div>

        <div
            *ngIf="formPriceIniciado && camposInvalidosPrice.length > 0"
            class="text-sm text-red-600 mt-1 ml-3 mb-1"
            >
            <p>
                <strong>Preencha os campos de precificação: </strong>
                {{ camposInvalidosPrice.join(", ") }}
            </p>
        </div>

        <!-- Campos Price de Manejo biologico -->
        <div class="grid grid-cols-12 gap-3 pl-2 mt-1"
        >
            <div class="col-span-2">
            <mat-form-field
                class="mat-form-field-faixa-compra w-full text-sm"
                appearance="outline"
            >
                <mat-label>Faixa de Compra Mínima</mat-label>
                <mat-select
                [formControl]="faixaCompraControl"
                placeholder="Selecione a faixa"
                >
                <mat-option *ngFor="let faixa of faixasDeCompra" [value]="faixa">
                    {{ faixa.description }}
                </mat-option>
                </mat-select>
            </mat-form-field>
            </div>
            <div class="col-span-2">
            <mat-form-field
                class="mat-form-field-qualyfarm w-full text-sm"
                appearance="outline"
            >
                <mat-label>Sanitização</mat-label>
                <mat-select
                [formControl]="sanatizacaoProdutoControl"
                placeholder="Selecione a qualidade"
                >
                <mat-option *ngFor="let tipo of tiposSanitizacao" [value]="tipo">
                    {{ tipo }}
                </mat-option>
                </mat-select>
            </mat-form-field>
            </div>
            <div class="col-span-8"></div>
        </div>

        <!-- Seção de Manejo Biológico -->
        <div *ngIf="dadosCabecalhoValidos" class="mb-2 pl-2">
            <div class="flex items-center gap-2 text-2xl font-semibold text-gray-800">
            <mat-icon class="text-primary text-3xl">inventory_2</mat-icon>
            <span>Novo Item</span>
            </div>
            <hr class="border-0 h-[0.5px] bg-gray-300 mt-1 mb-2 my-4" />
        </div>

         <!-- Seção de seleção de manejo biológico -->
        <div *ngIf="!dadosPriceValidos">
            <span class="text-red-500 pl-3 mb-2"
            >Para incluir um novo produto, selecione uma PRECIFICAÇÃO de Manejo
            Bioógico</span
            >
        </div>

        <!-- Seção de novosinclusão de novos produtos -->
    <div *ngIf="dadosPriceValidos">
        <div
          *ngIf="formProdutoIniciado && camposInvalidosProduto.length > 0"
          class="text-sm text-red-600 mt-1 ml-3 mb-2"
        >
          <p>
            <strong>Preencha os campos: </strong>
            {{ camposInvalidosProduto.join(", ") }}
          </p>
        </div>

        <div class="itens-scroll pl-3 pr-3" #itensScroll>
          <div class="itens">
            <div class="linha header p-2 rounded-lg">
              <div class="col col-nitem">Nº Item</div>
              <div class="col col-descricao">Descrição do Produto</div>
              <div class="col col-quantidadeAplicacoes">Nº Aplicação.</div>
              <div class="col col-doseBiologica">Dose Biológica (L)</div>
              <div class="col col-numeroHectares">Nº Ha</div>
              <div class="col col-nrKit">Nº Kit</div>
              <div class="col col-custoKgL">Custo Kg/L R$</div>
              <div class="col col-custoTotalHaBiologico">
                Custo Total/Ha Biológico R$
              </div>
              <div class="col col-precoUnitario">Preço Unitário R$</div>
              <div class="col col-quantidade">Quantidade</div>
              <div class="col col-desconto">Desconto %</div>
              <div class="col col-codVenda">Cód. Venda</div>
              <div class="col col-aplicacao">Aplicação</div>
              <div class="col col-cultura">Cultura</div>
              <div class="col col-dataEntrega">Data de Entrega</div>
              <div class="col col-numeroOC">Nº da OC</div>
              <div class="col col-valorTotal">Valor Total R$</div>
            </div>

            <div class="linha">
              <div class="col col-nitem">
                <mat-form-field class="mat-form-field-nitem" appearance="outline">
                  <mat-label class="pr-1">NItem * &nbsp;</mat-label>
                  <input
                    type="text"
                    (blur)="validarProductItem()"
                    matInput
                    [formControl]="nItemControl"
                    [matAutocomplete]="autoNItem"
                  />
                  <mat-autocomplete
                    #autoNItem="matAutocomplete"
                    class="custom-autocomplete"
                    [panelWidth]="'auto'"
                    (optionSelected)="selecionarProdutoNovoItem($event)"
                  >
                    <mat-option
                      *ngFor="let item of filteredItems | async"
                      [value]="item.ItemCode"
                    >
                      {{ item.ItemCode }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>

              <div class="col col-descricao">
                <mat-form-field
                  class="mat-form-field-descricao"
                  appearance="outline"
                >
                  <mat-label class="pr-1"
                    >Descrição do Produto * &nbsp;</mat-label
                  >
                  <input
                    type="text"
                    (blur)="validarProductItem()"
                    matInput
                    [formControl]="descricaoProdutoControl"
                    [matAutocomplete]="autoDescricaoProduto"
                  />
                  <mat-autocomplete
                    #autoDescricaoProduto="matAutocomplete"
                    (optionSelected)="selecionarProdutoNovoItem($event)"
                  >
                    <mat-option
                      *ngFor="let item of filteredItemDescriptions | async"
                      [value]="item.ItemName"
                    >
                      {{ item.ItemName }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>

              <div class="col col-quantidadeAplicacoes">
                <mat-form-field
                  class="mat-form-field-quantidadeAplicacoes"
                  appearance="outline"
                >
                  <mat-label class="pr-1"
                    >Quantidade de Aplicações * &nbsp;</mat-label
                  >
                  <input
                    type="number"
                    (blur)="validarProductItem()"
                    matInput
                    [formControl]="quantidadeAplicacoesControl"
                    placeholder="Ex: 10"
                  />
                </mat-form-field>
              </div>

              <div class="col col-doseBiologica">
                <mat-form-field
                  class="mat-form-field-doseBiologica"
                  appearance="outline"
                >
                  <mat-label class="pr-1">Dose Biológica (L) * &nbsp;</mat-label>
                  <input
                    type="number"
                    (blur)="validarProductItem()"
                    matInput
                    [formControl]="doseBiologicaControl"
                    placeholder="Ex: 1.5"
                  />
                </mat-form-field>
              </div>

              <div class="col col-numeroHectares">
                <mat-form-field
                  class="mat-form-field-numeroHectares"
                  appearance="outline"
                >
                  <mat-label class="pr-1"
                    >Número de Hectares (Ha) * &nbsp;</mat-label
                  >
                  <input
                    type="number"
                    (blur)="validarProductItem()"
                    matInput
                    [formControl]="numeroHectaresControl"
                    (ngModelChange)="atualizarCalculos()"
                    placeholder="Ex: 100"
                  />
                </mat-form-field>
              </div>

              <div class="col col-nrKit">
                <mat-form-field class="mat-form-field-nrKit" appearance="outline">
                  <mat-label class="pr-1">Nr Kit</mat-label>
                  <input
                    type="number"
                    (blur)="validarProductItem()"
                    matInput
                    [formControl]="nrKitControl"
                    readonly
                  />
                </mat-form-field>
              </div>

              <div class="col col-custoKgL">
                <mat-form-field
                  class="mat-form-field-custoKgL"
                  appearance="outline"
                >
                  <mat-label class="pr-1">Custo Kg/L</mat-label>
                  <input
                    type="text"
                    (blur)="validarProductItem()"
                    matInput
                    [formControl]="custoKgLControl"
                    readonly
                  />
                </mat-form-field>
              </div>

              <div class="col col-custoTotalHaBiologico">
                <mat-form-field
                  class="mat-form-field-custoTotalHaBiologico"
                  appearance="outline"
                >
                  <mat-label class="pr-1">Custo Total/Ha Biológico</mat-label>
                  <input
                    type="text"
                    (blur)="validarProductItem()"
                    matInput
                    [formControl]="custoTotalHaBiologicoControl"
                    (ngModelChange)="atualizarCalculos()"
                    readonly
                  />
                </mat-form-field>
              </div>

              <div class="col col-precoUnitario">
                <mat-form-field
                  class="mat-form-field-precoUnitario"
                  appearance="outline"
                >
                  <mat-label class="pr-1">Preço Unitário * &nbsp;</mat-label>
                  <input
                    type="text"
                    (blur)="validarProductItem()"
                    matInput
                    [formControl]="precoUnitarioControl"
                    (ngModelChange)="recalcularNovoValorTotal()"
                  />
                </mat-form-field>
              </div>

              <div class="col col-quantidade">
                <mat-form-field
                  class="mat-form-field-quantidade"
                  appearance="outline"
                >
                  <mat-label class="pr-1">Quantidade * &nbsp;</mat-label>
                  <input
                    type="number"
                    (blur)="validarProductItem()"
                    matInput
                    [formControl]="quantidadeControl"
                    (ngModelChange)="recalcularNovoValorTotal()"
                  />
                </mat-form-field>
              </div>

              <div class="col col-desconto">
                <mat-form-field
                  class="mat-form-field-desconto"
                  appearance="outline"
                >
                  <mat-label class="pr-1">Desconto * &nbsp;</mat-label>
                  <input
                    type="number"
                    (blur)="validarProductItem()"
                    matInput
                    [formControl]="descontoControl"
                  />
                </mat-form-field>
              </div>

              <div class="col col-codVenda">
                <mat-form-field
                  class="mat-form-field-codVenda"
                  appearance="outline"
                >
                  <mat-label class="pr-1">Cód. Venda</mat-label>
                  <mat-select
                    [formControl]="codControl"
                    (blur)="validarProductItem()"
                    (selectionChange)="gerarCodPedido($event)"
                  >
                    <mat-option value="212 VFU">VFU</mat-option>
                    <mat-option value="222 VDI">VDI</mat-option>
                    <mat-option value="210 BNF">BNF</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col col-aplicacao">
                <mat-form-field
                  class="mat-form-field-aplicacao"
                  appearance="outline"
                >
                  <mat-label class="pr-1">Aplicação * &nbsp;</mat-label>
                  <mat-select
                    (blur)="validarProductItem()"
                    [formControl]="aplicacaoControl"
                  >
                    <mat-option value="OnFarm">On Farm</mat-option>
                    <mat-option value="OnDemand">On Demand</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col col-cultura">
                <mat-form-field
                  class="mat-form-field-cultura"
                  appearance="outline"
                >
                  <mat-label class="pr-1">Cultura * &nbsp;</mat-label>
                  <input
                    type="text"
                    (blur)="validarProductItem()"
                    matInput
                    [formControl]="culturaControl"
                    [matAutocomplete]="autoCultura"
                  />
                  <mat-autocomplete #autoCultura="matAutocomplete">
                    <mat-option
                      *ngFor="let cultura of filteredCulturas"
                      [value]="cultura.Name"
                    >
                      {{ cultura.Name }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>

              <div class="col col-dataEntrega">
                <mat-form-field
                  class="mat-form-field-dataEntrega"
                  appearance="outline"
                >
                  <mat-label class="pr-1">Data de Entrega * &nbsp;</mat-label>
                  <input
                    matInput
                    (blur)="validarProductItem()"
                    [formControl]="dataEntregaControl"
                    [matDatepicker]="pickerScheduledE"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="pickerScheduledE"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #pickerScheduledE></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="col col-numeroOC">
                <mat-form-field
                  class="mat-form-field-numeroOC"
                  appearance="outline"
                >
                  <mat-label class="pr-1">Número OC</mat-label>
                  <input
                    type="number"
                    (blur)="validarProductItem()"
                    matInput
                    [formControl]="numeroOCControl"
                  />
                </mat-form-field>
              </div>

              <div class="col col-valorTotal">
                <mat-form-field
                  class="mat-form-field-valorTotal"
                  appearance="outline"
                >
                  <mat-label class="pr-1">Valor Total</mat-label>
                  <input
                    type="text"
                    (blur)="validDataProductItem()"
                    matInput
                    [value]="newValorTotalControl.value"
                    readonly
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>

        <div class="p-1 flex flex-wrap gap-2 w-full justify-end mt-2 mb-1 pr-2">
          <button
            mat-flat-button=""
            [ngClass]="{
              'bg-orange-500 hover:bg-orange-600': validDataProductItem,
              'bg-gray-500': !validDataProductItem
            }"
            class="flex-1 max-w-[200px] mt-2 font-bold py-2 px-3 rounded-full transition-all duration-300 flex items-center justify-center ml-3 text-white"
            [disabled]="!validDataProductItem"
            ng-reflect-color="primary"
            (click)="adicionarNovoItem()"
          >
            <span class="text-white"
              ><i class="fa-solid fa-plus text-white"></i> &nbsp; {{ indiceItemEditando == null ? 'Adicionar Item' : 'Atualizando Item' }}</span
            >
          </button>
        </div>
    </div>

        <!-- Seção de novos produtos -->
        <div
            *ngIf="itensSemKit.length > 0 && dadosCabecalhoValidos"
            class="mb-2 pl-2"
        >
            <div class="flex items-center gap-2 text-2xl font-semibold text-gray-800">
            <mat-icon class="text-primary text-3xl">inventory_2</mat-icon>
            <span>Items</span>
            </div>
            <hr class="border-0 h-[0.5px] bg-gray-300 mt-1 mb-2 my-4" />
        </div>

        <div class="itens-container mt-2 mb-4 w-full mr-5 pl-2 pr-2">
            <div
                class="item-card border-l-8 border-green-700 pl-8 pr-7 mt-2"
                *ngFor="let item of dadosTabela; let i = index"
                [ngClass]="{ selected: linhasSelecionadas === item }"
            >
                <!-- Linha 1 -->
                <div class="linha-row mt-2 mb-2">
                <span class="left text-1xl"
                    ><strong>Item {{ i + 1 }}</strong></span
                >
                </div>

                <!-- Linha 2 -->
                <div class="item-header justify-left text-1xl">
                <mat-icon class="item-icon">inventory_2</mat-icon>
                <span class="item-code"># {{ item.nItem }}</span>
                    <span>
                        <strong *ngIf="itemFoiEditado(item)" class="p-2 pl-3 pr-3 rounded-full bg-green-800 text-white">Atualizado</strong>
                    </span>
                </div>

                <!-- Linha 3 -->
                <div class="linha-solo text-2xl">
                <strong class="item-title">{{ item.descricaoProduto }}</strong>
                </div>

                <!-- Linha 4 -->
                <div class="linha-row">
                <span class="left"
                    ><strong>Aplicação:</strong> {{ item.aplicacao }} &nbsp;
                    <strong>Cultura:</strong> {{ item.cultura }}</span
                >
                </div>

                <!-- Linha 6 -->
                <div class="linha-row">
                <span class="left">
                    <div class="inline-block" *ngIf="isQuantidadeAplicacoes(item)"><strong>Aplicações:</strong>{{ item.quantidadeAplicacoes }}</div> &nbsp;
                    <div class="inline-block" *ngIf="isDoseBiologica(item)"><strong>Dose Biológica:</strong> {{ item.doseBiologica }} L</div> &nbsp;
                    <div class="inline-block" *ngIf="isNumeroHectares(item)_"><strong>Hectares:</strong> {{ item.numeroHectares }} </div> &nbsp;
                    <div class="inline-block" *ngIf="isNrKit(item)"><strong>Nr. Kit:</strong> {{ item.nrKit }}</div>
                </span>
                <span class="right"
                    ><strong>Nº OC:</strong>
                    {{ getNumeroOCSeguro(item.numeroOC) }}</span
                >
                </div>

                <!-- Linha 8 -->
                <div class="linha-row">
                <span class="left text-1xl">
                    <strong>Dt. Entrega:</strong>
                    {{ getDataEntrega(item) }}
                </span>
                <span class="right"><strong>Venda:</strong> {{ getCodVenda(item) }}</span>
                </div>

                <!-- Linha 9 -->
                <div class="linha-row">
                <span class="left text-1xl"
                    ><strong>Quantidade:</strong> {{ item.quantidade }}</span
                >
                <span class="right text-1xl"
                    ><strong>Desconto:</strong> {{ item.desconto }}%</span
                >
                </div>

                <!-- Linha 10 -->
                <div class="linha-row" *ngIf="item?.custoKgL || item?.custoTotalHaBiologico">
                <span class="left text-1xl"
                    ><strong>Custo Kg/L:</strong> {{ item.custoKgL }}</span
                >
                <span class="right text-1xl"
                    ><strong>Total/Ha:</strong> R$
                    {{ item.custoTotalHaBiologico }}</span
                >
                </div>

                <!-- Linha 11 -->
                <div class="linha-row">
                <span class="left text-1xl"
                    ><strong>Preço Unitário:</strong> R$ {{ item.precoUnitario }}</span
                >
                <span class="rigth text-1xl"
                    ><strong>Valor Total:</strong> {{ getValorTotalSeguro(item) }}</span
                >
                </div>

                <!-- Ação -->
                <!-- Remover -->
                <div class="flex justify-end mt-2">
                <button mat-icon-button color="warn" [disabled]="!(editarAtivo && dadosPriceValidos)" (click)="carregarItemParaEdicao(item, i)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button color="warn" [disabled]="!editarAtivo" (click)="removerItem(i)">
                    <mat-icon>delete</mat-icon>
                </button>
                </div>
            </div>

            <!-- Se não houver itens -->
            <div
                *ngIf="dadosCabecalhoValidos && dadosPriceValidos"
                class="grid grid-cols-12 gap-1 pl-1 mt-1 pl-4 pr-3"
            >
                <div class="col-span-12">
                <div
                    *ngIf="dadosTabela.length === 0"
                    class="item-card border-l-8 border-green-700"
                >
                    Nenhum produto foi adicionado ao orçamento de vendas, adicione um
                    novo produto!
                </div>
                </div>
            </div>
        </div>

        <div class="totals-section">
            <div>
                <strong>Total (Investimento por Ha):</strong>
                <span>{{ totalInvestimentoPorHa | currency : "BRL" }}</span>
            </div>
            <div>
                <strong>Investimento Total no Manejo:</strong>
                <span>{{ investimentoTotalNoManejo | currency : "BRL" }}</span>
            </div>
        </div>

        <!-- Balão Total Final -->
        <div class="totals-section">
            <div>
            <strong> Valor Total do orçamento:</strong>
            <span>{{ somaValorTotalItens | currency : "BRL" }}</span>
            </div>
        </div>

        <div class="flex flex-col flex-auto p-2 mb-5 -mt-3">
            <hr class="border-0 h-[0.5px] bg-gray-300 mt-1 mb-2 my-4" />
        </div>

        <div class="p-1 flex flex-wrap gap-2 w-full justify-end -mt-5 mb-4 pr-4">
            <button
                *ngIf="isClient"
                mat-flat-button=""
                class="flex-1 bg-green-500 hover:bg-green-600 text-white max-w-[100px] text-sm font-bold py-2 px-3 rounded-full transition-all duration-300 flex items-center justify-center"
                ng-reflect-color="primary"
                (click)="enviarPedido()">
                <i class="fas fa-check-circle mr-2"></i>
                <span>Aprovar com Clicksign</span>
            </button>

            <button
                *ngIf="isClient"
                mat-flat-button=""
                class="flex-1 bg-yellow-500 hover:bg-yellow-600 max-w-[100px] text-white text-sm font-bold py-2 px-3 rounded-full transition-all duration-300 flex items-center justify-center"
                ng-reflect-color="primary"
                (click)="modificarOrcamento(selecionado)">
                <i class="fas fa-edit mr-2"></i>
                <span>Modificar</span>
            </button>

            <button
                [disabled]="!editarAtivo || loading"
                mat-flat-button=""
                class="flex-1 bg-gray-600 hover:bg-gray-700 text-white max-w-[200px] text-sm font-bold py-2 px-3 rounded-full transition-all duration-300 flex items-center justify-center disabled:bg-gray-200 disabled:text-gray-300 !disabled:text-gray-300"
                ng-reflect-color="primary"
                (click)="cancelarOrcamento(codOrcamento)"
                [class.selected]="
                    orcamentoSelecionado === codOrcamento
                ">
                <i class="fas fa-times mr-2"></i>
                <span>Cancelar Orçamento</span>
            </button>

            <button
                [disabled]="!editarAtivo || loading"
                *ngIf="!isClient"
                mat-flat-button=""
                class="flex-1 bg-orange-500 hover:bg-orange-600 text-white max-w-[200px] text-sm font-bold py-2 px-3 rounded-full transition-all duration-300 flex items-center justify-center disabled:bg-gray-200 disabled:text-gray-400"
                ng-reflect-color="primary"
                (click)="editarOrcamento(codOrcamento)"
                [class.selected]="
                    orcamentoSelecionado === codOrcamento
                ">
                <i class="fas fa-save mr-2"></i>
                <span>Salvar Alterações</span>
            </button>

            <button
                mat-flat-button=""
                class="flex-1 bg-orange-500 hover:bg-orange-600 text-white max-w-[100px] font-bold py-2 px-3 rounded-full transition-all duration-300 flex items-center justify-center"
                ng-reflect-color="primary"
                (click)="editarItem()">
                <i class="fas fa-pencil-alt mr-2"></i>
                <span>Editar</span>
            </button>
        </div>
    </div>
</div>
