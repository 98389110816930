//src/app/app.routing.ts
import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { AuthRoles } from './core/auth/guards/auth-roles.guard';
import { InitialDataResolver } from 'app/app.resolvers';
import { LayoutComponent } from 'app/layout/layout.component';
import { FazendaListComponent } from 'app/fazenda-lista/fazenda-lista.component';
import { SalesComponent } from './modules/admin/sales/sales.component';
import { OpenSalesComponent } from './modules/admin/sales/open-sales/open-sales.component';
import { OrdersOpenComponent } from './modules/admin/dashboard/orders-open/orders-open.component';
import { SearchComponent } from './modules/admin/sales/search/search.component';
import { QuestionComponent } from './modules/settings/checklists/question-checklist/question/question.component';
import { QuestionsComponent } from './modules/admin/checklists/create/questions/questions/questions.component';
import { StockComponent } from 'app/modules/admin/stock/stock/stock.component';

export const appRoutes: Route[] = [
  // Rota padrão: se o usuário não estiver autenticado, redireciona para o sign-in.
  { path: '', pathMatch: 'full', redirectTo: 'sign-in' },
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

  // Rotas públicas (layout "empty")
  {
    path: '',
    component: LayoutComponent,
    data: { layout: 'empty' },
    children: [
      {
        path: 'sign-in',
        loadChildren: () =>
          import('app/modules/auth/sign-in/sign-in.module').then(
            m => m.AuthSignInModule
          ),
      },
      {
        path: 'sign-up',
        loadChildren: () =>
          import('app/modules/auth/sign-up/sign-up.module').then(
            m => m.AuthSignUpModule
          ),
      },
      {
        path: 'forgot-password',
        loadChildren: () =>
          import('app/modules/auth/forgot-password/forgot-password.module').then(
            m => m.AuthForgotPasswordModule
          ),
      },
      {
        path: 'reset-password/:token',
        loadChildren: () =>
          import('app/modules/auth/reset-password/reset-password.module').then(
            m => m.AuthResetPasswordModule
          ),
      },
      {
        path: 'politica-de-privacidade',
        loadChildren: () =>
          import('app/modules/landing/privacy-policy/privacy-policy.module').then(
            m => m.PrivacyPolicyModule
          ),
      }
    ],
  },

  // Rotas públicas de acesso livre (layout "empty")
  {
    path: '',
    component: LayoutComponent,
    data: { layout: 'empty' },
    children: [
      {
        path: 'timeline',
        loadChildren: () =>
          import('app/modules/timeline/timeline.module').then(
            m => m.TimelineModule
          ),
      },
      {
        path: 'survey',
        loadChildren: () =>
          import('app/modules/admin/survey/survey.module').then(
            m => m.SurveyModule
          ),
      },
    ],
  },

  // Rotas protegidas apenas para usuários autenticados
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: { initialData: InitialDataResolver },
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('app/modules/admin/dashboard/dashboard.module').then(
            m => m.DashboardModule
          ),
      },
      {
        path: 'sign-out',
        loadChildren: () =>
          import('app/modules/auth/sign-out/sign-out.module').then(
            m => m.AuthSignOutModule
          ),
      },
      {
        path: 'farms',
        loadChildren: () =>
          import('app/modules/admin/farms/farms.module').then(
            m => m.FarmsModule
          ),
        canActivate: [AuthRoles],
      },
      {
        path: 'service-orders',
        loadChildren: () =>
          import('app/modules/admin/service-orders/service-orders.module').then(
            m => m.ServiceOrdersModule
          ),
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('app/modules/admin/reports/reports.module').then(
            m => m.ReportsModule
          ),
        canActivate: [AuthRoles],
      },
      {
        path: 'stock',
        component: StockComponent,
        canActivate: [AuthRoles],
      },
      {
        path: 'checklists',
        loadChildren: () =>
          import('app/modules/admin/checklists/checklists.module').then(
            m => m.ChecklistsModule
          ),
        canActivate: [AuthRoles],
      },
      {
        path: 'settings-question',
        component: QuestionComponent,
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('app/modules/settings/settings.module').then(
            m => m.SettingsModule
          ),
        canActivate: [AuthRoles],
      },
      {
        path: 'diaries',
        loadChildren: () =>
          import('app/modules/admin/diaries/diaries.module').then(
            m => m.DiariesModule
          ),
        canActivate: [AuthRoles],
      },
      {
        path: 'biofactories',
        loadChildren: () =>
          import('app/modules/admin/biofactories/biofactories.module').then(
            m => m.BiofactoriesModule
          ),
      },
      {
        path: 'compatibilidade',
        loadChildren: () =>
          import('app/modules/admin/compatibilidade/compatibilidade.module').then(
            m => m.CompatibilidadeModule
          ),
        canActivate: [AuthRoles],
      },
      {
        path: 'ea-protocol',
        loadChildren: () =>
          import('app/modules/admin/ea-protocol/ea-protocol.module').then(
            m => m.EaProtocolModule
          ),
        canActivate: [AuthRoles],
      },
      {
        path: 'time-line',
        loadChildren: () =>
          import('app/modules/admin/timeline/timeline.module').then(
            m => m.TimelineModule
          ),
      },
      {
        path: 'type-simulator',
        loadChildren: () =>
          import('app/modules/admin/type-simulator/type-simulator.module').then(
            m => m.TypeSimulatorModule
          ),
      },
      {
        path: 'simulator',
        loadChildren: () =>
          import('app/modules/admin/simulator/simulator.module').then(
            m => m.SimulatorModule
          ),
      },
      {
        path: 'calendario',
        loadChildren: () =>
          import('app/modules/admin/calendar-aea-aql/calendar-aea-aql.module').then(
            m => m.CalendarAeaAqlModule
          ),
      },
      {
        path: 'previsibilidade',
        loadChildren: () =>
          import('app/modules/admin/predictability/predictability.module').then(
            m => m.PredictabilityModule
          ),
      },
      {
        path: 'plan',
        loadChildren: () =>
          import('app/modules/admin/plan/plan.module').then(
            m => m.PlanModule
          ),
      },
      {
        path: 'sales',
        component: SalesComponent,
      },
      {
        path: 'open-sales',
        component: OpenSalesComponent,
      },
      {
        path: 'lista-fazendas',
        component: FazendaListComponent,
      },
      {
        path: 'orders-open',
        component: OrdersOpenComponent,
      },
      {
        path: 'orcamento',
        component: OrdersOpenComponent,
      },
      {
        path: 'search-pedido',
        component: SearchComponent,
      },
      {
        path: 'new-checklist',
        component: QuestionsComponent,
      },
    ],
  },
];
