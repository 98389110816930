import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Product } from 'app/core/models/admin/products.model';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { FuseConfigService } from '@fuse/services/config';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  private _products: BehaviorSubject<Product[] | null> = new BehaviorSubject(
    null
  );
  products: Product[] = [];
  private productList: Product[] = [];
  private host: string;
  private _refresh$ = new Subject<void>();

  constructor(
    private _httpproduct: HttpClient,
    private _fuseConfigService: FuseConfigService
  ) {
    this._fuseConfigService.config$.subscribe(config => {
      this.host = environment.operHost;
    });
  }
  get products$(): Observable<Product[]> {
    return this._products.asObservable();
  }

  get refreshNeededs() {
    return this._refresh$;
  }

  getProducts(): Observable<Product[]> {
    let a = this._httpproduct
      .get<Product[]>(this.host + '/api/v1/product')
      .pipe(
        map(result => {
          this.productList = result['products'];
          return this.productList;
        }),
        tap(products => (this.products = products))
      );
    return a;
  }

  getProductsMemory(): Observable<Product[]> {
    if (this.productList.length > 0) {
      return of(this.productList);
    }
    return this.getProducts();
  }

  create(produc: Product): Observable<Product> {
    return this._httpproduct
      .post<Product>(this.host + '/api/v1/product', produc)
      .pipe(
        tap(() => {
          this.productList = [];
          this._refresh$.next();
        })
      );
  }

  update(id: number, data: Product) {
    return this._httpproduct
      .put<Product>(this.host + '/api/v1/product/' + id.toString(), data)
      .pipe(
        tap(() => {
          this.productList = [];
          this._refresh$.next();
        })
      );
  }

  findRecipe(id: number): string | number {
    const prod = this.products.find(product => product.id === id);
    return prod ? prod.description : id;
  }
}
