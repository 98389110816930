<link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css" />
<div class="mb-2 w-full">
    <div *ngIf="isLoading" class="progress-bar-container">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="pt-4 flex justify-center w-full">
        <div class="rounded-lg shadow-2xl bg-card w-full mx-8 px-4 p-4">
            <div class="pedido">
                <div class="header">
                    <div
                        *ngIf="!isMobile"
                        class="flex pr-5 md:pr-0 items-center font-sans text-3xl text-neutral-500 font-bold">
                        <i class="fas fa-shopping-cart mr-3 text-green-800"></i>
                        Pedido De Vendas - Realizados
                    </div>

                    <div
                        *ngIf="isMobile"
                        class="flex pr-5 md:pr-0 items-center font-sans text-3xl text-neutral-500 font-bold">
                        <i class="fas fa-shopping-cart mr-3 text-green-800"></i>
                        Ped Vendas
                    </div>

                    <div *ngIf="!isMobile" class="buttons">
                        <button
                            mat-flat-button
                            class="mat-focus-indicator ml-4 mat-flat-button mat-button-base mat-primary"
                            routerLink="/open-sales">
                            <span class="mat-button-wrapper">
                                <span class="ml-2 mr-1"> Orçamentos</span>
                            </span>
                        </button>

                        <button
                            mat-flat-button
                            class="mat-focus-indicator ml-4 mat-flat-button mat-button-base bg-green-500 text-white"
                            (click)="searchDialog()">
                            <span class="mat-button-wrapper">
                                <mat-icon
                                    class="icon-size-5"
                                    matPrefix
                                    [svgIcon]="
                                        'heroicons_solid:search'
                                    "></mat-icon>
                                <span class="ml-2 mr-1">Filtrar</span>
                            </span>
                        </button>

                        <button
                            mat-flat-button
                            class="mat-focus-indicator ml-4 mat-flat-button mat-button-base"
                            style="background-color: #6c757d; color: white"
                            (click)="limparFiltros()">
                            <span class="mat-button-wrapper">
                                <span class="ml-2 mr-1">Limpar Filtros</span>
                            </span>
                        </button>
                    </div>

                    <div
                        *ngIf="isMobile"
                        class="buttons flex justify-start gap-2 px-4 -mb-6">
                        <button
                            mat-flat-button
                            tooltip="Orçamentos"
                            class="mat-focus-indicator ml-4 mat-flat-button mat-button-base mat-primary flex items-center"
                            routerLink="/open-sales">
                            <span class="mat-button-wrapper flex items-center">
                                <i class="fas fa-file-invoice-dollar mr-2"></i>
                            </span>
                        </button>

                        <button
                            matTooltip="Filtrar"
                            mat-flat-button
                            class="mat-focus-indicator ml-4 mat-flat-button mat-button-base bg-green-500 text-white"
                            (click)="searchDialog()">
                            <span class="mat-button-wrapper">
                                <mat-icon
                                    class="icon-size-5"
                                    matPrefix
                                    [svgIcon]="
                                        'heroicons_solid:search'
                                    "></mat-icon>
                            </span>
                        </button>

                        <button
                            matTooltip="Limpar Filtros"
                            mat-flat-button
                            class="mat-focus-indicator ml-4 mat-flat-button mat-button-base bg-gray-500 text-white hover:bg-gray-800"
                            (click)="limparFiltros()">
                            <span class="mat-button-wrapper flex items-center">
                                <i class="fas fa-times mr-2"></i>
                                <span>Limpar</span>
                            </span>
                        </button>
                    </div>
                </div>

                <div
                    *ngIf="!isMobile"
                    class="flex items-center justify-left w-full gap-4 mt-3">
                    <!-- Searchbar -->
                    <div class="relative w-full max-w-md">
                        <input
                            type="text"
                            class="w-full px-4 py-2 focus:outline-none focus:border-orange-500 focus:ring-0 bg-white border border-gray-300 rounded-lg"
                            placeholder="Informe o Cliente"
                            [formControl]="vendaControl"
                            (input)="filtrarPedidosPorCliente()" />
                    </div>

                    <!-- Botão de ordenação Cliente -->
                    <div class="flex items-center">
                        <button
                            mat-icon-button
                            (click)="ordenarPorCardName()"
                            class="flex items-center justify-center bg-gray-300 hover:bg-gray-500 text-gray-800 hover:text-white p-2 rounded-lg transition duration-200 group">
                            <mat-icon
                                role="img"
                                class="mat-icon notranslate mat-icon-no-color group-hover:text-white transition duration-200"
                                aria-hidden="true"
                                ng-reflect-svg-icon="heroicons_outline:plus"
                                data-mat-icon-type="svg"
                                data-mat-icon-name="plus"
                                data-mat-icon-namespace="heroicons_outline">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 -960 960 960"
                                    width="24px"
                                    fill="currentColor"
                                    class="group-hover:fill-white transition duration-200">
                                    <path
                                        d="M480-120 320-280l56-56 64 63v-414l-64 63-56-56 160-160 160 160-56 57-64-64v414l64-63 56 56-160 160Z" />
                                </svg>
                            </mat-icon>
                            Cliente &nbsp;
                        </button>
                    </div>

                    <!-- Botão de ordenação Data Venda -->
                    <div class="flex items-center">
                        <button
                            mat-icon-button
                            (click)="ordenarPorData()"
                            class="flex items-center justify-center bg-gray-300 hover:bg-gray-500 text-gray-800 hover:text-white p-2 rounded-lg transition duration-200 group">
                            <mat-icon
                                role="img"
                                class="mat-icon notranslate mat-icon-no-color group-hover:text-white transition duration-200"
                                aria-hidden="true"
                                ng-reflect-svg-icon="heroicons_outline:plus"
                                data-mat-icon-type="svg"
                                data-mat-icon-name="plus"
                                data-mat-icon-namespace="heroicons_outline">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 -960 960 960"
                                    width="24px"
                                    fill="currentColor"
                                    class="group-hover:fill-white transition duration-200">
                                    <path
                                        d="M480-120 320-280l56-56 64 63v-414l-64 63-56-56 160-160 160 160-56 57-64-64v414l64-63 56 56-160 160Z" />
                                </svg>
                            </mat-icon>
                            Dt Venda &nbsp;
                        </button>
                    </div>

                    <!-- Botão de ordenação N Pedido -->
                    <div class="flex items-center">
                        <button
                            mat-icon-button
                            (click)="ordenarPorDocNum()"
                            class="flex items-center justify-center bg-gray-300 hover:bg-gray-500 text-gray-800 hover:text-white p-2 rounded-lg transition duration-200 group">
                            <mat-icon
                                role="img"
                                class="mat-icon notranslate mat-icon-no-color group-hover:text-white transition duration-200"
                                aria-hidden="true"
                                ng-reflect-svg-icon="heroicons_outline:plus"
                                data-mat-icon-type="svg"
                                data-mat-icon-name="plus"
                                data-mat-icon-namespace="heroicons_outline">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 -960 960 960"
                                    width="24px"
                                    fill="currentColor"
                                    class="group-hover:fill-white transition duration-200">
                                    <path
                                        d="M480-120 320-280l56-56 64 63v-414l-64 63-56-56 160-160 160 160-56 57-64-64v414l64-63 56 56-160 160Z" />
                                </svg>
                            </mat-icon>
                            Doc Num &nbsp;
                        </button>
                    </div>

                    <!-- Botão de ordenação Status -->
                    <!-- Botão de ordenação com dropdown -->
                    <div class="flex items-center">
                        <button
                            mat-icon-button
                            [matMenuTriggerFor]="statusMenu"
                            class="flex items-center justify-center bg-gray-300 hover:bg-gray-500 text-gray-800 hover:text-white p-2 rounded-lg transition duration-200 group">
                            <i
                                class="fa-solid fa-filter text-lg group-hover:text-white transition duration-200"></i>
                            Status &nbsp;
                        </button>

                        <!-- Dropdown de status -->
                        <mat-menu #statusMenu="matMenu">
                            <button
                                mat-menu-item
                                (click)="filtrarPorStatus('Cancelado')">
                                Cancelado
                            </button>
                            <button
                                mat-menu-item
                                (click)="filtrarPorStatus('Concluido')">
                                Concluído
                            </button>
                            <button
                                mat-menu-item
                                (click)="filtrarPorStatus('Saldo a Entregar')">
                                Saldo a Entregar
                            </button>
                            <button
                                mat-menu-item
                                (click)="filtrarPorStatus('Desconhecido')">
                                Desconhecido
                            </button>
                            <button
                                mat-menu-item
                                (click)="filtrarPorStatus('')">
                                Mostrar Todos
                            </button>
                        </mat-menu>
                    </div>
                </div>

                <div
                    *ngIf="isMobile"
                    class="flex flex-col items-start w-full gap-3 mt-3 px-4">
                    <!-- Searchbar -->
                    <div class="relative w-full max-w-md">
                        <input
                            type="text"
                            class="w-full px-4 py-2 focus:outline-none focus:border-orange-500 focus:ring-0 bg-white border border-gray-300 rounded-lg"
                            placeholder="Informe o Cliente"
                            [formControl]="vendaControl"
                            (input)="filtrarPedidosPorCliente()" />
                    </div>

                    <!-- Botão de ordenação Cliente -->
                    <div class="w-full">
                        <button
                            mat-icon-button
                            (click)="ordenarPorCardName()"
                            class="flex w-full items-center justify-center bg-gray-300 hover:bg-gray-500 text-gray-800 group hover:text-white p-2 rounded-lg transition duration-200">
                            <mat-icon class="mr-2 group-hover:text-white"
                                >sort</mat-icon
                            >
                            Cliente
                        </button>
                    </div>

                    <!-- Botão de ordenação Data Venda -->
                    <div class="w-full">
                        <button
                            mat-icon-button
                            (click)="ordenarPorData()"
                            class="flex w-full items-center justify-center bg-gray-300 hover:bg-gray-500 text-gray-800 group hover:text-white p-2 rounded-lg transition duration-200">
                            <mat-icon class="mr-2 group-hover:text-white"
                                >date_range</mat-icon
                            >
                            Dt Venda
                        </button>
                    </div>

                    <!-- Botão de ordenação N Pedido -->
                    <div class="w-full">
                        <button
                            mat-icon-button
                            (click)="ordenarPorDocNum()"
                            class="flex w-full items-center justify-center bg-gray-300 hover:bg-gray-500 text-gray-800 group hover:text-white p-2 rounded-lg transition duration-200">
                            <mat-icon class="mr-2 group-hover:text-white"
                                >description</mat-icon
                            >
                            Doc Num
                        </button>
                    </div>

                    <!-- Botão de ordenação Status -->
                    <div class="w-full">
                        <button
                            mat-icon-button
                            [matMenuTriggerFor]="statusMenu"
                            class="flex w-full items-center justify-center bg-gray-300 hover:bg-gray-500 text-gray-800 group hover:text-white p-2 rounded-lg transition duration-200">
                            <i
                                class="fa-solid fa-filter text-lg mr-2 group-hover:text-white transition duration-200"></i>
                            Status
                        </button>

                        <!-- Dropdown de status -->
                        <mat-menu #statusMenu="matMenu">
                            <button
                                mat-menu-item
                                (click)="filtrarPorStatus('Cancelado')">
                                Cancelado
                            </button>
                            <button
                                mat-menu-item
                                (click)="filtrarPorStatus('Concluido')">
                                Concluído
                            </button>
                            <button
                                mat-menu-item
                                (click)="filtrarPorStatus('Saldo a Entregar')">
                                Saldo a Entregar
                            </button>
                            <button
                                mat-menu-item
                                (click)="filtrarPorStatus('Desconhecido')">
                                Desconhecido
                            </button>
                            <button
                                mat-menu-item
                                (click)="filtrarPorStatus('')">
                                Mostrar Todos
                            </button>
                        </mat-menu>
                    </div>
                </div>

                <br />

                <hr class="-mt-1" />

                <!-- ADICIONAR O SCROOL-->
                <div
                    #scrollContainer
                    class="vendas-container mt-2 mb-4"
                    (scroll)="onScroll()">
                    <div
                        class="venda-card"
                        *ngFor="let venda of vendasFiltradas"
                        [ngClass]="
                            getStatusClass(venda.DocStatus, venda.CANCELED)
                        "
                        (click)="
                            selecionarLinha(venda, isMobile, venda.CANCELED)
                        ">
                        <!-- Ícone e Número do Documento -->
                        <div class="venda-info">
                            <div class="venda-header">
                                <mat-icon class="status-icon">{{
                                    getStatusIcon(
                                        venda.DocStatus,
                                        venda.CANCELED
                                    )
                                }}</mat-icon>
                                <span class="doc-num"
                                    ># {{ venda.CardCode }}</span
                                >
                            </div>

                            <!-- Nome da Fazenda e Número do Pedido -->
                            <div class="venda-detalhes">
                                <strong>{{ venda.CardName }}</strong>
                                <div class="pedido">P{{ venda.DocEntry }}</div>
                            </div>
                        </div>

                        <!-- Data da Venda e Status -->
                        <div class="venda-status">
                            <div class="data-venda">
                                {{ venda.DocDate | date : 'dd/MM/yyyy' }}
                            </div>
                            <div
                                class="status-text"
                                [ngClass]="
                                    getStatusClass(
                                        venda.DocStatus,
                                        venda.CANCELED
                                    )
                                ">
                                {{ getStatus(venda.DocStatus, venda.CANCELED) }}
                            </div>
                            <button
                                [matTooltip]="'Baixar PDF do Pedido'"
                                class="ml-auto bg-green-100 hover:bg-green-400 rounded-full hover:text-white text-gray-500 p-2"
                                (click)="
                                    onDownloadPedido($event, venda.DocEntry)
                                ">
                                <i class="fa-solid fa-file-pdf"></i> &nbsp;
                                Baixar PDF
                            </button>
                        </div>

                        <div *ngIf="isLoadingMore" class="loading">
                            Carregando mais pedidos...
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
