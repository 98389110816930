import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProductsService } from 'app/core/services/admin/products.service';
import { FarmsService } from 'app/core/services/admin/farms.service';
import { ProductStockService } from 'app/core/services/admin/product-stock.service';
import { Product } from 'app/core/models/admin/products.model';
import { Farm } from 'app/core/models/admin/farms.model';

@Component({
  selector: 'app-stock-form',
  templateUrl: './stock-form.component.html',
  styleUrls: ['./stock-form.component.scss']
})
export class StockFormComponent implements OnInit {
  stockForm: FormGroup;
  products: Product[] = [];
  farms: Farm[] = [];
  formTitle: string = 'Adicionar Estoque';

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<StockFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, // Se houver dados para edição
    private productsService: ProductsService,
    private farmsService: FarmsService,
    private productStockService: ProductStockService
  ) {}

  ngOnInit(): void {
    // Inicializa o formulário já com um produto (estoque) e um lote padrão
    this.initializeForm();
  
    // Carrega os selects de produtos e fazendas
    this.productsService.getProducts().subscribe(prodList => {
      this.products = prodList;
    });
    this.farmsService.getFarms().subscribe(farmsList => {
      this.farms = farmsList;
    });
  
    // Se houver dados para edição
    if (this.data && this.data.stockData) {
      // Define o título para atualização
      this.formTitle = 'Atualizar Estoque';
      const stockData = this.data.stockData;
      // Se os dados não vierem estruturados em "inventory", cria a estrutura esperada
      if (!stockData.inventory) {
        stockData.inventory = [{
          product: stockData.product_id,
          minimun_level: stockData.minimun_level,
          batches: stockData.batch || []
        }];
      }
      // Preenche o campo de fazenda
      this.stockForm.patchValue({ farm_id: stockData.farm_id });
      // Atualiza o FormArray "inventory"
      const inventoryArray = this.stockForm.get('inventory') as FormArray;
      inventoryArray.clear();
      stockData.inventory.forEach(prod => {
        const prodGroup = this.createStockEntry();
        prodGroup.patchValue(prod);
        // Atualiza o FormArray de lotes ("batches") para cada produto
        const batchesArray = prodGroup.get('batches') as FormArray;
        batchesArray.clear();
        prod.batches.forEach(batch => {
          const batchGroup = this.createBatchEntry();
          batchGroup.patchValue(batch);
          batchesArray.push(batchGroup);
        });
        inventoryArray.push(prodGroup);
      });
      // Desabilita os campos que não devem ser editados
      this.stockForm.get('farm_id').disable();
      inventoryArray.controls.forEach((prodGroup: FormGroup) => {
        prodGroup.get('product').disable();
        prodGroup.get('minimun_level').disable();
        const batchesArray = prodGroup.get('batches') as FormArray;
        batchesArray.controls.forEach((batchGroup: FormGroup) => {
          batchGroup.get('batch').disable();
          batchGroup.get('validity').disable();
          batchGroup.get('mode').disable();
          // quantity e operation_type permanecem habilitados
        });
      });
    }
  }

  initializeForm(): void {
    this.stockForm = this.fb.group({
      farm_id: [null, Validators.required],
      // Inicialmente já cria um produto com um lote padrão
      inventory: this.fb.array([this.createStockEntry()])
    });
  }

  // Getter para o FormArray de produtos
  get inventoryFormArray(): FormArray {
    return this.stockForm.get('inventory') as FormArray;
  }

  // Cria um novo FormGroup para um produto (estoque) já com um lote padrão
  createStockEntry(): FormGroup {
    return this.fb.group({
      product: [null, Validators.required],
      minimun_level: [null, Validators.required],
      batches: this.fb.array([this.createBatchEntry()])
    });
  }

  // Cria um novo FormGroup para um lote
  createBatchEntry(): FormGroup {
    return this.fb.group({
      batch: ['', Validators.required],
      validity: ['', Validators.required],
      quantity: [null, [Validators.required, Validators.min(0.001)]],
      mode: ['OnDemand', Validators.required],
      operation_type: ['verification', Validators.required]
    });
  }

  // Adiciona um novo produto (estoque)
  addStock(): void {
    this.inventoryFormArray.push(this.createStockEntry());
  }

  // Remove um produto
  removeStock(index: number): void {
    this.inventoryFormArray.removeAt(index);
  }

  // Retorna o FormArray de lotes para um produto específico
  getBatches(stock: FormGroup): FormArray {
    return stock.get('batches') as FormArray;
  }

  // Adiciona um novo lote para um produto específico
  addBatch(stockIndex: number): void {
    const stockGroup = this.inventoryFormArray.at(stockIndex) as FormGroup;
    const batches = stockGroup.get('batches') as FormArray;
    batches.push(this.createBatchEntry());
  }

  // Remove um lote de um produto
  removeBatch(stockIndex: number, batchIndex: number): void {
    const stockGroup = this.inventoryFormArray.at(stockIndex) as FormGroup;
    const batches = stockGroup.get('batches') as FormArray;
    batches.removeAt(batchIndex);
  }

  // Envia os dados do formulário para o backend
  save(): void {
    if (this.stockForm.invalid) return;
    const rawValue = this.stockForm.getRawValue();
    const payload = {
      stocks: rawValue.inventory.map((prod: any) => ({
        farm_id: rawValue.farm_id,
        product_id: prod.product,
        minimun_level: prod.minimun_level,
        batch: prod.batches,
      })),
    };
    console.log('Payload para envio:', payload);
    this.productStockService.createStock(payload).subscribe({
      next: res => {
        console.log('Estoque criado/atualizado com sucesso:', res);
        this.dialogRef.close(res);
      },
      error: err => {
        console.error('Erro ao enviar o estoque:', err);
      }
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }
}