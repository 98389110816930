//src/app/modules/admin/sales/open-sales/open-sales-order/open-sales-order.component.ts
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, map, startWith } from 'rxjs';
import { firstValueFrom } from 'rxjs';
import { DateTime } from 'aws-sdk/clients/devicefarm';
import { Cadastro, Item } from '../../Types';
import { MatDialogRef } from '@angular/material/dialog';
import { ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from 'environments/environment';
import { Dialog } from '@angular/cdk/dialog';
import { MatTable } from '@angular/material/table';
import { filter } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  Input,
  Inject,
} from '@angular/core';
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { OpenSalesService } from '../open-sales.service';
import { CurrencyPipe } from '@angular/common';
import { DialogEditarComponent } from '../open-sales-dialog/dialog-editar/dialog-editar.component';

@Component({
  selector: 'app-open-sales-order',
  templateUrl: './open-sales-order.component.html',
  styleUrls: ['./open-sales-order.component.scss'],
})
export class OpenSalesOrderComponent implements OnInit {
  @ViewChild(MatSidenavContainer) sidenavContainer: MatSidenavContainer;
  @ViewChild('itensScroll') itensScroll: ElementRef;

  clientes: any[] = [];
  clientesFiltrados = new BehaviorSubject<any[]>([]);
  filtroForm: FormGroup;
  apiUrl: string = '';
  loading: boolean = false;
  error: boolean = false;
  regrasDeCalculo: any[] = [];
  tiposSanitizacao: string[] = [];

  produto: any = null;
  cliente: any = null;
  vendedor: any = null;
  cdEntrega: any = null;
  methodopg: any = null;
  codClienteControl = new FormControl();
  cpfControl = new FormControl();
  telefoneControl = new FormControl();
  fazendaControl = new FormControl();
  enderecoControl = new FormControl();
  dataVencimentoControl = new FormControl();
  codPedidoControl = new FormControl();
  codControl = new FormControl();
  clienteControl = new FormControl();
  localEntregaControl = new FormControl();
  freteControl = new FormControl();
  observacoescadControl = new FormControl();
  textoControl = new FormControl();
  condicaoPagamentoControl = new FormControl();
  inscricaoControl = new FormControl();
  inscricaoMunicipalControl = new FormControl();
  itemControl = new FormControl();
  freteCodigo: any = null;
  produtoControl = new FormControl();
  vendedorControl = new FormControl();
  fretes: any[] = [];
  fretesFiltrados: any[] = [];
  CdEntregaControl = new FormControl();
  emailControl = new FormControl();
  observacoesControl = new FormControl();
  numeroOCControl = new FormControl();
  quantidadeControl = new FormControl();
  descontoControl = new FormControl();
  aplicacaoControl = new FormControl();
  nItemControl = new FormControl();
  descricaoProdutoControl = new FormControl();
  precoUnitarioControl = new FormControl();
  dataEntregaControl = new FormControl();
  culturaControl = new FormControl();
  quantidadeAplicacoesControl = new FormControl();
  doseBiologicaControl = new FormControl();
  faixaCompraControl = new FormControl();
  faixasDeCompra: any[] = [];
  numeroHectaresControl = new FormControl('', [Validators.required]);
  custoKgLControl = new FormControl('');
  custoTotalHaBiologicoControl = new FormControl('');
  nrKitControl = new FormControl({ value: '', disabled: true });
  buscaClienteControl = new FormControl();
  newValorTotalControl = new FormControl('');
  sanatizacaoProdutoControl = new FormControl('');
  somaValorTotalItens: number = 0;

  items: any[] = [];
  itemsFiltrados: any[] = [];

  numbersCard: any[] = [];
  numberCard: any[] = [];
  numberCardFiltrado: any[] = [];

  vendedores: any[] = [];
  vendedoresFiltrados: any[] = [];
  listaFormaPagamento: any[] = [];

  formasPagamento: any[] = [];
  formasPagamentoFiltradas: any[] = [];
  formaPagamentoControl = new FormControl();
  filteredFormaPagamento: any;
  formaPagamentoSelecionada: any = null;
  PayMethCodSelecionado: string;

  cdsEntrega: any[] = [];
  cdsEntregaFiltrados: any[] = [];

  filteredItems: any;
  filteredItemDescriptions: any;
  filteredClientes: any;
  filteredVendedores: any;
  filteredCdEntrega: any;
  filteredCulturas: any;
  filteredCondicaoPagamento: any;
  culturasFiltradas: any[] = [];
  culturas: any[] = [];
  linhasSelecionadas: any;
  dadosTabela: any[] = [];
  dadosTabelaOnFarm: any[] = [];
  novoItem: any = {};
  cabecalho: any[] = [];
  private indiceSequencial: number = 1;
  contadorPedido: number = 0;
  BPLIdSelecionado: number | null = null;
  SlpCodeSelecionado: number | null = null;
  editarAtivo: boolean = false;
  ultimoNumAtCard: string;
  GroupNumSelecionado: string;
  condicoesPagamento: any[] = [];
  condicoesPagamentoFiltradas: any[] = [];
  condicao: any = null;
  totalInvestimentoPorHa: number = 0;
  investimentoTotalNoManejo: number = 0;
  public mostrarDetalhesCliente: boolean = false;
  dadosCabecalhoValidos: boolean = false;
  camposInvalidosCabecalho: string[] = [];
  dadosPriceValidos: boolean = false;
  camposInvalidosPrice: string[] = [];
  validDataProductItem: boolean = false;
  camposInvalidosProduto: string[] = [];
  formProdutoIniciado: boolean = false;
  formPriceIniciado: boolean = false;

  deleteProcess: boolean = false;

  indiceItemEditando: number | null = null;
  itensEditados: string[] = [];

  constructor(
    public matDialogRef: MatDialogRef<any>,
    public router: Router,
    private dialog: MatDialog,
    private openService: OpenSalesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private changeDetectorRef: ChangeDetectorRef,
    private http: HttpClient,
    private currencyPipe: CurrencyPipe
  ) {
    this.filtroForm = new FormGroup({
      U_CADF_Fazenda: new FormControl(''),
      CardCode: new FormControl(''),
      Nome_do_PN: new FormControl(''),
    });

    this.apiUrl = environment.apiSap;

    let items: Item[] = [];

    let cabecalho: Cadastro[] = [];
  }
  async ngOnInit(): Promise<void> {
    await this.carregarClientes();
    await this.carregarItems();
    await this.carregarVendedores();
    await this.carregarCdsEntrega();
    await this.carregarMeioCultura();
    await this.carregarRegrasDeCalculo();
    await this.carregarCondicaoPg();
    await this.carregarOrcamentos();
    await this.carregarFormasPg();
    await this.carregarFretes();
    await this.carregarFretes();
    await this.carregarFaixasDeCompra();
    await this.carregarTiposSanitizacao();
    let ultimoEstado: { [key: string]: any } = {};

    const tipoSanitizacaoAtual = this.sanatizacaoProdutoControl.value;
    if (tipoSanitizacaoAtual && this.nItemControl.value) {
      this.selecionarItensPadrao(
        this.nItemControl.value,
        parseFloat(this.quantidadeControl.value) || 1,
        this.codControl.value,
        this.aplicacaoControl.value,
        this.culturaControl.value,
        this.dataEntregaControl.value,
        tipoSanitizacaoAtual
      );
    }

    this.onChanges();
    this.changeDetectorRef.detectChanges();
    this.codControl = new FormControl(
      { value: '', disabled: this.selectUtilizado !== false },
      Validators.required
    );
    this.quantidadeAplicacoesControl.valueChanges.subscribe(() =>
      this.calcularCustoTotalHaBiologico()
    );
    this.doseBiologicaControl.valueChanges.subscribe(() =>
      this.calcularCustoTotalHaBiologico()
    );
    this.custoKgLControl.valueChanges.subscribe(() =>
      this.calcularCustoTotalHaBiologico()
    );
    this.quantidadeControl.valueChanges.subscribe(() =>
      this.validarERecalcularValorTotal()
    );
    this.precoUnitarioControl.valueChanges.subscribe(() =>
      this.validarERecalcularValorTotal()
    );

    this.quantidadeAplicacoesControl.valueChanges.subscribe(() =>
      this.atualizarNrKit()
    );
    this.doseBiologicaControl.valueChanges.subscribe(() =>
      this.atualizarNrKit()
    );
    this.numeroHectaresControl.valueChanges.subscribe(() =>
      this.atualizarNrKit()
    );

    this.filteredCondicaoPagamento =
      this.condicaoPagamentoControl.valueChanges.pipe(
        startWith(''),
        map((value) => {
          if (typeof value === 'string') {
            return value;
          } else if (value && value.PymntGroup) {
            return value.PymntGroup;
          } else {
            return '';
          }
        }),
        map((nome) =>
          nome
            ? this._filterCondicoesPagamento(nome)
            : this.condicoesPagamento.slice()
        )
      );

    this.filteredFormaPagamento = this.formaPagamentoControl.valueChanges.pipe(
      startWith(''),
      map((value) =>
        typeof value === 'string' ? value : value?.CPAGAMENTO || ''
      ),
      map((nome) =>
        nome ? this._filterFormasPg(nome) : this.formasPagamento.slice()
      )
    );

    this.filteredCulturas = this.culturaControl.valueChanges.pipe(
      startWith(''),
      map((value) =>
        typeof value === 'string' ? value : value ? value.Name : ''
      ),
      map((name) => (name ? this._filterCulturas(name) : this.culturas.slice()))
    );

    this.filteredItems = this.nItemControl.valueChanges.pipe(
      startWith(''),
      map((value) =>
        typeof value === 'string' ? value : value ? value.ItemCode : ''
      ),
      map((name) => (name ? this._filterItems(name) : this.items.slice()))
    );

    this.filteredItemDescriptions =
      this.descricaoProdutoControl.valueChanges.pipe(
        startWith(''),
        map((value) =>
          typeof value === 'string' ? value : value ? value.ItemName : ''
        ),
        map((description) =>
          description
            ? this._filterItemDescriptions(description)
            : this.items.slice()
        )
      );

    this.filteredClientes = this.clienteControl.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.Nome_do_PN)),
      map((nome) => (nome ? this._filterClientes(nome) : this.clientes.slice()))
    );

    this.filteredVendedores = this.vendedorControl.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.SlpName)),
      map((nome) =>
        nome ? this._filterVendedores(nome) : this.vendedores.slice()
      )
    );

    this.CdEntregaControl.valueChanges
      .pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.BPLName)),
        map((nome) =>
          nome ? this._filterCdsEntrega(nome) : this.cdsEntrega.slice()
        )
      )
      .subscribe((cdsEntregaFiltrados: any[]) => {
        this.filteredCdEntrega = cdsEntregaFiltrados;
      });

    this.vendedorControl.valueChanges
      .pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.SlpName)),
        map((nome) =>
          nome ? this._filterVendedores(nome) : this.vendedores.slice()
        )
      )
      .subscribe((vendedoresFiltrados: any[]) => {
        this.filteredVendedores = vendedoresFiltrados;
      });

    this.culturaControl.valueChanges
      .pipe(
        startWith(''),
        map((value) =>
          typeof value === 'string' ? value : value ? value.Name : ''
        ),
        map((name) =>
          name ? this._filterCulturas(name) : this.culturas.slice()
        )
      )
      .subscribe((culturasFiltradas: any[]) => {
        this.filteredCulturas = culturasFiltradas;
      });

    this.buscaClienteControl.valueChanges
      .pipe(
        startWith(''),
        map((value) =>
          typeof value === 'string' ? value : value?.Nome_do_PN || ''
        ),
        map((searchTerm) => this.filtrarClientes(searchTerm))
      )
      .subscribe((filteredClients) =>
        this.clientesFiltrados.next(filteredClients)
      );

    combineLatest([
      this.nItemControl.valueChanges.pipe(startWith(this.nItemControl.value)),
      this.quantidadeControl.valueChanges.pipe(
        startWith(this.quantidadeControl.value)
      ),
      this.codControl.valueChanges.pipe(startWith(this.codControl.value)),
      this.aplicacaoControl.valueChanges.pipe(
        startWith(this.aplicacaoControl.value)
      ),
      this.culturaControl.valueChanges.pipe(
        startWith(this.culturaControl.value)
      ),
      this.dataEntregaControl.valueChanges.pipe(
        startWith(this.dataEntregaControl.value)
      ),
      this.sanatizacaoProdutoControl.valueChanges.pipe(
        startWith(this.sanatizacaoProdutoControl.value)
      ),
    ])
      .pipe(
        filter(
          ([
            nItem,
            quantidade,
            codVenda,
            aplicacao,
            cultura,
            dataEntrega,
            tipoSanatizacao,
          ]) =>
            !!nItem &&
            !!quantidade &&
            !!codVenda &&
            !!aplicacao &&
            !!cultura &&
            !!dataEntrega &&
            !!tipoSanatizacao
        ), // Verifica se todos os campos estão preenchidos
        distinctUntilChanged((anterior, atual) => {
          const estadoAtual = {
            nItem: atual[0],
            quantidade: atual[1],
            codVenda: atual[2],
            aplicacao: atual[3],
            cultura: atual[4],
            dataEntrega: atual[5],
            tipoSanatizacao: atual[6],
          };

          // Verifica se algum valor mudou
          const valoresMudaram = Object.keys(estadoAtual).some(
            (key) => estadoAtual[key] !== ultimoEstado[key]
          );

          // Atualiza o último estado
          ultimoEstado = { ...estadoAtual };

          return !valoresMudaram; // Se não mudou, evita chamada desnecessária
        })
      )
      .subscribe(
        ([
          nItem,
          quantidade,
          codVenda,
          aplicacao,
          cultura,
          dataEntrega,
          tipoSanatizacao,
        ]) => {
          this.selecionarItensPadrao(
            nItem,
            quantidade,
            codVenda,
            aplicacao,
            cultura,
            dataEntrega,
            tipoSanatizacao
          );
        }
      );

    this.sanatizacaoProdutoControl.valueChanges
      .pipe(distinctUntilChanged()) // Evita múltiplas execuções para o mesmo valor
      .subscribe((newValue) => {
        this.verificarSanatizacao(newValue);
      });

    const campos = [
      this.codClienteControl,
      this.dataEntregaControl,
      this.freteControl,
      this.CdEntregaControl,
      this.vendedorControl,
      this.condicaoPagamentoControl,
      this.formaPagamentoControl,
    ];

    campos.forEach((control) => {
      control.valueChanges.subscribe(() => {
        this.validarCabecalho();
      });
    });

    const camposPrice = [
      this.faixaCompraControl,
      this.sanatizacaoProdutoControl,
    ];

    camposPrice.forEach((control) => {
      control.valueChanges.subscribe(() => {
        this.validarPriceValid();
      });
    });

    const camposProductItem = [
      this.nItemControl,
      this.descricaoProdutoControl,
      this.quantidadeAplicacoesControl,
      this.doseBiologicaControl,
      this.numeroHectaresControl,
      this.precoUnitarioControl,
      this.quantidadeControl,
      this.descontoControl,
      this.codControl,
      this.aplicacaoControl,
      this.culturaControl,
      this.dataEntregaControl,
    ];

    camposProductItem.forEach((control) => {
      control.valueChanges.subscribe(() => {
        this.validarProductItem();
      });
    });

    this.sanatizacaoProdutoControl.valueChanges
      .pipe(filter((val) => !!val))
      .subscribe(() => {
        this.reprocessarItensComNovaSanitizacao();
      });

    this.changeDetectorRef.detectChanges();
  }

  private _filterItemDescriptions(name: string): any[] {
    const filterValue = name.toLowerCase();
    return this.items.filter(
      (item) =>
        (item.ItemCode && item.ItemCode.toLowerCase().includes(filterValue)) ||
        (item.ItemName || '').toLowerCase().includes(filterValue)
    );
  }

  private _filterCulturas(name: string): any[] {
    const filterValue = name ? name.toLowerCase() : '';
    return this.culturas.filter(
      (cultura) =>
        cultura.Name && cultura.Name.toLowerCase().includes(filterValue)
    );
  }

  private _filterCondicoesPagamento(nome: string) {
    const filterValue = nome.toLowerCase();
    return this.condicoesPagamento.filter((condicao) =>
      condicao.PymntGroup.toLowerCase().includes(filterValue)
    );
  }

  private _filterFormasPg(nome: string) {
    const filterValue = nome.toLowerCase();
    return this.formasPagamento.filter((forma) =>
      forma.CPAGAMENTO.toLowerCase().includes(filterValue)
    );
  }

  private _filterClientes(value: string): any[] {
    const filterValue = value.toLowerCase();

    // Filtra o array de clientes com base nos atributos especificados
    return this.clientes.filter(
      (cliente) =>
        cliente.CardCode.toLowerCase().includes(filterValue) ||
        cliente.Nome_do_PN.toLowerCase().includes(filterValue) ||
        cliente.CNPJ_CPF.toLowerCase().includes(filterValue) ||
        cliente.U_CADF_Fazenda.toLowerCase().includes(filterValue) ||
        cliente.Inscricao_Estadual.toLowerCase().includes(filterValue)
    );
  }
  private _filterVendedores(nome: string): any[] {
    const filterValue = nome.toLowerCase();
    return this.vendedores.filter((vendedor) =>
      vendedor.SlpName.toLowerCase().includes(filterValue)
    );
  }

  private _filterCdsEntrega(nome: string): any[] {
    const filterValue = nome.toLowerCase();
    return this.cdsEntrega.filter((cdEntrega) =>
      cdEntrega.BPLName.toLowerCase().includes(filterValue)
    );
  }

  async carregarClientes(): Promise<void> {
    try {
      const response = await this.http
        .get<any>(this.apiUrl + '/api/consulta/listaClientes')
        .toPromise();
      this.clientes = response.listaClientes;
      this.clientesFiltrados.next(this.clientes); // Inicia com todos os clientes
    } catch (error) {
      console.error('Erro ao carregar clientes:', error);
    }
  }

  onChanges(): void {
    this.filtroForm.valueChanges.subscribe((val) => {
      this.filtrarClientes('');
    });

    this.filtroForm.valueChanges.subscribe(() => {});
    this.changeDetectorRef.detectChanges();
  }

  private filtrarClientes(searchTerm: string = ''): any[] {
    const filterValue = searchTerm.toLowerCase();
    return this.clientes.filter(
      (cliente) =>
        (cliente.CardCode &&
          cliente.CardCode.toLowerCase().includes(filterValue)) ||
        (cliente.Nome_do_PN &&
          cliente.Nome_do_PN.toLowerCase().includes(filterValue)) ||
        (cliente.CNPJ_CPF &&
          cliente.CNPJ_CPF.toLowerCase().includes(filterValue)) ||
        (cliente.U_CADF_Fazenda &&
          cliente.U_CADF_Fazenda.toLowerCase().includes(filterValue)) ||
        (cliente.Inscricao_Estadual &&
          cliente.Inscricao_Estadual.toLowerCase().includes(filterValue))
    );
  }

  selecionarCliente(event: any) {
    const clienteSelecionado = event.option.value;
    this.codClienteControl.setValue(clienteSelecionado.CardCode);
    this.clienteControl.setValue(clienteSelecionado.Nome_do_PN);
    this.cpfControl.setValue(clienteSelecionado.CNPJ_CPF);
    this.telefoneControl.setValue(clienteSelecionado.Telefone);
    this.fazendaControl.setValue(clienteSelecionado.U_CADF_Fazenda);
    this.inscricaoControl.setValue(clienteSelecionado.Inscricao_Estadual);
    this.enderecoControl.setValue(clienteSelecionado.Address2);
    this.localEntregaControl.setValue(clienteSelecionado.U_CADF_Fazenda);
  }

  displayCliente(cliente: any): string {
    return cliente ? `${cliente.Nome_do_PN}` : '';
  }

  cancel(): void {
    this.indiceItemEditando = null;
    this.limparCamposNovoItem();
    this.matDialogRef.close(false);
    this.changeDetectorRef.detectChanges();
  }

  carregarOrcamentos(): void {
    this.loading = true;
    this.openService.obterAtCard().subscribe(
      (numbersCard) => {
        this.numberCard = numbersCard.map((card) => card.DocEntry.toString());
        this.numberCardFiltrado = [...this.numberCard];
        this.ultimoNumAtCard = this.numberCard[this.numberCard.length - 1];

        this.contadorPedido = 0;
        this.loading = false;

        this.separarStringDoNumero(this.ultimoNumAtCard);

        this.contadorPedido = 0;
        this.loading = false;
      },
      (error) => {
        console.error('Erro ao carregar orçamentos:', error);
        this.loading = false;
      }
    );
  }
  separarStringDoNumero(numAtCard: string): void {
    const numeros = numAtCard.replace(/\D/g, '');
  }

  async carregarItems(): Promise<void> {
    try {
      this.loading = true;
      const response = await this.openService.carregarItens().toPromise(); // Chamada ao serviço
      this.items = response; // Armazena os itens para exibição
      this.itemsFiltrados = [...this.items];
      this.loading = false;
    } catch (error) {
      console.error('Erro ao carregar itens:', error);
      this.loading = false;
    }
  }

  onNewItemInput() {
    if (this.novoItem.nItem || this.novoItem.descricaoProduto) {
      this.dadosTabela.push(this.novoItem);
      this.novoItem = {};
    }
  }

  private _filterItems(name: string): any[] {
    const filterValue = name.toLowerCase();
    return this.items.filter(
      (item) =>
        (item.ItemCode && item.ItemCode.toLowerCase().includes(filterValue)) ||
        (item.ItemName || '').toLowerCase().includes(filterValue)
    );
  }

  displayItem(item: any): string {
    return item
      ? item.ItemCode && item.ItemName
        ? `${item.ItemCode} - ${item.ItemName}`
        : item.ItemCode || item.ItemName || ''
      : '';
  }

  async preencherPrecoUnitario(itemCode: string, item: any): Promise<void> {
    try {
      const faixaSelecionada = this.faixaCompraControl.value;
      if (!faixaSelecionada || !faixaSelecionada.description) {
        console.warn('Nenhuma faixa de compra válida selecionada.');
        item.precoUnitario = '0';
        return;
      }

      const faixaSelecionadaValor = faixaSelecionada.description;

      const inoculumsResponse = await this.openService
        .obterPrecosInoculums()
        .toPromise();
      const cultureMediaResponse = await this.openService
        .obterPrecosCultureMedia()
        .toPromise();
      const combinedPrices = [...inoculumsResponse, ...cultureMediaResponse];

      const matchingItem = combinedPrices.find(
        (p) =>
          p.itemcode === itemCode &&
          p.price_ranges.some(
            (range) =>
              this.verificarFaixaDeConsumo(
                range.consumption_range,
                faixaSelecionadaValor
              ) && range.price_type === 'Preço/Unidade'
          )
      );

      if (matchingItem) {
        const matchingRange = matchingItem.price_ranges.find(
          (range) =>
            this.verificarFaixaDeConsumo(
              range.consumption_range,
              faixaSelecionadaValor
            ) && range.price_type === 'Preço/Unidade'
        );
        item.precoUnitario = this.formatarValorMonetario(
          matchingRange?.value || 0
        );
      } else {
        item.precoUnitario = this.formatarValorMonetario(0);
      }
    } catch (error) {
      console.error('Erro ao preencher Preço Unitário:', error);
      item.precoUnitario = '0';
    }
  }

  async preencherCustoKgL(itemCode: string, item: any) {
    try {
      const faixaSelecionada = this.faixaCompraControl.value;

      if (!faixaSelecionada || !faixaSelecionada.description) {
        console.warn('Nenhuma faixa de compra válida selecionada.');
        item.custoKgL = ''; // Atualiza diretamente no item
        return;
      }

      const faixaSelecionadaValor = faixaSelecionada.description;
      const inoculumsResponse = await this.openService
        .obterPrecosInoculums()
        .toPromise();
      const cultureMediaResponse = await this.openService
        .obterPrecosCultureMedia()
        .toPromise();

      const combinedPrices = [...inoculumsResponse, ...cultureMediaResponse];

      const matchingItem = combinedPrices.find(
        (p) =>
          p.itemcode === itemCode &&
          p.price_ranges.some(
            (range) =>
              this.verificarFaixaDeConsumo(
                range.consumption_range,
                faixaSelecionadaValor
              ) && range.price_type === 'Preço/L Kit'
          )
      );

      if (matchingItem) {
        const matchingRange = matchingItem.price_ranges.find(
          (range) =>
            this.verificarFaixaDeConsumo(
              range.consumption_range,
              faixaSelecionadaValor
            ) && range.price_type === 'Preço/L Kit'
        );

        item.custoKgL = matchingRange ? matchingRange.value : ''; // Atualiza no item
      } else {
        console.warn(
          'Nenhum item correspondente encontrado para ItemCode:',
          itemCode
        );
        item.custoKgL = ''; // Limpa o campo se não houver correspondência
      }
    } catch (error) {
      console.error('Erro ao preencher o Custo Kg/L:', error);
      item.custoKgL = '';
    }
  }

  private verificarFaixaDeConsumo(
    consumptionRange: string,
    faixaSelecionada: string
  ): boolean {
    if (!consumptionRange || !faixaSelecionada) return false;

    // Remove espaços e converte para minúsculas para comparação
    const faixaNormalizada = faixaSelecionada.toLowerCase().replace(/\s+/g, '');
    const rangeNormalizado = consumptionRange.toLowerCase().replace(/\s+/g, '');

    // Verifica se a faixa selecionada é compatível com o range da tabela
    if (
      faixaNormalizada.includes('acima') &&
      rangeNormalizado.includes('acima')
    ) {
      return faixaNormalizada === rangeNormalizado;
    } else if (
      faixaNormalizada.includes('até') &&
      rangeNormalizado.includes('até')
    ) {
      const valorSelecionado =
        parseFloat(faixaNormalizada.match(/(\d+)[mk]/)?.[1] || '0') * 1000;
      const valorRange =
        parseFloat(rangeNormalizado.match(/(\d+)[mk]/)?.[1] || '0') * 1000;
      return valorSelecionado === valorRange;
    }
    return false;
  }

  async selecionarProduto(event: any, item: any) {
    const itemSelecionado = this.itemsFiltrados.find(
      (product) =>
        product.ItemCode === event.option.value ||
        product.ItemName === event.option.value
    );

    if (itemSelecionado) {
      // Atualiza propriedades do item principal
      item.nItem = itemSelecionado.ItemCode;
      item.descricaoProduto = itemSelecionado.ItemName;
      item.precoUnitario = this.currencyPipe.transform(itemSelecionado.Price);

      await this.preencherCustoKgL(itemSelecionado.ItemCode, item);
      await this.preencherPrecoUnitario(itemSelecionado.ItemCode, item);
      this.calcularCustoTotalHaBiologicoParaItem(item);

      // Gerenciar Kits Complementares

      this.changeDetectorRef.detectChanges();
    } else {
      console.warn(
        'Nenhum item encontrado com o valor selecionado:',
        event.option.value
      );
    }
  }

  calcularValorTotal(item: any): number {
    // Extrai a quantidade como número
    const quantidade = parseFloat(item.quantidade) || 0;

    // Formata o preço unitário, garantindo que seja um número decimal válido
    const precoUnitario =
      parseFloat(
        (item.precoUnitario || '0')
          .toString() // Garante que o valor seja tratado como string antes de manipular
          .replace(/\./g, '') // Remove separadores de milhar (ex.: "7.213,00")
          .replace(',', '.') // Converte vírgula decimal para ponto (ex.: "7213,00" -> "7213.00")
      ) || 0;

    // Adiciona validação para evitar erros
    if (isNaN(precoUnitario) || precoUnitario <= 0) {
      console.warn(
        `Preço unitário inválido para o item ${item.nItem}:`,
        item.precoUnitario
      );
      return 0; // Retorna 0 para evitar cálculos incorretos
    }

    // Calcula o valor total
    const valorTotal = quantidade * precoUnitario;

    // Atualiza o item com o valor total calculado
    item.valorTotal = valorTotal.toFixed(2); // Armazena como string formatada (opcional)

    // Retorna o valor total como número
    return valorTotal;
  }

  formatarValorMonetario(valor: any): string {
    if (!valor) return 'R$ 0,00';

    // Remove caracteres não numéricos exceto ponto e vírgula
    let valorLimpo = valor.toString().replace(/[^0-9,.-]/g, '');

    // Converte para número
    let valorNumerico = parseFloat(valorLimpo.replace(',', '.'));

    // Formata o valor como moeda brasileira
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(valorNumerico);
  }

  converterValorMonetarioParaNumero(valor: string): number {
    try {
      if (!valor) return 0;
      const valorLimpo = valor.replace(/[^0-9,.-]/g, '');
      const numero = parseFloat(valorLimpo.replace('.', '').replace(',', '.'));
      return isNaN(numero) ? 0 : numero;
    } catch (error) {
      console.error('Erro ao converter valor monetário:', error);
      return 0;
    }
  }
  recalcularValorTotal(item: any): void {
    const quantidade = parseFloat(item.quantidade) || 0;
    const precoUnitario = this.converterValorMonetarioParaNumero(
      item.precoUnitario
    );
    const valorTotal = quantidade * precoUnitario;

    item.valorTotal = this.formatarValorMonetario(valorTotal);
  }

  recalcularValorTotalOnFarm(item: any): void {
    const quantidade = parseFloat(item.quantity) || 0;
    const precoUnitario = this.converterValorMonetarioParaNumero(
      item.unit_price
    );
    const valorTotal = quantidade * precoUnitario;

    item.total_cost = this.formatarValorMonetario(valorTotal);
  }

  calcularSomaValorTotalItens(): void {
    const valorTotalItensTabela = this.dadosTabela.reduce((total, item) => {
      return total + this.converterValorMonetarioParaNumero(item.valorTotal);
    }, 0);

    const valorTotalFormulario = this.converterValorMonetarioParaNumero(
      this.newValorTotalControl.value
    );
    this.somaValorTotalItens = valorTotalFormulario + valorTotalItensTabela;
  }

  validarERecalcularValorTotal(): void {
    const quantidade = parseFloat(this.quantidadeControl.value) || 0;
    const precoUnitario = this.converterValorMonetarioParaNumero(
      this.precoUnitarioControl.value
    );

    if (quantidade > 0 && precoUnitario > 0) {
      const valorTotal = quantidade * precoUnitario;
      this.newValorTotalControl.setValue(
        this.formatarValorMonetario(valorTotal)
      );
    } else {
      this.newValorTotalControl.setValue(this.formatarValorMonetario(0));
    }
  }

  async selecionarProdutoNovoItem(event: any) {
    const itemSelecionado = this.itemsFiltrados.find(
      (product) =>
        product.ItemCode === event.option.value ||
        product.ItemName === event.option.value
    );

    if (itemSelecionado) {
      // Atualiza os controles de formulário diretamente para um novo item
      this.nItemControl.setValue(itemSelecionado.ItemCode, {
        emitEvent: false,
      });
      this.descricaoProdutoControl.setValue(itemSelecionado.ItemName, {
        emitEvent: false,
      });

      const novoItem = {
        nItem: itemSelecionado.ItemCode,
        descricaoProduto: itemSelecionado.ItemName,
        codVenda: this.codControl.value,
        aplicacao: this.aplicacaoControl.value,
        cultura: this.culturaControl.value,
        dataEntrega: this.dataEntregaControl.value,
        precoUnitario: '',
        custoKgL: '',
      };

      try {
        await this.preencherCustoKgL(itemSelecionado.ItemCode, novoItem);
        this.custoKgLControl.setValue(novoItem.custoKgL, { emitEvent: false });

        await this.preencherPrecoUnitario(itemSelecionado.ItemCode, novoItem);
        this.precoUnitarioControl.setValue(novoItem.precoUnitario);
      } catch (error) {
        console.error(
          'Erro ao preencher os valores ou buscar Inoculum:',
          error
        );
      }
    } else {
      console.warn(
        'Nenhum item encontrado com o valor selecionado:',
        event.option.value
      );
    }

    this.changeDetectorRef.detectChanges();
  }

  isItemPrincipalCompleto(itemPrincipal: any): boolean {
    return !!(
      itemPrincipal.codVenda &&
      itemPrincipal.aplicacao &&
      itemPrincipal.cultura &&
      itemPrincipal.dataEntrega &&
      itemPrincipal.numeroOC
    );
  }

  async atualizarKitsComplementares(
    itemCodePrincipal: string,
    quantidadeItemPrincipal: number,
    itemPrincipal: any
  ) {
    try {
      // Carregar as informações de inoculums e meios de cultura
      const inoculums = await this.openService
        .obterListaInoculums()
        .toPromise();
      const meiosDeCultura = await this.openService
        .obterMeiosDeCultura()
        .toPromise();

      const inoculumRelacionado = inoculums.find(
        (inoculum) => inoculum.itemcode === itemCodePrincipal
      );

      if (inoculumRelacionado && inoculumRelacionado.cultureMedia) {
        const itemCultureMedia = this.itemsFiltrados.find(
          (product) =>
            product.ItemCode === inoculumRelacionado.cultureMedia.itemcode
        );

        if (itemCultureMedia) {
          const meioDeCultura = meiosDeCultura.find(
            (meio) => meio.itemcode === itemCultureMedia.ItemCode
          );

          if (meioDeCultura) {
            // Calcula a nova quantidade para os kits complementares
            const quantidadeKitsComplementares =
              quantidadeItemPrincipal * (meioDeCultura.rule_qtd_mult || 1);

            // Buscar preço unitário do kit complementar
            const faixaSelecionada =
              this.faixaCompraControl.value?.description || '';
            const faixaSelecionadaValor = faixaSelecionada.match(/\d+k/)?.[0];
            const combinedPrices = [
              ...(await this.openService.obterPrecosInoculums().toPromise()),
              ...(await this.openService.obterPrecosCultureMedia().toPromise()),
            ];

            const matchingPrice = combinedPrices.find(
              (p) =>
                p.itemcode === itemCultureMedia.ItemCode &&
                p.price_ranges.some(
                  (range) =>
                    range.consumption_range.includes(faixaSelecionadaValor) &&
                    range.price_type === 'Preço/Unidade Meio de Cultura'
                )
            );

            const precoUnitario =
              matchingPrice?.price_ranges.find(
                (range) =>
                  range.consumption_range.includes(faixaSelecionadaValor) &&
                  range.price_type === 'Preço/Unidade Meio de Cultura'
              )?.value || 0;

            console.log('item.itemPrincipal', itemCodePrincipal);
            console.log('dadosTabela', this.dadosTabela);

            // Atualiza ou adiciona o kit complementar na tabela de dados
            const kitExistente = this.dadosTabela.find(
              (item) =>
                item.nItem === itemCultureMedia.ItemCode &&
                item.itemPrincipal === itemCodePrincipal
            );

            if (kitExistente) {
              kitExistente.quantidade = quantidadeKitsComplementares;
              kitExistente.precoUnitario = precoUnitario;

              // Propagar valores do item principal
              kitExistente.codVenda = itemPrincipal.codVenda;
              kitExistente.aplicacao = itemPrincipal.aplicacao;
              kitExistente.cultura = itemPrincipal.cultura;
              kitExistente.dataEntrega = itemPrincipal.dataEntrega;

              this.recalcularValorTotal(kitExistente); // Recalcula o valor total do kit existente
            } else {
              const novoKit = {
                nItem: itemCultureMedia.ItemCode,
                descricaoProduto: itemCultureMedia.ItemName,
                quantidade: quantidadeKitsComplementares,
                precoUnitario: precoUnitario,
                valorTotal: 0, // Será recalculado abaixo
                tipoGrupo: 'kitComplementar',
                itemPrincipal: itemCodePrincipal,
                // Propagar valores do item principal
                codVenda: itemPrincipal.codVenda,
                aplicacao: itemPrincipal.aplicacao,
                cultura: itemPrincipal.cultura,
                dataEntrega: itemPrincipal.dataEntrega,
              };

              this.recalcularValorTotal(novoKit); // Recalcula o valor total do novo kit
              this.dadosTabela.push(novoKit);
            }
          } else {
            console.warn(
              `Meio de Cultura não encontrado para ItemCode: ${itemCultureMedia.ItemCode}`
            );
          }
        } else {
          console.warn(
            'Nenhum CultureMedia associado encontrado para o ItemCode:',
            inoculumRelacionado.cultureMedia.itemcode
          );
        }
      }
    } catch (error) {
      console.error('Erro ao atualizar kits complementares:', error);
    }
  }

  get possuiItensComplementares(): boolean {
    return this.dadosTabela?.some(
      (item) => item.tipoGrupo === 'kitComplementar'
    );
  }

  get dadosTabelaFiltrados(): any[] {
    return (
      this.dadosTabela?.filter(
        (item) => item.tipoGrupo === 'kitComplementar'
      ) || []
    );
  }

  async carregarVendedores(): Promise<void> {
    try {
      this.loading = true;
      const response = await this.http
        .get<any>(this.apiUrl + '/api/consulta/listaVendedores')
        .toPromise();
      this.vendedores = response.listaVendedores;
      this.vendedoresFiltrados = [...this.vendedores];
      this.loading = false;
    } catch (error) {
      console.error('Erro ao carregar clientes:', error);
      this.loading = false;
    }
  }

  carregarFaixasDeCompra(): void {
    this.openService.obterFaixasDeCompra().subscribe(
      (faixas) => {
        this.faixasDeCompra = faixas;
      },
      (error) => {
        console.error('Erro ao carregar faixas de compra mínima:', error);
      }
    );
  }

  selecionarVendedor(event: any) {
    this.vendedor = event.option.value;
    this.vendedorControl.setValue(this.vendedor.SlpName);
    this.SlpCodeSelecionado = this.vendedor.SlpCode;
  }

  carregarFretes(): void {
    this.loading = true;
    this.openService.carregarFrete().subscribe(
      (fretes) => {
        this.fretes = fretes;
        this.fretesFiltrados = [...this.fretes];
        this.loading = false;
      },
      (error) => {
        console.error('Erro ao carregar lista de fretes: ', error);
        this.loading = false;
      }
    );
  }

  private async carregarRegrasDeCalculo(): Promise<void> {
    try {
      this.regrasDeCalculo = await this.openService
        .obterRegrasDeCalculo()
        .toPromise();
    } catch (error) {
      console.error('Erro ao carregar regras de cálculo:', error);
    }
  }
  calcularQuantidadeKits(
    itemCode: string,
    quantidadeAplicacoes: number,
    doseBiologica: number,
    numeroHectares: number
  ): number {
    // Verificar se os valores são válidos
    quantidadeAplicacoes = Number(quantidadeAplicacoes) || 0;
    doseBiologica = Number(doseBiologica) || 0;
    numeroHectares = Number(numeroHectares) || 0;

    // Buscar a regra específica do item
    const regra = this.regrasDeCalculo.find((r) => r.item_code === itemCode);

    if (!regra) {
      console.warn(
        `Regra de cálculo não encontrada para o item ${itemCode}. Usando divisor padrão de 500.`
      );
    }

    const divisor = regra ? parseFloat(regra.divisor) : 500;

    // Realizar o cálculo
    const quantidadeKits =
      (quantidadeAplicacoes * doseBiologica * numeroHectares) / divisor;

    return Math.ceil(quantidadeKits); // Arredondamento para cima
  }

  calcularCustoTotalHaBiologicoParaItem(item: any): void {
    const quantidadeAplicacoes =
      Number(this.quantidadeAplicacoesControl.value) || 0;
    const doseBiologica = Number(this.doseBiologicaControl.value) || 0;
    const custoKgL = this.converterValorMonetarioParaNumero(item.custoKgL) || 0;

    if (quantidadeAplicacoes && doseBiologica && custoKgL) {
      const valor = quantidadeAplicacoes * doseBiologica * custoKgL;
      item.custoTotalHaBiologico = this.formatarValorMonetario(valor);
    } else {
      item.custoTotalHaBiologico = 'R$ 0,00';
    }
  }

  calcularCustoTotalHaBiologico(): void {
    const quantidadeAplicacoes =
      Number(this.quantidadeAplicacoesControl.value) || 0;
    const doseBiologica = Number(this.doseBiologicaControl.value) || 0;
    const custoKgL =
      this.converterValorMonetarioParaNumero(this.custoKgLControl.value) || 0;

    if (quantidadeAplicacoes && doseBiologica && custoKgL) {
      const custoTotalHaBiologico =
        quantidadeAplicacoes * doseBiologica * custoKgL;
      this.custoTotalHaBiologicoControl.setValue(
        this.formatarValorMonetario(custoTotalHaBiologico)
      );
    } else {
      this.custoTotalHaBiologicoControl.setValue('R$ 0,00');
    }
  }

  verificarSanatizacao(newValue: string): void {
    if (newValue === 'QUALYFARM BACTÉRIAS' || newValue === 'QUALYFARM FUNGOS') {
      this.dadosTabela = this.dadosTabela.filter(
        (item) => item.adicionadoPor !== 'selecionarItensPadrao'
      );
      this.atualizarCalculos();
      this.changeDetectorRef.detectChanges();
    }
  }

  async carregarTiposSanitizacao(): Promise<void> {
    try {
      const inoculums = await this.openService
        .obterListaInoculums()
        .toPromise();

      // Extrair valores únicos de "sanitization_type" e filtrar nulos
      this.tiposSanitizacao = [
        ...new Set(
          inoculums
            .map((item: any) => item.sanitization_type)
            .filter((tipo: string | null) => tipo !== null)
        ),
      ];
    } catch (error) {
      console.error('Erro ao carregar tipos de sanitização:', error);
    }
  }

  selecionarFrete(event: any): void {
    const descricaoFreteSelecionado = this.freteControl.value;
    const freteSelecionado = this.fretes.find(
      (frete) => frete.DESCRICAO === descricaoFreteSelecionado
    );

    if (freteSelecionado) {
      this.freteControl.setValue(freteSelecionado.DESCRICAO);
      this.freteCodigo = freteSelecionado.CODIGO;
    }
  }

  async carregarCdsEntrega(): Promise<any[]> {
    try {
      this.loading = true;
      const response = await this.openService.carregarCdsEntrega().toPromise(); // Substituir firstValueFrom
      this.cdsEntrega = response;
      this.cdsEntregaFiltrados = [...this.cdsEntrega];
      this.loading = false;
      return this.cdsEntrega; // Retorna a lista para uso posterior
    } catch (error) {
      console.error('Erro ao carregar CDs de Entrega:', error);
      this.loading = false;
      return []; // Retorna uma lista vazia em caso de erro
    }
  }

  selecionarCdEntrega(event: any) {
    this.cdEntrega = event.option.value;
    this.CdEntregaControl.setValue(this.cdEntrega.BPLName);
    this.BPLIdSelecionado = this.cdEntrega.BPLId;
  }

  async carregarMeioCultura(): Promise<void> {
    try {
      this.loading = true;
      const response = await this.openService.carregarCultura().toPromise();
      this.culturas = response || [];
      this.culturasFiltradas = [...this.culturas];
    } catch (error) {
      console.error('Erro ao carregar meios de cultura:', error);
    } finally {
      this.loading = false;
    }
  }

  carregarCondicaoPg(): void {
    this.loading = true;
    this.openService.condicaoPagamento().subscribe(
      (condicoespg) => {
        this.condicoesPagamento = condicoespg;

        this.condicoesPagamentoFiltradas = [...this.condicoesPagamento];
        this.loading = false;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  selecionarCondicaoPagamento(event: any): void {
    const condicaoSelecionada = event.option.value;
    const condicaoEncontrada = this.condicoesPagamentoFiltradas.find(
      (condicao) => condicao.PymntGroup === condicaoSelecionada
    );

    if (condicaoEncontrada) {
      this.condicaoPagamentoControl.setValue(condicaoEncontrada.PymntGroup);
      this.GroupNumSelecionado = condicaoEncontrada.GroupNum;
    }
  }

  carregarFormasPg(): void {
    this.loading = true;
    this.openService.pagamentoForma().subscribe(
      (formaspg) => {
        this.formasPagamento = formaspg;
        this.formasPagamentoFiltradas = [...this.formasPagamento];
        this.loading = false;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  filtrarFormasPagamento(): void {
    const { FPAGAMENTO } = this.filtroForm.value;
    this.formasPagamentoFiltradas = this.formasPagamento.filter((forma) => {
      return FPAGAMENTO
        ? forma.FPAGAMENTO.toLowerCase().includes(FPAGAMENTO.toLowerCase())
        : true;
    });
  }
  selecionarFormaPagamento(event: any) {
    this.formaPagamentoSelecionada = event.option.value;
    this.PayMethCodSelecionado = this.formaPagamentoSelecionada.FPAGAMENTO;
    this.formaPagamentoControl.setValue(this.PayMethCodSelecionado);
  }

  displayFormaPg(forma?: any): string {
    return forma ? forma.FPAGAMENTO : '';
  }

  get selectUtilizado() {
    return false;
  }

  gerarCodPedido(event: any): void {
    const opcaoSelecionada = event.value;

    const partes = opcaoSelecionada.split(' ');

    console.log(partes);

    const codigoVenda = partes[1];
    const numCard = this.numberCardFiltrado.shift();

    console.log(numCard);

    let numCardValido = numCard ? numCard : '';
    if (numCardValido.length > 0) {
      const numCardArray = numCardValido.split('');
      const indexParaModificar = numCardArray.length - 1;

      if (indexParaModificar >= 0) {
        const novoDigito =
          (parseInt(numCardArray[indexParaModificar]) +
            this.contadorPedido +
            2) %
          10;
        numCardArray[indexParaModificar] = novoDigito.toString();
        numCardValido = numCardArray.join('');
      }
    }

    const codigoPedido = `${codigoVenda}${numCardValido}`;
    console.log('codigoPedido', codigoPedido);
    this.codPedidoControl.setValue(codigoPedido);
  }

  adicionarCadastro() {
    const codPedido = this.codPedidoControl.value;
    const codCliente = this.codClienteControl.value;
    const cliente = this.clienteControl.value;
    const cpf_cnpj = this.cpfControl.value;
    const telefone = this.telefoneControl.value;
    const fazenda = this.fazendaControl.value;
    const inscricaoEstadual = this.inscricaoControl.value;
    const endereco = this.enderecoControl.value;
    const cod = this.codControl.value;
    const vendedor = this.vendedorControl.value;
    const formaPg = this.formaPagamentoControl.value;
    const condicaoPg = this.GroupNumSelecionado;
    const localEntrega = this.fazendaControl.value;
    const frete = this.freteCodigo;
    const cdEntrega = this.CdEntregaControl.value;
    const observacoes = this.observacoescadControl.value;

    const cadastro: Cadastro = {
      codPedido: codPedido,
      codCliente: codCliente,
      cliente: cliente,
      cpf_cnpj: cpf_cnpj,
      telefone: telefone,
      fazenda: fazenda,
      inscricaoEstadual: inscricaoEstadual,
      endereco: endereco,
      cod: cod,
      vendedor: vendedor,
      formaPg: formaPg,
      condicaoPg: condicaoPg,
      localEntrega: localEntrega,
      frete: frete,
      cdEntrega: cdEntrega,
      observacoes: observacoes,
    };

    this.cabecalho.push(cadastro);
    this.changeDetectorRef.detectChanges();
  }

  async enviarDadosParaAPI() {
    if (
      this.nItemControl.value ||
      this.descricaoProdutoControl.value ||
      this.quantidadeControl.value
    ) {
      const novoItem = {
        nItem: this.nItemControl.value,
        descricaoProduto: this.descricaoProdutoControl.value,
        custoKgL: this.formatarValorMonetario(this.custoKgLControl.value),
        custoTotalHaBiologico: this.formatarValorMonetario(
          this.custoTotalHaBiologicoControl.value
        ),
        precoUnitario: this.formatarValorMonetario(
          this.precoUnitarioControl.value
        ),
        quantidade: this.quantidadeControl.value,
        desconto: this.descontoControl.value,
        codVenda: this.codControl.value,
        aplicacao: this.aplicacaoControl.value,
        cultura: this.culturaControl.value,
        dataEntrega: this.dataEntregaControl.value,
        numeroOC: this.numeroOCControl.value,
      };

      const novoItemOnFarm = {
        item_code: this.nItemControl.value,
        item_name: this.descricaoProdutoControl.value,
        application_number: this.quantidadeAplicacoesControl.value,
        biological_dose: this.doseBiologicaControl.value,
        hectares_number: this.numeroHectaresControl.value,
        kit_number: this.nrKitControl.value,
        cost_per_kg: this.custoKgLControl.value,
        total_cost: this.formatarValorMonetario(this.custoKgLControl.value),
        total_cost_per_ha: this.formatarValorMonetario(
          this.custoTotalHaBiologicoControl.value
        ),
        unit_price: this.formatarValorMonetario(
          this.precoUnitarioControl.value
        ),
        quantity: this.quantidadeControl.value,
        discount: this.descontoControl.value,
        sale_code: this.codControl.value,
        application: this.aplicacaoControl.value,
        culture: this.culturaControl.value,
        delivery_date: this.dataEntregaControl.value,
        oc_number: this.numeroOCControl.value,
        warehouse_code: this.BPLIdSelecionado,
      };

      this.dadosTabela.push(novoItem);
      this.dadosTabelaOnFarm.push(novoItemOnFarm);

      this.nItemControl.reset();
      this.descricaoProdutoControl.reset();
      this.custoKgLControl.reset();
      this.custoTotalHaBiologicoControl.reset();
      this.precoUnitarioControl.reset();
      this.quantidadeControl.reset();
      this.descontoControl.reset();
      this.codControl.reset();
      this.aplicacaoControl.reset();
      this.culturaControl.reset();
      this.dataEntregaControl.reset();
      this.numeroOCControl.reset();
    }

    /*const codPedido = this.codPedidoControl.value;
    const codCliente = this.codClienteControl.value;
    const dataVenc = this.dataVencimentoControl.value;
    const CdEntrega = this.BPLIdSelecionado;
    const vendedor = this.SlpCodeSelecionado;
    const observacao = this.observacoescadControl.value;
    const metodopg = this.formaPagamentoControl.value;
    const condicaoPg = this.GroupNumSelecionado;
    const frete = this.freteCodigo;
    const endereco = this.enderecoControl.value;*/

    const codPedido = this.codPedidoControl.value;
    const codCliente = this.codClienteControl.value;
    const clienteNome = this.clienteControl.value;
    const clienteCpf = this.cpfControl.value;
    const clienteIe = this.inscricaoControl.value;
    const clienteFazenda = this.fazendaControl.value;
    const clienteTelefone = this.telefoneControl.value;
    const clienteEndereco = this.enderecoControl.value;
    const dataVenc = this.dataVencimentoControl.value;
    const CdEntrega = this.BPLIdSelecionado;
    const vendedor = this.SlpCodeSelecionado;
    const observacao = this.observacoescadControl.value;
    const metodopg = this.formaPagamentoControl.value;
    const condicaoPg = this.GroupNumSelecionado;
    const frete = this.freteCodigo;
    const endereco = this.enderecoControl.value;
    const faixa_price = this.faixaCompraControl.value;
    const sanitizacao = this.sanatizacaoProdutoControl.value;

    const listaFiliais = await this.carregarCdsEntrega();
    const filial = listaFiliais.find((f) => f.BPLId === CdEntrega);
    const warehouseCode = filial ? filial.DflWhs : null;

    console.log('Warehouse:', warehouseCode);

    if (!warehouseCode) {
      console.error('Nenhum DflWhs encontrado para o CdEntrega selecionado.');
      return;
    }

    const documentLines = this.dadosTabela.map((item) => {
      const precoUnitarioNumerico = this.converterValorMonetarioParaNumero(
        item.precoUnitario
      );
      const quantidade = parseFloat(item.quantidade) || 0;
      const desconto = parseFloat(item.desconto) || 0;
      return {
        ItemCode: item.nItem,
        Quantity: quantidade,
        UnitPrice: precoUnitarioNumerico,
        Usage: item.codVenda ? item.codVenda.substring(0, 3) : '',
        U_SOLU_Cultura: item.cultura,
        U_SOLU_TP_Aplicacao: item.aplicacao,
        U_OM_NumberOC: item.numeroOC,
        U_OM_NumberOCLine: null,
        ShipDate: item.dataEntrega,
        DiscountPercent: desconto,
        WarehouseCode: warehouseCode,
      };
    });

    const requestBody = {
      CardCode: codCliente,
      DocDueDate: dataVenc,
      DocObjectCode: 23,
      BPL_IDAssignedToInvoice: CdEntrega,
      SalesPersonCode: vendedor,
      NumAtCard: codPedido,
      Comments: observacao,
      U_SOLU_MEIO_PAG: metodopg,
      PaymentGroupCode: condicaoPg,
      DiscountPercent: 0.0,
      Address: 'Entrega',
      Address2: endereco,
      DocumentLines: documentLines,
      TaxExtension: {
        Incoterms: frete,
      },
    };

    // Payload Header API OnFarm
    const header = {
      doc_entry: 0,
      card_code: codCliente,
      company_name: clienteNome,
      tax_id: clienteCpf,
      registration_number: clienteIe,
      farm: clienteFazenda,
      phone: clienteTelefone,
      address: clienteEndereco,
      delivery_date: this.toDateOnlyString(dataVenc),
      delivery_code: CdEntrega,
      salesperson_id: vendedor,
      remarks: observacao,
      payment_method: metodopg,
      payment_condition_id: condicaoPg,
      freight: frete,
      purchase_range: faixa_price.id,
      sanitization_type: sanitizacao,
    };

    // Payload API documentLines
    const { header: finalHeader, kit_items } = this.copyTableFromOnFarm(
      warehouseCode,
      header
    );

    //BodyOnFarm
    const requestBodyOnFarm = {
      header: finalHeader,
      kit_items: kit_items,
    };

    this.loading = true;

    try {
      //SAP
      const response = await firstValueFrom(
        this.openService.enviarPedido(requestBody)
      );

      // 3. Atualiza os identificadores do header
      header.doc_entry = response.DocEntry;

      console.log('requestBodyOnFarm', requestBodyOnFarm);

      //OnFarm
      /*const responseOnFarm = await firstValueFrom(
        this.openService.enviarPedidoOnFarm(requestBodyOnFarm)
      );*/

      //Se sucesso
      const successDialogRef = this.dialog.open(DialogEditarComponent, {
        width: '350px',
        panelClass: 'custom-success-dialog',
        data: {
          message: 'Orçamento enviado com sucesso!',
          showSuccessMessage: true,
        },
      });

      this.loading = false;

      successDialogRef.afterClosed().subscribe(() => {
        this.matDialogRef.close(true);
        this.enviarEmail(response.DocEntry);
      });
    } catch (error) {
      this.loading = false;
      console.error('Erro ao enviar dados para a API:', error);
      const errorMessage =
        error.error?.message ||
        'Ocorreu um erro ao enviar os dados para a API.';
      this.dialog.open(DialogEditarComponent, {
        width: '350px',
        panelClass: 'custom-success-dialog',
        data: { message: errorMessage, showSuccessMessage: true },
      });
    }
  }
  enviarEmail(docentry: number) {
    this.openService.enviarPDf(docentry).toPromise();
  }

  verificarEModificarCodVenda(): void {
    const novoCodVenda = this.codControl.value;
    this.dadosTabela.forEach((item) => {
      if (item.cod_venda !== novoCodVenda) {
        item.cod_venda = novoCodVenda;
      }
    });

    this.changeDetectorRef.detectChanges();
  }

  // Função que retorna os dados da linha de inserção
  getDadosLinhaInsercao() {
    return {
      numeroHectares: parseFloat(this.numeroHectaresControl.value) || 0,
      custoTotalHaBiologico:
        parseFloat(this.custoTotalHaBiologicoControl.value) || 0,
    };
  }

  // Função para calcular Total (Investimento por Ha)
  calcularInvestimentoPorHa(): void {
    this.totalInvestimentoPorHa = this.dadosTabela.reduce((total, item) => {
      const custoTotalHaBiologico = this.converterValorMonetarioParaNumero(
        item.custoTotalHaBiologico
      );
      return total + custoTotalHaBiologico;
    }, 0);

    // Add value from current form if it exists
    const formCustoTotal = this.converterValorMonetarioParaNumero(
      this.custoTotalHaBiologicoControl.value
    );
    this.totalInvestimentoPorHa += formCustoTotal;
  }

  calcularInvestimentoTotalNoManejo(): void {
    this.investimentoTotalNoManejo = this.dadosTabela.reduce((total, item) => {
      const custoTotalHaBiologico = this.converterValorMonetarioParaNumero(
        item.custoTotalHaBiologico
      );
      const numeroHectares = Number(item.numeroHectares) || 0;
      return total + custoTotalHaBiologico * numeroHectares;
    }, 0);

    // Add value from current form if it exists
    const formCustoTotal = this.converterValorMonetarioParaNumero(
      this.custoTotalHaBiologicoControl.value
    );
    const formNumeroHectares = Number(this.numeroHectaresControl.value) || 0;
    this.investimentoTotalNoManejo += formCustoTotal * formNumeroHectares;
  }

  atualizarCalculos(): void {
    this.calcularInvestimentoPorHa();
    this.calcularInvestimentoTotalNoManejo();
    this.calcularSomaValorTotalItens();
  }

  atualizarNrKit(): void {
    const itemCode = this.nItemControl.value;
    const quantidadeAplicacoes =
      Number(this.quantidadeAplicacoesControl.value) || 0;
    const doseBiologica = Number(this.doseBiologicaControl.value) || 0;
    const numeroHectares = Number(this.numeroHectaresControl.value) || 0;

    if (itemCode) {
      const quantidadeKits = this.calcularQuantidadeKits(
        itemCode,
        quantidadeAplicacoes,
        doseBiologica,
        numeroHectares
      );
      this.nrKitControl.setValue(quantidadeKits.toString());
      this.quantidadeControl.setValue(Math.ceil(quantidadeKits)); // Atualiza quantidade com valor arredondado
    } else {
      this.nrKitControl.setValue('');
      this.quantidadeControl.setValue(''); // Limpa o campo se o código do item não estiver definido
    }
  }

  async selecionarItensPadrao(
    itemCodePrincipal: string,
    quantidadeItemPrincipal: number,
    codVenda: string,
    aplicacao: string,
    cultura: string,
    dataEntrega: Date,
    sanitizationType: string
  ): Promise<void> {
    try {
      // Remove itens não correspondentes ao novo sanitizationType
      this.dadosTabela = this.dadosTabela.filter(
        (item) =>
          item.sanitization_type === sanitizationType ||
          item.adicionadoPor !== 'selecionarItensPadrao'
      );

      // Obtém os itens padrão da API
      const inoculums = await this.openService
        .obterListaInoculums()
        .toPromise();
      let itensPadrao = inoculums.filter(
        (item) => item.sanitization_type === sanitizationType
      );

      if (sanitizationType === 'QUALYFARM') {
        // Inclui apenas itens com conditions === null ou ""
        itensPadrao = itensPadrao.filter(
          (item) => item.conditions === null || item.conditions === ''
        );

        // Itera pelos itens padrão para aplicar a lógica de substituição do rule_qtd_mult
        itensPadrao = itensPadrao.map((item) => {
          const itemReferente = inoculums.find(
            (refItem) =>
              refItem.itemcode === itemCodePrincipal &&
              refItem.conditions === item.itemcode
          );

          if (itemReferente) {
            return { ...item, rule_qtd_mult: itemReferente.rule_qtd_mult };
          }

          return item;
        });
      }

      // Soma das quantidades dos itens não complementares
      const somaNaoKitComplementar =
        this.dadosTabela
          .filter((item) => item.tipoGrupo !== 'kitComplementar')
          .reduce(
            (soma, item) => soma + (parseFloat(item.quantidade) || 0),
            0
          ) + (parseFloat(this.quantidadeControl.value) || 0);

      // Processar cada item padrão
      for (const item of itensPadrao) {
        const ruleQtdMult = parseFloat(item.rule_qtd_mult) || 1;
        const divisor = parseFloat(item.divisor) || 1;

        // Calcular a quantidade com a nova fórmula
        let quantidadeCalculada = Math.ceil(
          (somaNaoKitComplementar * ruleQtdMult) / divisor
        );

        const itemExistente = this.dadosTabela.find(
          (i) =>
            i.nItem === item.itemcode &&
            i.adicionadoPor === 'selecionarItensPadrao'
        );

        if (itemExistente) {
          // Atualizar a quantidade e recalcular o valor total
          itemExistente.quantidade = quantidadeCalculada;
          this.recalcularValorTotal(itemExistente);
        } else {
          // Adicionar novo item com quantidade calculada
          const novoItem = {
            nItem: item.itemcode,
            descricaoProduto: item.itemname,
            precoUnitario: '',
            custoKgL: '',
            quantidade: quantidadeCalculada,
            desconto: 0,
            valorTotal: 0,
            tipoGrupo: 'kitComplementar',
            adicionadoPor: 'selecionarItensPadrao',
            codVenda: codVenda,
            aplicacao: aplicacao,
            cultura: cultura,
            dataEntrega: dataEntrega,
            sanitization_type: sanitizationType,
            itemCodePrincipal: itemCodePrincipal,
          };

          try {
            await this.preencherCustoKgL(item.itemcode, novoItem);
            await this.preencherPrecoUnitario(item.itemcode, novoItem);
            this.recalcularValorTotal(novoItem);
            this.dadosTabela.push(novoItem);

            this.atualizarKitsComplementares(
              itemCodePrincipal,
              quantidadeItemPrincipal,
              novoItem
            );
          } catch (error) {
            console.error(
              `Erro ao adicionar item padrão ${item.itemcode}:`,
              error
            );
          }
        }
      }

      this.atualizarCalculos();
      this.changeDetectorRef.detectChanges();
    } catch (error) {
      console.error('Erro ao carregar itens padrão:', error);
    }
  }

  private calcularQuantidadeItem(item: any, quantidadeBase: number): number {
    const ruleQtdMult = parseFloat(item.rule_qtd_mult) || 1;
    return Math.ceil(quantidadeBase * ruleQtdMult);
  }

  recalcularNrKit(item: any): void {
    if (
      item.nItem &&
      item.quantidadeAplicacoes &&
      item.doseBiologica &&
      item.numeroHectares
    ) {
      const quantidadeKits = this.calcularQuantidadeKits(
        item.nItem,
        item.quantidadeAplicacoes,
        item.doseBiologica,
        item.numeroHectares
      );

      // Arredonda o valor de Nr Kit para cima e atualiza a quantidade com o mesmo valor
      item.nrKit = quantidadeKits.toString();
      item.quantidade = Math.ceil(quantidadeKits);
    } else {
      item.nrKit = '';
      item.quantidade = ''; // Limpa a quantidade se os valores forem inválidos
    }
  }

  recalcularNrKitOnFarm(item: any): void {
    if (
      item.item_code &&
      item.application_number &&
      item.biological_dose &&
      item.hectares_number
    ) {
      const quantidadeKits = this.calcularQuantidadeKits(
        item.item_code,
        item.application_number,
        item.biological_dose,
        item.hectares_number
      );

      // Arredonda o valor de Nr Kit para cima e atualiza a quantidade com o mesmo valor
      item.kit_number = quantidadeKits.toString();
      item.quantity = Math.ceil(quantidadeKits);
    } else {
      item.kit_number = '';
      item.quantity = ''; // Limpa a quantidade se os valores forem inválidos
    }
  }

  recalcularNovoValorTotal(): void {
    const quantidade = parseFloat(this.quantidadeControl.value) || 0;
    const precoUnitario =
      parseFloat(
        (this.precoUnitarioControl.value || '0')
          .replace(/\./g, '') // Remove separadores de milhar
          .replace(',', '.') // Substitui vírgulas por ponto
      ) || 0;

    if (isNaN(precoUnitario) || precoUnitario <= 0) {
      console.warn(
        `Preço unitário inválido: ${this.precoUnitarioControl.value}`
      );
      this.newValorTotalControl.setValue('0.00');
      return;
    }

    const valorTotal = quantidade * precoUnitario;
    this.newValorTotalControl.setValue(valorTotal.toFixed(2)); // Atualiza o controle com o valor formatado
  }

  limparCamposNovoItem(): void {
    this.nItemControl.reset();
    this.descricaoProdutoControl.reset();
    this.quantidadeAplicacoesControl.reset();
    this.doseBiologicaControl.reset();
    this.numeroHectaresControl.reset();
    this.nrKitControl.reset();
    this.custoKgLControl.reset();
    this.custoTotalHaBiologicoControl.reset();
    this.precoUnitarioControl.reset();
    this.quantidadeControl.reset();
    this.descontoControl.reset();
    this.codControl.reset();
    this.aplicacaoControl.reset();
    this.culturaControl.reset();
    this.dataEntregaControl.reset();
    this.numeroOCControl.reset();
    this.newValorTotalControl.reset();
  }

  scrollToLeft() {
    this.itensScroll.nativeElement.scrollTo({ left: 0, behavior: 'smooth' });
  }

  /*removerItem(item: any): void {
    // Filtrar o item a ser removido
    this.dadosTabela = this.dadosTabela.filter((i) => i !== item);

    console.log('item.nItem', item.nItem);

    // Remover kits complementares associados
    if (item.nItem) {
      this.removerKitsComplementares(item.nItem);
    }

    this.atualizarCalculos();
    this.changeDetectorRef.detectChanges();
  }*/

  /*onBlur(item: any, campo: string): void {
    this.dadosTabela = this.dadosTabela.map((i) => {
      if (i === item) {
        return { ...i, [campo]: item[campo] };
      }
      return i;
    });

    if (campo === 'quantidade' || campo === 'precoUnitario') {
      this.recalcularValorTotal(item);
    }

    this.calcularSomaValorTotalItens();
  }*/

  /**
   * Retornar itens principais da "dadosTabela"
   */
  get itensSemKit(): any[] {
    return (
      this.dadosTabela?.filter((i) => i.tipoGrupo !== 'kitComplementar') || []
    );
  }

  /*selecionarLinha(item: any): void {
    console.log('Item selecionado:', item);
    // Implemente o comportamento necessário aqui
  }*/

  /**
   * Adiciona novo item com base na regra de negocio atual e verifica se o item foi atualizado ou criado
   */
  adicionarNovoItem(): void {
    const novoItem = {
      nItem: this.nItemControl.value,
      descricaoProduto: this.descricaoProdutoControl.value,
      quantidadeAplicacoes: this.quantidadeAplicacoesControl.value,
      doseBiologica: this.doseBiologicaControl.value,
      numeroHectares: this.numeroHectaresControl.value,
      custoKgL: this.custoKgLControl.value,
      custoTotalHaBiologico: this.custoTotalHaBiologicoControl.value,
      precoUnitario: this.precoUnitarioControl.value,
      quantidade: this.quantidadeControl.value,
      desconto: this.descontoControl.value,
      codVenda: this.codControl.value,
      aplicacao: this.aplicacaoControl.value,
      cultura: this.culturaControl.value,
      dataEntrega: this.dataEntregaControl.value,
      numeroOC: this.numeroOCControl.value,
      nrKit: this.nrKitControl.value,
      valorTotal: this.newValorTotalControl.value,
    };

    this.recalcularValorTotal(novoItem);

    if (this.indiceItemEditando !== null) {
      this.dadosTabela.splice(this.indiceItemEditando, 1, novoItem);
      this.indiceItemEditando = null;
      if (!this.itensEditados.includes(novoItem.nItem)) {
        this.itensEditados.push(novoItem.nItem);
      }
      this.changeDetectorRef.detectChanges();
    } else {
      this.dadosTabela = this.dadosTabela.filter(
        (i) => i.nItem !== novoItem.nItem
      );
      this.dadosTabela.unshift(novoItem);
    }

    this.atualizarCalculos();
    this.recalcularNrKit(novoItem);
    this.limparCamposNovoItem();
    this.scrollToLeft();

    console.log(`Item adicionado:`, novoItem);
  }

  /**
   * Validação dos campos de cabeçaho ou HEADER para liberação dos botões de envio do orçamento a base de dados e popula erros de cabeçalho
   */
  validarCabecalho(): void {
    this.camposInvalidosCabecalho = [];

    if (!this.buscaClienteControl.value)
      this.camposInvalidosCabecalho.push('Cliente');
    if (!this.dataVencimentoControl.value)
      this.camposInvalidosCabecalho.push('Data de Entrega');
    if (!this.freteControl.value) this.camposInvalidosCabecalho.push('Frete');
    if (!this.CdEntregaControl.value)
      this.camposInvalidosCabecalho.push('Centro de Distribuição de Entrega');
    if (!this.vendedorControl.value)
      this.camposInvalidosCabecalho.push('Vendedor');
    if (!this.condicaoPagamentoControl.value)
      this.camposInvalidosCabecalho.push('Condição de Pagamento');
    if (!this.formaPagamentoControl.value)
      this.camposInvalidosCabecalho.push('Forma de Pagamento');

    this.dadosCabecalhoValidos = this.camposInvalidosCabecalho.length === 0;
  }

  /**
   * Validação de preenchimento de campos e que é utilizado para liberação dos campos de inclusão de produto
   */
  validarPriceValid(): void {
    this.camposInvalidosPrice = [];

    const campos = [
      this.faixaCompraControl.value,
      this.sanatizacaoProdutoControl.value,
    ];

    this.formPriceIniciado = campos.some((valor) => !!valor);

    if (!this.faixaCompraControl.value)
      this.camposInvalidosPrice.push('Faixa de Compra');
    if (!this.sanatizacaoProdutoControl.value)
      this.camposInvalidosPrice.push('Sanitização do Produto');

    this.dadosPriceValidos = this.camposInvalidosPrice.length === 0;
  }

  /**
   * Condição de amostragem de informação da OC para o usuário
   */
  getNumeroOCSeguro(valor: any): string {
    return typeof valor === 'string' && valor.trim()
      ? valor.trim()
      : typeof valor === 'number'
      ? valor.toString()
      : 'Não Informado';
  }

  /**
   * Validação de preechimewnto de campos e liberação do botão "Adicionar item"
   */
  validarProductItem(): void {
    this.camposInvalidosProduto = [];

    const campos = [
      this.nItemControl.value,
      this.descricaoProdutoControl.value,
      this.quantidadeAplicacoesControl.value,
      this.doseBiologicaControl.value,
      this.numeroHectaresControl.value,
      this.precoUnitarioControl.value,
      this.quantidadeControl.value,
      this.codControl.value,
      this.aplicacaoControl.value,
      this.culturaControl.value,
      this.dataEntregaControl.value,
    ];

    // Verifica se pelo menos um campo foi preenchido
    this.formProdutoIniciado = campos.some((valor) => !!valor);

    // Validação de preenchimento e popula erros user.
    if (!this.nItemControl.value) this.camposInvalidosProduto.push('Nº Item');
    if (!this.descricaoProdutoControl.value)
      this.camposInvalidosProduto.push('Descrição do Produto');
    if (!this.quantidadeAplicacoesControl.value)
      this.camposInvalidosProduto.push('Quantidade de Aplicações');
    if (!this.doseBiologicaControl.value)
      this.camposInvalidosProduto.push('Dose Biológica (L)');
    if (!this.numeroHectaresControl.value)
      this.camposInvalidosProduto.push('Número de Hectares');
    if (!this.precoUnitarioControl.value)
      this.camposInvalidosProduto.push('Preço Unitário');
    if (!this.quantidadeControl.value)
      this.camposInvalidosProduto.push('Quantidade');
    if (!this.codControl.value)
      this.camposInvalidosProduto.push('Código de Venda');
    if (!this.aplicacaoControl.value)
      this.camposInvalidosProduto.push('Aplicação');
    if (!this.culturaControl.value) this.camposInvalidosProduto.push('Cultura');
    if (!this.dataEntregaControl.value)
      this.camposInvalidosProduto.push('Data de Entrega');

    this.validDataProductItem = this.camposInvalidosProduto.length === 0;
  }

  /*copyTableFromOnFarmTemp(warehouse): { kit_items: any[] } {
    const kit_items = this.dadosTabela
      .filter((item) => !item.itemCodePrincipal)
      .map((principal) => {
        const assembled_items = this.dadosTabela
          .filter(
            (kit) =>
              kit.itemCodePrincipal?.toString().trim() ===
                principal.nItem?.toString().trim() &&
              kit.adicionadoPor === 'selecionarItensPadrao'
          )
          .map((kit) => ({
            item_code: kit.nItem ?? '',
            unit_price: this.converterValorMonetarioParaNumero(
              kit.precoUnitario
            ),
            quantity: parseFloat(kit.quantidade) || 0,
            discount: parseFloat(kit.desconto) || 0,
            sale_code: String(kit.codVenda),
            application: kit.aplicacao,
            culture: kit.cultura,
            delivery_date: this.toDateOnlyString(kit.dataEntrega),
            oc_number: kit.numeroOC ?? null,
            warehouse_code: warehouse,
          }));

        return {
          item_code: principal.nItem ?? '',
          item_name: principal.descricaoProduto ?? '',
          application_number: principal.quantidadeAplicacoes ?? 0,
          biological_dose: principal.doseBiologica ?? 0,
          hectares_number: principal.numeroHectares ?? 0,
          kit_number: String(principal.nrKit ?? ''),
          cost_per_kg: this.converterValorMonetarioParaNumero(
            principal.custoKgL
          ),
          total_cost: this.converterValorMonetarioParaNumero(
            principal.custoKgL
          ),
          total_cost_per_ha: this.converterValorMonetarioParaNumero(
            principal.custoTotalHaBiologico
          ),
          unit_price: this.converterValorMonetarioParaNumero(
            principal.precoUnitario
          ),
          quantity: parseFloat(principal.quantidade) || 0,
          discount: parseFloat(principal.desconto) || 0,
          sale_code: String(principal.codVenda),
          application: principal.aplicacao,
          culture: principal.cultura,
          delivery_date: this.toDateOnlyString(principal.dataEntrega),
          oc_number: principal.numeroOC ?? null,
          warehouse_code: warehouse,
          added_by: principal.adicionadoPor,
          sanitization_type: principal.sanitization_type,
          group_type: principal.tipoGrupo,
        };
      });

    console.log('✅ kit_items gerado:', kit_items);
    return { kit_items };
  }*/

  /*copyTableFromOnFarmTemp2(
    warehouse: string,
    headerData: any
  ): { header: any; kit_items: any[] } {
    const kit_items = this.dadosTabela
      .filter((item) => !item.itemCodePrincipal)
      .map((principal) => {
        const assembled_items = this.dadosTabela
          .filter(
            (kit) =>
              kit.itemCodePrincipal?.toString().trim() ===
                principal.nItem?.toString().trim() &&
              kit.adicionadoPor === 'selecionarItensPadrao'
          )
          .map((kit) => ({
            item_code: kit.nItem ?? '',
            unit_price: this.converterValorMonetarioParaNumero(
              kit.precoUnitario
            ),
            quantity: parseFloat(kit.quantidade) || 0,
            discount: parseFloat(kit.desconto) || 0,
            sale_code: String(kit.codVenda),
            application: kit.aplicacao,
            culture: kit.cultura,
            delivery_date: this.toDateOnlyString(kit.dataEntrega),
            oc_number: kit.numeroOC ?? null,
            warehouse_code: warehouse,
          }));

        return {
          item_code: principal.nItem ?? '',
          item_name: principal.descricaoProduto ?? '',
          application_number: principal.quantidadeAplicacoes ?? 0,
          biological_dose: principal.doseBiologica ?? 0,
          hectares_number: principal.numeroHectares ?? 0,
          kit_number: String(principal.nrKit ?? ''),
          cost_per_kg: this.converterValorMonetarioParaNumero(
            principal.custoKgL
          ),
          total_cost: this.converterValorMonetarioParaNumero(
            principal.custoKgL
          ),
          total_cost_per_ha: this.converterValorMonetarioParaNumero(
            principal.custoTotalHaBiologico
          ),
          unit_price: this.converterValorMonetarioParaNumero(
            principal.precoUnitario
          ),
          quantity: parseFloat(principal.quantidade) || 0,
          discount: parseFloat(principal.desconto) || 0,
          sale_code: String(principal.codVenda),
          application: principal.aplicacao,
          culture: principal.cultura,
          delivery_date: this.toDateOnlyString(principal.dataEntrega),
          oc_number: principal.numeroOC ?? null,
          warehouse_code: warehouse,
          assembled_items,
        };
      });

    return {
      header: headerData,
      kit_items,
    };
  }*/

  /**
   * Efetua cópia da tabela SAP + Simulador para o banco de dados do OnFarm
   * Retorna o header + kit_items do payload
   * @param warehouse
   * @param headerData
   * @returns
   */
  copyTableFromOnFarm(
    warehouse: string,
    headerData: any
  ): { header: any; kit_items: any[] } {
    const kit_items = this.dadosTabela.map((item) => {
      return {
        item_code: item.nItem ?? '',
        item_name: item.descricaoProduto ?? '',
        application_number: item.quantidadeAplicacoes ?? 0,
        biological_dose: item.doseBiologica ?? 0,
        hectares_number: item.numeroHectares ?? 0,
        kit_number: String(item.nrKit ?? ''),
        cost_per_kg: this.converterValorMonetarioParaNumero(item.custoKgL),
        total_cost: this.converterValorMonetarioParaNumero(item.custoKgL),
        total_cost_per_ha: this.converterValorMonetarioParaNumero(
          item.custoTotalHaBiologico
        ),
        unit_price: this.converterValorMonetarioParaNumero(item.precoUnitario),
        quantity: parseFloat(item.quantidade) || 0,
        discount: parseFloat(item.desconto) || 0,
        sale_code: String(item.codVenda),
        application: item.aplicacao,
        culture: item.cultura,
        delivery_date: this.toDateOnlyString(item.dataEntrega),
        oc_number: item.numeroOC ?? null,
        warehouse_code: warehouse,

        added_by: item.hasOwnProperty('adicionadoPor')
          ? item.adicionadoPor
          : 'adicionarNovoItem',
        sanitization_type: item.sanitization_type ?? null,
        group_type: item.hasOwnProperty('tipoGrupo')
          ? item.tipoGrupo
          : 'itemPrincipal',
      };
    });

    return {
      header: headerData,
      kit_items,
    };
  }

  /**
   * Converte a data para inserir no banco de dados no formato "2025-15-03"
   * @param dateInput
   */
  toDateOnlyString(dateInput: any): string {
    if (!dateInput) return '';

    if (dateInput._isAMomentObject && dateInput.isValid()) {
      return dateInput.format('YYYY-MM-DD');
    }

    if (dateInput instanceof Date && !isNaN(dateInput.getTime())) {
      return dateInput.toISOString().split('T')[0];
    }

    const str = String(dateInput).trim();
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (regex.test(str)) {
      return str;
    }

    console.warn('❌ Formato de data não reconhecido:', dateInput);
    return '';
  }

  /**
   * Reprocessa items apos selecionar uma nova sanitização
   */
  async reprocessarItensComNovaSanitizacao(): Promise<void> {
    const novaSanitizacao = this.sanatizacaoProdutoControl.value;

    if (!novaSanitizacao || this.dadosTabela.length === 0) return;

    const itensPrincipais = this.dadosTabela.filter(
      (item) => item.tipoGrupo !== 'kitComplementar'
    );

    for (const item of itensPrincipais) {
      item.tipoSanatizacao = novaSanitizacao;

      await this.selecionarItensPadrao(
        item.nItem,
        parseFloat(item.quantidade),
        item.codVenda,
        item.aplicacao,
        item.cultura,
        item.dataEntrega,
        novaSanitizacao
      );
    }

    this.atualizarCalculos();
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Remove todos os items complementarers
   */
  removerItensComplementares(): void {
    this.dadosTabela = this.dadosTabela.filter(
      (item) => item.tipoGrupo !== 'kitComplementar'
    );

    this.atualizarCalculos();
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Remove kits compplementares pelo item principal
   * @param itemPrincipalCode
   */
  removerKitsComplementares(itemPrincipalCode: string): void {
    this.dadosTabela = this.dadosTabela.filter(
      (i) => i.itemCodePrincipal !== itemPrincipalCode
    );
  }

  /**
   * Remove items nas tabelas kitcomplmentar e itemprincipal
   * @param item
   */
  removerItem(item: any): void {
    this.deleteProcess = true;
    this.dadosTabela = this.dadosTabela.filter((i) => i !== item);

    const isPrincipal = item.tipoGrupo !== 'kitComplementar';

    if (isPrincipal && item.nItem) {
      this.removerKitsComplementares(item.nItem);
    }

    if (isPrincipal) {
      this.reprocessarItensComNovaSanitizacao();
    }

    this.atualizarCalculos();
    this.changeDetectorRef.detectChanges();
    this.deleteProcess = false;
  }

  /**
   * Carrega item nos campos de formulario para editar os valores
   * @param item
   * @param index
   */
  carregarItemParaEdicao(item: any, index: number): void {
    this.indiceItemEditando = index;

    this.changeDetectorRef.detectChanges();

    this.nItemControl.setValue(item.nItem);
    this.descricaoProdutoControl.setValue(item.descricaoProduto);
    this.quantidadeAplicacoesControl.setValue(item.quantidadeAplicacoes);
    this.doseBiologicaControl.setValue(item.doseBiologica);
    this.numeroHectaresControl.setValue(item.numeroHectares);
    this.nrKitControl.setValue(item.nrKit);
    this.custoKgLControl.setValue(item.custoKgL);
    this.custoTotalHaBiologicoControl.setValue(item.custoTotalHaBiologico);
    this.precoUnitarioControl.setValue(item.precoUnitario);
    this.quantidadeControl.setValue(item.quantidade);
    this.descontoControl.setValue(item.desconto);
    this.aplicacaoControl.setValue(item.aplicacao);
    this.culturaControl.setValue(item.cultura);
    this.dataEntregaControl.setValue(item.data ? new Date(item.data) : null);
    this.numeroOCControl.setValue(item.numeroOC);
    this.newValorTotalControl.setValue(item.valorTotal ?? item.total);

    setTimeout(() => {
      this.itensScroll?.nativeElement?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }, 0);
  }

  /**
   * Flag para item atualizado
   */
  itemFoiEditado(item: any): boolean {
    return this.itensEditados.includes(item.nItem);
  }
}
