import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError, take, tap } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/services/auth/user.service';
import { FuseConfigService } from '@fuse/services/config';
import { environment } from 'environments/environment';

import { APP_CONSTANTS_SESSION } from '../constants/app.constants';
import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SessionAlertComponent } from '../../shared/components/toast-session/session-alert.component'; // Importe o componente
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AuthService {
  private _authenticated: boolean = false;
  private host: string;
  private remember: boolean;

  private sessionTimeout: any;
  private warningTimeout: any;

  // Dentro da classe AuthService
  private sessionRenewedSubject = new BehaviorSubject<boolean>(false);
  public sessionRenewed$ = new BehaviorSubject<boolean>(false);

  private snackBarRef: MatSnackBarRef<SessionAlertComponent> | null = null;

  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService,
    private _fuseConfigService: FuseConfigService,
    private _snackBar: MatSnackBar,
    private _router: Router
  ) {
    this._fuseConfigService.config$.subscribe(config => {
      this.host = environment.authHost;
    });

    if (APP_CONSTANTS_SESSION.ENVIRONMENT === 'PRD') {
      this.startSessionTimer();
      setInterval(
        () => this.checkSessionValidity(),
        APP_CONSTANTS_SESSION.CHECK_INTERVAL
      );
    } else {
      console.log('⚠️ Modo de desenvolvimento, controle de sessão desativado.');
    }
    window.addEventListener('storage', this.handleStorageChange.bind(this));
  }

  set accessToken(token: string) {
    localStorage.removeItem('accessToken');
    sessionStorage.removeItem('accessToken');
    if (this.remember) {
      localStorage.setItem('accessToken', token);
    } else {
      sessionStorage.setItem('accessToken', token);
    }
  }

  get accessToken(): string {
    if (sessionStorage.getItem('accessToken')) {
      return sessionStorage.getItem('accessToken');
    } else {
      return localStorage.getItem('accessToken');
    }
  }

  set refreshToken(token: string) {
    localStorage.removeItem('refreshToken');
    localStorage.setItem('refreshToken', token);
  }

  get refreshToken(): string {
    return localStorage.getItem('refreshToken');
  }

  /**
   * Decodifica o token para validação de validade
   * @param token
   * @returns
   */
  decodeToken(token: string): any {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      return JSON.parse(atob(base64));
    } catch (error) {
      console.error('Erro na tentativa de decodificar o token:', error);
      return null;
    }
  }

  /**
   * Avalia se pode ou não renovar a sessão
   * @returns
   */
  canRenewSession(): boolean {
    if (!this.refreshToken) return false;

    const decodedToken = this.decodeToken(this.refreshToken);
    if (!decodedToken || !decodedToken.exp) return false;

    const currentTime = Math.floor(Date.now() / 1000);
    const newSessionExpiration =
      currentTime + APP_CONSTANTS_SESSION.SESSION_DURATION / 1000;

    /*console.log('⏳ Tempo Atual:', this.formatTimestamp(currentTime));
    console.log(
      '🔄 Expiração do Refresh Token:',
      this.formatTimestamp(decodedToken.exp)
    );
    console.log(
      '📌 Expiração da Próxima Sessão:',
      this.formatTimestamp(newSessionExpiration)
    );*/

    return decodedToken.exp > newSessionExpiration;
  }

  formatTimestamp(timestamp) {
    const date = new Date(timestamp * 1000);
    return date.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' });
  }

  set userId(id: string) {
    localStorage.removeItem('userId');
    sessionStorage.removeItem('userId');
    if (this.remember) {
      localStorage.setItem('userId', id);
    } else {
      sessionStorage.setItem('userId', id);
    }
  }

  get userId(): string {
    if (sessionStorage.getItem('userId')) {
      return sessionStorage.getItem('userId');
    } else {
      return localStorage.getItem('userId');
    }
  }

  forgotPassword(uid: string): Observable<any> {
    return this._httpClient.post(this.host + '/auth/token-password', {
      uid: uid,
    });
  }

  resetPassword(password: string, token: string): Observable<any> {
    return this._httpClient.post(this.host + '/auth/new-password/' + token, {
      password: password,
    });
  }

  signIn(
    credentials: { uid: string; password: string },
    remember: boolean
  ): Observable<any> {
    if (this._authenticated) {
      return throwError('logged');
    }
    this.remember = remember;

    return this._httpClient.post(this.host + '/auth/login', credentials).pipe(
      switchMap((response: any) => {
        this.accessToken = response.token;
        this.userId = response.user.id;
        this.refreshToken = response.refreshtoken;

        this._authenticated = true;

        this._userService.user = response.user;
        this._userService.userData = response.user;

        // 🔹 Armazena horário do login
        this.setLoginTime();

        return of(response);
      })
    );
  }

  signInUsingToken(): Observable<any> {
    return this._httpClient
      .post(this.host + '/auth/refreshtoken', {
        refreshtoken: this.refreshToken,
      })
      .pipe(
        catchError(() => of(false)),
        switchMap((response: any) => {
          this.accessToken = response.token;

          this.refreshToken = response.refreshtoken;

          this._authenticated = true;

          this._userService.user = response.user;

          return of(true);
        })
      );
  }

  signOut(): Observable<any> {
    this.clearTimers();

    localStorage.removeItem('accessToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('role');
    localStorage.removeItem('refreshToken');
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('userId');
    localStorage.removeItem(APP_CONSTANTS_SESSION.LOGIN_TIME_KEY);
    localStorage.removeItem('sessionWarningToast');

    this._authenticated = false;
    this.sessionRenewed$.next(false); // 🚀 Reseta o estado da sessão
    this._router.navigate(['/sign-out']);

    return of(true);
  }

  signUp(user: {
    name: string;
    email: string;
    password: string;
    company: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/sign-up', user);
  }

  unlockSession(credentials: {
    email: string;
    password: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  check(): Observable<boolean> {
    if (this._authenticated) {
      return of(true);
    }

    if (!this.accessToken) {
      return of(false);
    }

    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    return this.signInUsingToken();
  }

  verifyTokenAuthenticity(token) {
    return this._httpClient
      .get<any>(this.host + '/auth/email-password/' + token)
      .pipe(tap(result => result));
  }

  /**
   * Marca o tempo de iniício de sessão
   */
  setLoginTime(): void {
    const now = Date.now();
    localStorage.setItem(APP_CONSTANTS_SESSION.LOGIN_TIME_KEY, now.toString());
    //console.log('✅ [LOGIN] Sessão iniciada em:', new Date(now));

    this.clearTimers();
    this.startSessionTimer();
    this.sessionRenewedSubject.next(true);
  }

  /**
   * Inicializa os tempos de sessão e de controle
   * @returns
   */
  private startSessionTimer(): void {
    this.clearTimers();

    const loginTime = parseInt(
      localStorage.getItem(APP_CONSTANTS_SESSION.LOGIN_TIME_KEY) || '0',
      10
    );

    if (!loginTime || !this._authenticated) {
      return;
    }

    const expirationTime = loginTime + APP_CONSTANTS_SESSION.SESSION_DURATION;
    const warningTime = expirationTime - APP_CONSTANTS_SESSION.WARNING_TIME;
    const now = Date.now();

    if (warningTime > now) {
      this.warningTimeout = setTimeout(() => {
        //this.showSessionAlert();
        this.renewSession();
      }, warningTime - now);
    } else {
      //this.showSessionAlert();
      this.renewSession();
    }

    if (expirationTime > now) {
      this.sessionTimeout = setTimeout(() => {
        this.signOut();
      }, expirationTime - now);
    } else {
      this.signOut();
    }
  }

  /**
   * Mostra a notificação de expiração de sessão
   * @returns
   */
  /*private showSessionWarning(): void {
    if (
      this._router.url.includes('/sign-in') ||
      this._router.url.includes('/sign-out')
    ) {
      return;
    }

    localStorage.setItem('sessionWarningToast', 'true');

    this._snackBar.openFromComponent(SessionAlertComponent, {
      duration: APP_CONSTANTS_SESSION.WARNING_TIME - 1000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'custom-snackbar-container',
    });
  }*/

  /**
   * Renova a sessão do usuário
   */
  renewSession(): void {
    this.setLoginTime();
    localStorage.removeItem('sessionWarningToast');
    localStorage.setItem('sessionRenewed', 'true');
    this.sessionRenewed$.next(true);
    setTimeout(() => {
      localStorage.removeItem('sessionRenewed');
    }, 1000);
  }

  /**
   * Limpa os tempos de sessão
   */
  private clearTimers(): void {
    if (this.warningTimeout) {
      clearTimeout(this.warningTimeout);
      this.warningTimeout = null;
    }
    if (this.sessionTimeout) {
      clearTimeout(this.sessionTimeout);
      this.sessionTimeout = null;
    }
  }

  /**
   * Verifica a validade da sessão
   */
  checkSessionValidity(): void {
    const loginTime = parseInt(
      localStorage.getItem(APP_CONSTANTS_SESSION.LOGIN_TIME_KEY) || '0',
      10
    );
    const now = Date.now();
    const sessionExpiresAt = loginTime + APP_CONSTANTS_SESSION.SESSION_DURATION;

    if (now >= sessionExpiresAt) {
      if (
        this._router.url.includes('/sign-in') ||
        this._router.url.includes('/sign-out')
      ) {
        return;
      }
      this.signOut();
    } else {
      //   console.log(
      //     `🔍 [VERIFICAÇÃO] Sessão ativa. Expira em ${Math.ceil(
      //       (sessionExpiresAt - now) / 1000
      //     )}s`
      //   );
    }
  }

  /**
   * Trigger para acesso publico
   */
  public triggerSessionWarning(): void {
    this.renewSession();
    //this.showSessionAlert();
  }

  /**
   * Exibir alerta de expiração de sessão
   */
  private showSessionAlert(): void {
    if (!this.snackBarRef) {
      this.snackBarRef = this._snackBar.openFromComponent(
        SessionAlertComponent,
        {
          duration: APP_CONSTANTS_SESSION.WARNING_TIME,
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: 'custom-snackbar-container',
        }
      );
    }
  }

  /**
   * Fechar alerta de expiração de sessão
   */
  dismissSessionAlert(): void {
    if (this.snackBarRef) {
      this.snackBarRef.dismiss();
      this.snackBarRef = null;
    }
  }
  /**
   * Detecta automaticamente a mudança de armazenamento
   * @param event
   */
  handleStorageChange(event: StorageEvent): void {
    if (event.key === 'sessionRenewed' && event.newValue === 'true') {
      this.dismissSessionAlert();
    }
  }
}
