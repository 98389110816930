import { NgModule, LOCALE_ID, DEFAULT_CURRENCY_CODE } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FuseConfigModule } from '@fuse/services/config';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { QuillModule } from 'ngx-quill';
import { MyFilterPipe } from './shared/pipes/my-filter.pipe';
import { NotVisistedFarmsModalComponent } from './modules/admin/dashboard/not-visisted-farms-modal/not-visisted-farms-modal.component';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { ColorPickerModule } from 'ngx-color-picker';
import {
  MatDatepicker,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import ptBr from '@angular/common/locales/pt';
import {
  CommonModule,
  CurrencyPipe,
  registerLocaleData,
} from '@angular/common';
import { DiariesModalComponent } from './modules/admin/dashboard/diaries-modal/diaries-modal.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgxMaskModule } from 'ngx-mask';
import { FazendaListComponent } from './fazenda-lista/fazenda-lista.component';
import { SalesComponent } from './modules/admin/sales/sales.component';
import {
  MatSortHeader,
  MatSortHeaderIntl,
  MatSortModule,
} from '@angular/material/sort';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { OpenSalesComponent } from './modules/admin/sales/open-sales/open-sales.component';
import { OpenSalesOrderComponent } from './modules/admin/sales/open-sales/open-sales-order/open-sales-order.component';
import { ClosedSalesComponent } from './modules/admin/sales/closed-sales/closed-sales.component';
import { ClosedSalesOrderComponent } from './modules/admin/sales/closed-sales/closed-sales-order/closed-sales-order.component';
import { OpenSalesDialogComponent } from './modules/admin/sales/open-sales/open-sales-dialog/open-sales-dialog.component';
import { SalesDialogComponent } from './modules/admin/sales/sales-dialog/sales-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DialogCancelarComponent } from './modules/admin/sales/open-sales/open-sales-dialog/dialog-cancelar/dialog-cancelar.component';
import { DialogEditarComponent } from './modules/admin/sales/open-sales/open-sales-dialog/dialog-editar/dialog-editar.component';
import { MatMenuModule } from '@angular/material/menu';
import { SearchComponent } from './modules/admin/sales/search/search.component';
import { SearchOrcamentoComponent } from './modules/admin/sales/open-sales/search-orcamento/search-orcamento.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AlertComponent } from './modules/admin/alert/alert.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SessionAlertComponent } from 'app/shared/components/toast-session/session-alert.component';
import { SessionTimerComponent } from 'app/shared/components/timer-session/session-timer.component';
import { StockComponent } from './modules/admin/stock/stock/stock.component';
import { StockFormComponent } from './modules/admin/stock/stock-form/stock-form.component';
import { StockModule } from './modules/admin/stock/stock.module';

const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled',
};

registerLocaleData(ptBr);

@NgModule({
  declarations: [
    SessionAlertComponent,
    AppComponent,
    NotVisistedFarmsModalComponent,
    DiariesModalComponent,
    FazendaListComponent,
    SalesComponent,
    OpenSalesComponent,
    OpenSalesOrderComponent,
    ClosedSalesComponent,
    ClosedSalesOrderComponent,
    OpenSalesDialogComponent,
    SalesDialogComponent,
    DialogCancelarComponent,
    DialogEditarComponent,
    SearchComponent,
    SearchOrcamentoComponent,
    AlertComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatProgressBarModule,
    RouterModule.forRoot(appRoutes, routerConfig),

    FuseModule,
    FuseConfigModule.forRoot(appConfig),

    CoreModule,

    LayoutModule,

    MarkdownModule.forRoot({}),
    QuillModule.forRoot(),
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSidenavModule, 
    MatPaginatorModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    MatSnackBarModule,
    FormsModule,
    MatAutocompleteModule,
    MatTableModule,
    MatTabsModule,
    MatDatepickerModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatToolbarModule,
    StockModule,
    ColorPickerModule,
    NgxMaskModule.forRoot(),
    CommonModule,
    MatTooltipModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
    CurrencyPipe,
  ],
})
export class AppModule {}
