export const environment = {
  production: true,

authHost: 'https://api.hmg.suporteonfarm.com.br',
//authHost: 'http://18.188.56.142:3100',

operHost: 'https://api.hmg.suporteonfarm.com.br',
// operHost: 'http://18.188.56.142:4100',

custHost: 'https://api.hmg.suporteonfarm.com.br',
//custHost: 'http://18.188.56.142:5100',

  iotHost: 'https://api.hmg.suporteonfarm.com.br',
  urlTimeline: 'https://api.hmg.suporteonfarm.com.br/api/v1/timeline',
  urlSurvey: 'https://api.hmg.suporteonfarm.com.br/api/v1/survey/put',
    //apiSap: 'http://18.188.56.142:3400',
apiSap: 'https://sap.hmg.solubio.agr.br',

whatsappLink:'https://api.whatsapp.com/send?phone=5564999167857'

};
