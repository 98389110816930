//src/app/modules/admin/sales/open-sales/open-sales.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import 'jspdf-autotable';
import { jsPDF } from 'jspdf';

import { Cadastro, Item } from '../Types';

@Injectable({
  providedIn: 'root',
})
export class OpenSalesService {
  apiUrl: string = '';
  apiUrlOnFarm: string = '';

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiSap;
    this.apiUrlOnFarm = environment.operHost;
  }

  enviarPedido(requestBody: any): Observable<any> {
    const url = `${this.apiUrl}/api/orcamentos/cadastrar`;
    return this.http.post<any>(url, requestBody);
  }

  enviarPedidoOnFarm(requestBody: any): Observable<any> {
    console.log(
      '📤 Enviando para API OnFarm:',
      JSON.stringify(requestBody, null, 2)
    );
    const url = `${this.apiUrlOnFarm}/api/v1/budgets`;
    console.log('url: ', url);
    return this.http.post<any>(url, requestBody);
  }

  cabecalho: any[] = [];

  carregarOrcamentos(): Observable<any[]> {
    return this.http
      .get<any>(this.apiUrl + '/api/consulta/listaOrcamentos')
      .pipe(
        map(response => {
          return response.listaOrcamentos || [];
        }),
        catchError(error => {
          console.error('Erro ao carregar orçamentos:', error);
          return throwError(error);
        })
      );
  }

  carregarOrcamentosPages(currentPage, limit): Observable<any[]> {
    return this.http
      .get<any>(
        this.apiUrl +
          '/api/consulta/listaOrcamentos?page=' +
          currentPage +
          '&limit=' +
          limit
      )
      .pipe(
        map(response => {
          return response.listaOrcamentos || [];
        }),
        catchError(error => {
          console.error('Erro ao carregar orçamentos:', error);
          return throwError(error);
        })
      );
  }

  envioPedido(idOrcamento: number): Observable<any> {
    const url = `${this.apiUrl}/api/consulta/orcamento/${idOrcamento}/clicksign`;
    return this.http.post<any>(url, {});
  }

  envioEmail(DocEntry: string): Observable<any> {
    const url = `${this.apiUrl}/api/consulta/pedido/${DocEntry}/pdf`;
    return this.http.get<any>(url);
  }

  enviarPDf(DocEntry: number): Observable<any[]> {
    const url = `${this.apiUrl}/api/consulta/orcamento/${DocEntry}/pdf`;
    return this.http.get<any>(url);
  }

  orcamentoDetails(DocEntry: number): Observable<any[]> {
    const url = `${this.apiUrl}/api/consulta/orcamento/${DocEntry}`;
    return this.http.get<any>(url);
  }

  orcamentoCancelar(DocEntry: number): Observable<any> {
    const url = `${this.apiUrl}/api/orcamentos/cancelar/${DocEntry}`;
    const requestBody = { DocEntry };
    return this.http.post<any>(url, requestBody);
  }

  pagamentoForma(): Observable<any[]> {
    return this.http
      .get<any>(this.apiUrl + '/api/consulta/listaFormaPagamento')
      .pipe(
        map(response => {
          return response.listaFormaPagamento || [];
        }),
        catchError(error => {
          console.error('Erro ao carregar formas de pagamento :', error);
          return throwError(error);
        })
      );
  }

  condicaoPagamento(): Observable<any[]> {
    return this.http
      .get<any>(this.apiUrl + '/api/consulta/listaCondicaoPagamento')
      .pipe(
        map(response => {
          return response.listaCondicaoPagamento || [];
        }),
        catchError(error => {
          console.error('Erro ao carregar condições de pagamento :', error);
          return throwError(error);
        })
      );
  }

  obterVendedores(): Observable<any[]> {
    return this.http
      .get<any>(this.apiUrl + '/api/consulta/listaVendedores')
      .pipe(
        map(response => {
          return response.listaVendedores || [];
        }),
        catchError(error => {
          console.error('Erro ao carregar lista de vendedores :', error);
          return throwError(error);
        })
      );
  }

  obterClientes(): Observable<any[]> {
    return this.http.get<any>(this.apiUrl + '/api/consulta/listaClientes').pipe(
      map(response => {
        return response.listaClientes || [];
      }),
      catchError(error => {
        console.error('Erro ao carregar lista de clientes:', error);
        return throwError(error);
      })
    );
  }

  filtrarPorNomeUsuario(nomeVendedor: string): Observable<any[]> {
    const url = `${this.apiUrl}/api/consulta/listaOrcamentos?nomeVendedor='${nomeVendedor}'`;
    return this.http.get<any>(url).pipe(
      map(response => {
        return response.listaOrcamentos || [];
      })
    );
  }

  obterMetodoPagamento(): Observable<any[]> {
    return this.http
      .get<any>(this.apiUrl + '/api/consulta/listaFormaPagamento')
      .pipe(
        map(response => {
          return response.listaFormaPagamento || [];
        }),
        catchError(error => {
          console.error('Erro ao carregar lista de vendedores :', error);
          return throwError(error);
        })
      );
  }

  editarOrcamento(requestBody: any, DocEntry: string): Observable<any> {
    const url = `${this.apiUrl}/api/orcamentos/editar/${DocEntry}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      docentry: DocEntry,
    });

    return this.http.patch(url, requestBody);
  }

  carregarCdsEntrega() {
    return this.http.get<any>(`${this.apiUrl}/api/consulta/listaFiliais`).pipe(
      map(response => response.listaFiliais || []), // Extrai a lista de filiais do response
      catchError(error => {
        console.error('Erro ao carregar lista de CDs de Entrega:', error);
        return throwError(() => error); // Lança o erro para ser tratado
      })
    );
  }

  carregarItens(): Observable<any[]> {
    return this.http.get<any>(`${this.apiUrl}/api/consulta/listaItems`).pipe(
      map(response => {
        return response.listaItems || [];
      }),
      catchError(error => {
        console.error('Erro ao carregar lista de itens:', error);
        return throwError(error);
      })
    );
  }

  carregarFrete() {
    return this.http.get<any>(this.apiUrl + '/api/consulta/listaFrete').pipe(
      map(response => {
        return response.listaFrete || [];
      }),
      catchError(error => {
        console.error('Erro ao carregar lista de vendedores :', error);
        return throwError(error);
      })
    );
  }

  buscarDados(parameters: string): Observable<any[]> {
    const url = `${this.apiUrl}${parameters}`;
    return this.http.get<{ listaOrcamentos: any[] }>(url).pipe(
      map(response => {
        return response.listaOrcamentos || [];
      })
    );
  }

  carregarCultura(): Observable<any[]> {
    return this.http
      .get<any>(`${this.apiUrl}/api/consulta/listaMeioCultura`)
      .pipe(
        map(response => {
          return response.listaMeioCultura || [];
        }),
        catchError(error => {
          console.error('Erro ao carregar lista de Meio de Cultura:', error);
          return throwError(error);
        })
      );
  }

  obterAtCard() {
    return this.http
      .get<any>(this.apiUrl + '/api/consulta/listaPedidos?ultimo=true')
      .pipe(
        map(response => {
          return response.listaPedidos || [];
        }),
        catchError(error => {
          console.error('Erro ao carregar orçamentos:', error);
          return throwError(error);
        })
      );
  }

  filtrarPorCardCode(CardCode: any): Observable<any[]> {
    if (!CardCode) {
      // Se o CardCode for inválido (null, undefined, ou ''), retorna um Observable vazio
      return of([]);
    }

    const url = `${this.apiUrl}/api/consulta/listaOrcamentos?CardCode='${CardCode}'`;
    return this.http.get<any>(url).pipe(
      map(response => {
        return response.listaOrcamentos || [];
      }),
      catchError(error => {
        console.error('Erro ao filtrar orçamentos por CardCode:', error);
        return throwError(error);
      })
    );
  }

  obterRegrasDeCalculo(): Observable<any[]> {
    const url = `${this.apiUrlOnFarm}/api/v1/product_rules`;
    return this.http.get<any[]>(url).pipe(
      map(response => response || []),
      catchError(error => {
        console.error(
          'Erro ao carregar regras de cálculo dos produtos:',
          error
        );
        return throwError(error);
      })
    );
  }

  obterFaixasDeCompra(): Observable<any[]> {
    const url = `${this.apiUrlOnFarm}/api/v1/consumption_range`;
    return this.http.get<any[]>(url).pipe(
      map(response =>
        response.map(faixa => ({
          ...faixa,
          description: faixa.description, // Deixa o valor original sem modificações
        }))
      ),
      catchError(error => {
        console.error('Erro ao carregar faixas de compra mínima:', error);
        return throwError(error);
      })
    );
  }

  obterPrecosInoculums(): Observable<any[]> {
    const url = `${this.apiUrlOnFarm}/api/v1/inoculums/prices`;
    return this.http.get<any[]>(url).pipe(
      catchError(error => {
        console.error('Erro ao carregar preços dos inoculums:', error);
        return throwError(error);
      })
    );
  }

  obterPrecosCultureMedia(): Observable<any[]> {
    const url = `${this.apiUrlOnFarm}/api/v1/culture_media/prices`;
    return this.http.get<any[]>(url).pipe(
      catchError(error => {
        console.error('Erro ao carregar preços dos meios de cultura:', error);
        return throwError(error);
      })
    );
  }

  obterListaInoculums(): Observable<any[]> {
    const url = `${this.apiUrlOnFarm}/api/v1/inoculum`;
    return this.http.get<any[]>(url).pipe(
      catchError(error => {
        console.error('Erro ao buscar lista de Inoculums:', error);
        return throwError(error);
      })
    );
  }

  obterMeiosDeCultura(): Observable<any[]> {
    const url = `${this.apiUrlOnFarm}/api/v1/culture_media`;
    return this.http.get<any[]>(url).pipe(
      map(response => {
        console.log('Resposta da API (Meios de Cultura):', response);
        return response; // Garante que é um array
      }),
      catchError(error => {
        console.error('Erro ao buscar meios de cultura:', error);
        return throwError(error);
      })
    );
  }

  downlodPdfSalesBudget(docEntry: number): Observable<Blob> {
    const url = `${this.apiUrl}/api/consulta/orcamento/${docEntry}/pdf/download`;
    console.log('url', url);
    console.log('docNum', docEntry);
    return this.http.get(url, { responseType: 'blob' }).pipe(
      catchError(error => {
        console.error(
          'na tentativa de download do PDF do orçamento de venda:',
          error
        );
        return throwError(error);
      })
    );
  }

  carregarOrcamentosApiOnFarm(): Observable<any[]> {
    return this.http.get<any>(this.apiUrlOnFarm + '/api/v1/budgets').pipe(
      map(response => {
        return response.listaOrcamentos || [];
      }),
      catchError(error => {
        console.error('Erro ao carregar orçamentos:', error);
        return throwError(error);
      })
    );
  }

  carregarOrcamentosPagesApiOnFarm(currentPage, limit): Observable<any[]> {
    return this.http
      .get<any>(
        this.apiUrlOnFarm +
          '/api/v1/budgets?page=' +
          currentPage +
          '&limit=' +
          limit
      )
      .pipe(
        map(response => {
          return response.listaOrcamentos || [];
        }),
        catchError(error => {
          console.error('Erro ao carregar orçamentos:', error);
          return throwError(error);
        })
      );
  }

  editarOrcamentoApiOnFarn(
    requestBody: any,
    DocEntry: string
  ): Observable<any> {
    const url = `${this.apiUrlOnFarm}/api/v1/budgets/${DocEntry}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      docentry: DocEntry,
    });

    return this.http.patch(url, requestBody);
  }

  orcamentoDetailsApiOnFarm(DocEntry: number): Observable<any[]> {
    const url = `${this.apiUrlOnFarm}/api/v1/budgets/${DocEntry}`;
    return this.http.get<any>(url);
  }
}
