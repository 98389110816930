<div class="custom-snackbar">
    <span class="snackbar-message"
        >⚠️ Sua sessão está prestes a expirar! &nbsp;</span
    >

    <button mat-button class="snackbar-action" (click)="renewSession()">
        Renovar
    </button>
    &nbsp;
    <button mat-button class="snackbar-action" (click)="logOut()">
        Logout
    </button>
</div>
